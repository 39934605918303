<template>
  <div class="home-box">
    <template v-for="(item, idx) in tabs">
      <component v-if="item.code == config.profile " :key="idx" v-bind:is="item.com"></component>
    </template>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import caratPage from './carat/Index'
import defaultPage from './default/Index'
export default {
  name: 'Home',
  components: {
  },
  data() {
    return {
      tabs: [
        { com: defaultPage, code: 'default' },
        { com: caratPage, code: 'zxkl' },
      ]
    }
  },
  computed: {
    ...mapState('auth', ['config']),
  },
  created: function () {
  },
  mounted: function () {
  },
  methods: {

  },
}
</script>
<style scoped>
.home-box {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
</style>