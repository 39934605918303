<template>
  <Modal v-model="showModal" :mask-closable="false" width="1000px">
    <div slot="header">编辑用户信息</div>
    <div class="user-edit-content">
      <Card class="cust-fields">
        <p slot="title">
          <Icon type="ios-film-outline"></Icon>
          基本信息
        </p>
        <!-- <a href="#" slot="extra" @click.prevent="getList">
          <Icon type="ios-loop-strong"></Icon>
          刷新
        </a> -->
        <Form ref="form" :model="form" :rules="rules" :label-width="80">
          <FormItem prop="customerId" label="所在组织">
            <Input type="text" maxlength="20" v-model="custName" readonly placeholder="请输入名称"></Input>
          </FormItem>
          <FormItem prop="code" label="登录名">
            <Input ref="code" type="text" maxlength="20" v-model="form.code" placeholder="请输入登录名"></Input>
          </FormItem>
          <FormItem prop="password" label="用户密码">
            <Input ref="password" type="text" maxlength="20" v-model="form.pswd" placeholder=""></Input>
          </FormItem>
          <FormItem prop="name" label="姓名">
            <Input ref="name" type="text" maxlength="20" v-model="form.name" placeholder="请输入姓名"></Input>
          </FormItem>
          <FormItem prop="mobile" label="手机号">
            <Input ref="mobile" type="text" maxlength="20" v-model="form.mobile" placeholder="请输入手机号"></Input>
          </FormItem>
          <FormItem prop="needExpire" label="过期控制">
            <Checkbox v-model="form.needExpire">是否需要过期控制</Checkbox>
          </FormItem>
          <FormItem v-show="form.needExpire" prop="expire" label="过期时间">
            <DatePicker ref="expire" type="date" v-model="form.expireDate" placeholder="请选择过期时间" style="width: 100%"></DatePicker>
          </FormItem>
          <FormItem v-show="needUsbKey && form.id>0" prop="expire" label="" :label-width="0">
            <Button type="success" long @click="bindUsbKey">绑定U盾</Button>
          </FormItem>
        </Form>
      </Card>
      <Card class="cust-fields" style="margin: 0 5px">
        <p slot="title">
          <Icon type="ios-film-outline"></Icon>
          角色权限
        </p>
        <Table border size="small" stripe highlight-row :height="500" :columns="cols" :data="roles"></Table>
      </Card>
      <Card class="cust-fields">
        <p slot="title">
          <Icon type="ios-film-outline"></Icon>
          数据权限
        </p>
        <div style="height: 500px;overflow-x: hidden;overflow-y: auto;border: solid 0 red">
          <Tree ref="groupTree" :data="groups" show-checkbox check-strictly @on-check-change="noceCheckChanged"></Tree>
        </div>
      </Card>
      <ModalUsbKeyCheck v-model="showUsbKeyCheckModal" :item="editItem" />
    </div>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">取消</Button>
      <Button type="primary" :loading="loading" @click="ok">保存</Button>
    </div>
  </Modal>
</template>
<script>
import {mapState} from 'vuex'
import ModalUsbKeyCheck from './ModalUsbKeyCheck'
export default {
  name: 'ModalUserEdit',
  components:{
    ModalUsbKeyCheck
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default(){ return {};}
    },
  },
  data () {
    return {
      showModal: this.value,
      showUsbKeyCheckModal: false,
      loading: false,
      isAdd: false,
      custName: '',
      index: -1,
      editItem: {},
      allGroups: [],
      groups: [],
      roles: [],
      cols:[
        {
          title: '',
          key: 'chk',
          width: 65,
          renderHeader: (h) => {
            return h('checkbox', {
              props: {
                // type: 'primary',
                // size: 'small',
                value: false,
              },
              style: {
                // marginRight: '5px'
                // width: '93px'
              },
              on: {
                'on-change': (chk) => {
                  for(let item of this.roles){
                    item.chk = chk;
                  }
                }
              }
            }, '');
          },
          render: (h, params) => {
            return h('checkbox', {
              props: {
                // type: 'primary',
                // size: 'small',
                value: params.row.chk,
              },
              style: {
                // marginRight: '5px'
                // width: '93px'
              },
              on: {
                'on-change': (chk) => {
                  params.row.chk = chk;
                  this.$set(this.roles[params.index], 'chk', chk);
                },
              }
            }, '');
          },
        },
        {
          title: '名称',
          key: 'name',
        },
      ],
      form: {
        id: 0,
        custId: 0,
        code: '',
        name: '',
        mobile: '',
        needExpire: false,
        expireDate: '',
        pswd: '',
        roles: [],
        groups: [],
      },
      rules: {
        code: { required: true, type: 'string', max: 20, message: '登录名不为空且长度不超过20', trigger: 'blur' },
        name: { required: true, type: 'string', max: 20, message: '姓名不为空且长度不超过20', trigger: 'blur' },
        mobile: { required: true, type: 'string', max: 20, message: '手机号不为空且长度不超过20', trigger: 'blur' },
      },
      needUsbKey: false,
    }
  },
  computed: {
    ...mapState('auth', ['organs', 'customers']),
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if(this.showModal){
        this.isAdd = this.item.isAdd;
        this.custName = this.item.cust.name;
        this.form.custId = this.item.cust.id;
        this.setUsbKeyNeed();
        this.form.pswd = '';
        this.form.roles = [];
        this.form.groups = [];
        if(!this.isAdd){
          this.form.id = this.item.data.id;
          this.form.code = this.item.data.code;
          this.form.name = this.item.data.name;
          this.form.mobile = this.item.data.mobile;
          this.form.needExpire = this.item.data.needExpire;
          if(this.form.needExpire){
            this.form.expireDate = this.item.data.expire;
          }else{
            let date = new Date();
            date.setFullYear(date.getFullYear() + 1);
            this.form.expireDate = date.format('yyyy-MM-dd');
          }
        }else{
          this.form.id = 0;
          this.form.name = '';
          this.form.code = '';
          this.form.mobile = '';
          this.form.needExpire = false;
          let date = new Date();
          date.setFullYear(date.getFullYear() + 1);
          this.form.expireDate = date.format('yyyy-MM-dd');
        }
        this.getUserRoles();
      }
    },
    showModal(val){
      this.$emit('showChanged', val);
    },
    'form.customerId'(){
      if(this.form.customerId <= 0){
        this.$set(this, 'roles', []);
        this.$set(this, 'groups', []);
      }else{
        this.getUserRoles();
      }
    },
  },
  methods: {
    bindUsbKey: function(){
      this.editItem = {type: 'bind', userId: this.form.id};
      this.showUsbKeyCheckModal = true;
    },
    setUsbKeyNeed: function(){
      this.$axios.post(`//${this.domains.trans}/sys/setting/QueryUsbKeyEnabled`, {custId: this.form.custId}).then(res => {
        if(res.code == 0){
          this.needUsbKey = res.data.usb;
        }
      });
    },
    noceCheckChanged: function(nodes, node){
      if(!node.checked)return;
      this.unchecksubs(node);
      for(let n of nodes){
        if(this.isSub(node, n)){
          // n.checked = false;
          this.$set(n, 'checked', false);
        }
      }
    },
    isSub: function(target, source){
      if(target.id == source.id)return false;
      let issub = false;
      let pid = target.parentId;
      let running = true;
      while(running){
        let pnodes = this.allGroups.filter(p => p.id == pid);
        if(pnodes.length == 0)break;
        let pnode = pnodes[0];
        if(pnode.id == source.id){
          issub = true;
          break;
        }else{
          pid = pnode.parentId;
        }
      }
      return issub;
    },
    unchecksubs: function(node){
      if(!node.children)return;
      for(let n of node.children){
        // n.checked = false;
        this.$set(n, 'checked', false);
        this.unchecksubs(n);
      }
    },
    getUserRoles: function(){
      if(this.form.custId <= 0){
        this.$set(this, 'roles', []);
        this.$set(this, 'groups', []);
        return;
      }
      this.$axios.post(`//${this.domains.trans}/station/auth/QueryUserAgents`, {id: this.form.id, custId: this.form.custId}).then(res => {
        this.$set(this, 'allGroups', res.data.groups);
        this.$set(this, 'roles', res.data.roles);
        if(res.data.roots.length == 0){
          this.$set(this, 'groups', []);
        }else{
          let grpTree = [];
          for(let r of res.data.roots){
            let grp = res.data.groups.filter(p => p.id == r);
            if(grp.length == 0)continue;
            let node = Object.assign(grp[0], {expand: true, children: []});
            this.initTreeData(node.children, res.data.groups, node.id)
            grpTree.push(node);
          }
          this.$set(this, 'groups', grpTree);
        }
      })
    },
    initTreeData: function(nodes, list, parent){
      let subs = list.filter(p => p.parentId == parent);
      for(let sub of subs){
        let node = Object.assign(sub, {expand: true, children: []});
        this.initTreeData(node.children, list, sub.id);
        nodes.push(node);
      }
    },
    ok: async function () {
      let result = await this.$refs.form.validate();
      if(!result){
        // this.hideLoading();
        return;
      }
      let chkNodes = this.$refs.groupTree.getCheckedNodes();
      let userGroups = [];
      for(let cn of chkNodes){
        userGroups.push(cn.id);
      }
      let userRoles = [];
      for(let role of this.roles){
        if(role.chk){
          userRoles.push(role.id);
        }
      }
      if(userRoles.length == 0){
        this.$Message.warning('必须选择用户角色');
        return;
      }
      if(userGroups.length == 0){
        this.$Message.warning('必须选择可操作的分组');
        return;
      }
      this.form.roles = userRoles;
      this.form.groups = userGroups;
      this.form.expireDate = new Date(this.form.expireDate).format('yyyy-MM-dd HH:mm:ss');
      this.loading = true;
      let res = await this.$axios.post(`//${this.domains.trans}/station/auth/SaveUser`, this.form);
      this.loading = false;
      if(res.code !== 0){
        // this.$Message.error(res.data);
        return;
      }
      this.$Message.info('保存成功');
      setTimeout(() => {
        this.showModal = false;
        this.$emit('saved');
      }, 800);
    },
    cancel () {
      this.showModal = false;
    },
  }
}
</script>
<style scoped>
.user-edit-content{
  display: flex;
}
.cust-fields{
  width: 300px;
  flex: auto;
}
  .form{
    height: calc(100%);
    overflow: auto;
  }
  .readonly{
    background: #eee
  }
</style>