<template>
  <div class="config-container">
    <div class="config-tree">
      <CommonTree />
    </div>
    <div class="config-content">
      <ButtonGroup class="config-menu">
        <template v-for="(item, idx) in tabs" >
          <Button :key="idx" @click="switchMenu(item.code)" v-if="funCodes(...item.opCodes)" :type="currentMenu == item.code ? 'primary' : 'default'">{{item.name}}</Button>
        </template>
      </ButtonGroup>
      <template v-for="(item, idx) in tabs">
        <component v-if="item.code == currentMenu" :key="idx" v-bind:is="item.com" :args="item.args"></component>
      </template>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import ConfigAreaIndex from '@/components/config/light/area/Index'
import ConfigLightIndex from '@/components/config/light/lamp/Index'
// import ConfigLightIndex from '@/components/config/light/lampfac/Index'
import SceneIndex from '@/components/config/light/scene/Index'
import CommonTree from '../tree/Index'
import ConfigGroupIndex from '@/components/config/station/group/Index'
import ConfigDeviceIndex from '@/components/config/station/device/Index'
import ProfileIndex from '@/components/config/light/profile/Index'
import StationIndex from '@/components/config/station/station/Index'
import Light6TableIndex from '@/components/config/timetable/ltime6/Index'
import Light7TableIndex from '@/components/config/timetable/ltime7/Index'
// import ConfigAccountIndex from '@/views/common/account/Index'
import AdjustIndex from '@/components/config/light/adjust/Index'
import { mapState } from 'vuex'
export default {
  name: 'FactoryConfigIndex',
  components: {
    CommonTree,
  },
  data() {
    return {
      currentMenu: 's7',
      tabs: [
        { code: 's1', name: '分组管理', com: ConfigGroupIndex, args: {}, opCodes: ['gv', 'ga', 'ge', 'gd'] },
        { code: 's2', name: '站点管理', com: StationIndex, args: { stationType: 0 }, opCodes: ['sv', 'sa','se', 'sd',"ss"] },
        { code: 's3', name: '通信管理', com: ConfigDeviceIndex, args: {}, opCodes: ['dv', 'da', 'de','dd'] },
        { code: 's4', name: '单灯分组管理', com: ConfigAreaIndex, args: {}, opCodes: ['lgv', 'lga', 'lge','lgd'] },
        { code: 's11', name: '控制模式管理', com: SceneIndex, args: {}, opCodes:['lmv', 'lma', 'lme','lmd'] },
        { code: 's12', name: '情景模式管理', com: ProfileIndex, args: {}, opCodes:['lpv', 'lpa', 'lpe','lpd'] },
        { code: 's6', name: '单灯计时日表管理', com: Light6TableIndex, args: {}, opCodes:['tlv', 'tla', 'tle','tld'] },
        { code: 's14', name: '单灯准时日表管理', com: Light7TableIndex, args: {}, opCodes:['tlv', 'tla', 'tle','tld'] },
        { code: 's19', name: '调光属性', com: AdjustIndex, args: {}, opCodes:['lav', 'laa', 'lae','lad'] },
        { code: 's7', name: '单灯设备管理', com: ConfigLightIndex, args: {}, opCodes:['lv', 'la', 'le','ld'] },
        // { code: 's13', name: '外接账号管理', com: ConfigAccountIndex, args: { tab: 's4' }, opCodes:['azv', 'aza', 'aze','azd'] },
      ],
      isCollapsed: false,
      currentName: 'light', //role,user
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes']),
    ...mapState('common', ['powerBoxAlarmTypes', 'productCodes', 'powerBoxPartTypes', 'powerBoxTypes']),
  },
  watch: {
    $route: {
      handler: function (route) {
        this.currentName = route.path.replace(`/index/light/factory/config/`, '');
      },
      immediate: true
    },
  },
  mounted: function () {
    this.$store.dispatch('group/getGroupAndStations', 0)
  },
  methods: {
    switchMenu: function (item) {
      this.currentMenu = item;
    },
  }
}
</script>
<style scoped>
.config-container {
  /* border: solid 1px red; */
  width: 100%;
  display: flex;
}
.config-tree {
  /* border: solid 1px red; */
  width: 300px;
  /* margin: 3px; */
  flex: none;
}
.config-content {
  /* border: solid 1px red; */
  margin: 3px;
  width: 0;
  flex: auto;
  display: flex;
  flex-direction: column;
  /* background: linear-gradient(180deg, #001323, #0E244A);
  box-shadow: inset 0px 0px 32px 0px #013565; */
}
.config-menu {
  flex: none;
  margin: 5px 10px;
}
.menu-content {
  height: 10px;
  flex: auto;
  /* border: solid 1px red; */
}
</style>
