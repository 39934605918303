<template>
<div class="config-device-container">
  <div class="device-search-area">
    所属站点：
    <Select v-model="stationId" number style="width: 400px; margin-right: 10px">
      <Option :value="0">全部站点</Option>
      <template v-for="(item, idx) in stations">
        <Option :value="item.id" :key="idx">{{item.name}} - {{item.groupName}}</Option>
      </template>
    </Select>
    <!-- <Button type="success" :loading="loading" @click="addSmoke">新增</Button> -->
    <AuthButton opCode='pa' size="default" type="primary" @click="getDeviceList">查询</AuthButton>
  </div>
  <div class="device-data-area" ref="table">
    <u-table :data="deviceTree" style="width: 100%;" row-key="id" border :height="tabHeight" default-expand-all :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
      <u-table-column prop="name" label="设备名称" sortable width="300"></u-table-column>
      <u-table-column prop="type" label="设备类型" sortable width="250">
        <template slot-scope="scope">
          {{deviceTypes[scope.row.type]}}
        </template>
      </u-table-column>
      <u-table-column prop="code" label="通信ID" sortable width="150"></u-table-column>
      <u-table-column prop="stationId" label="所在站点" sortable width="200">
        <template slot-scope="scope">
          {{getStationName(scope.row.stationId)}}
        </template>
      </u-table-column>
      <u-table-column prop="simCard" label="SIM卡号" sortable width="150"></u-table-column>
      <!-- <u-table-column prop="monitorId" label="前置机ID" sortable></u-table-column> -->
      <u-table-column prop="connectTime" label="连接时间" sortable width="160">
        <template slot-scope="scope">
          {{scope.row.connectTime == 0 ? '':`${new Date(scope.row.connectTime).format('yyyy-MM-dd HH:mm:ss')}`}}
        </template>
      </u-table-column>
      <u-table-column prop="fireTime" label="最后通信时间" sortable width="160">
        <template slot-scope="scope">
          {{scope.row.fireTime == 0 ? '':`${new Date(scope.row.fireTime).format('yyyy-MM-dd HH:mm:ss')}`}}
        </template>
      </u-table-column>
      <u-table-column prop="timeout" label="超时时间" sortable width="160">
        <template slot-scope="scope">
          {{scope.row.timeout}} 分钟
        </template>
      </u-table-column>
      <!-- <u-table-column prop="interval" label="通信间隔" sortable width="140">
        <template slot-scope="scope">
          {{scope.row.interval == 0 ? '不巡检':`每隔${scope.row.interval}分钟正点`}}
        </template>
      </u-table-column> -->
      <u-table-column fixed="right">
        <template slot="header" slot-scope="scope">
          <AuthButton opCode='lda' style="margin-right: 5px" @click="handleAddTop(scope)">新增顶级设备</AuthButton>
        </template>
        <template slot-scope="scope">
          <AuthButton opCode='lda' style="margin-right: 5px" @click="handleAddSub(scope)">新增子设备</AuthButton>
          <AuthButton opCode='lde' style="margin-right: 5px" @click="handleEdit(scope)">编辑</AuthButton>
          <AuthButton opCode='ldd' type="error" @click="handleDelete(scope)">删除</AuthButton>
          <!-- <AuthButton opCode='DelDeviceInfo' type="error" @click="handleDelete(scope)">操作</AuthButton> -->
        </template>
      </u-table-column>
    </u-table>
    <ModalDeviceEdit v-model="editModal" :item="editItem" :types="deviceTypes" :stations="stations" :devices="deviceAll" @saved="getDeviceList"/>
  </div>
</div>
</template>
<script>
import { mapState } from 'vuex'
import ModalDeviceEdit from './ModalDeviceEdit'
export default {
  name: 'ConfigDeviceIndex',
  components:{
    ModalDeviceEdit
  },
  props: {
  },
  data () {
    return {
      stationId: 0,
      stations: [],
      tabHeight: 300,
      editModal: false,
      loading: false,
      editItem: {
        isAdd: true,
        data: {}
      },
      deviceAll: [],
      deviceTree: [],
    }
  },
  watch: {
    windowOnResize(){
      this.setTableHeight();
    },
  },
  computed: {
    ...mapState('group', ['groups', 'selectedNode', 'selectedDevices']),
    ...mapState('cmd', ['windowOnResize']),
    ...mapState('common', ['productCodes','deviceTypes']),
  },
  mounted: function(){
    setTimeout(this.setTableHeight, 200);
    this.getStations();
    this.getDeviceList();
    // console.log('station device mounted', this.list)
  },
  methods: {
    getStations: function(){
      this.$axios.post(`//${this.domains.trans}/station/lux/QueryStation`, {}).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          this.$set(this, 'stations', res.data);
        }
      });
    },
    setTableHeight: function(){
      this.tabHeight = this.$refs.table.clientHeight;
    },
    getStationName: function(id){
      let ss = this.stations.filter(p => p.id == id);
      if(ss.length == 0)return '';
      return ss[0].name;
    },
    getDeviceList: function(){
      this.$axios.post(`//${this.domains.trans}/station/lux/QueryDevice`, {id: this.stationId}).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          this.$set(this, 'deviceAll', res.data);
          let treeData = [];
          let tops = res.data.filter(item => item.topId == 0);
          for(let t of tops){
            let children = this.initChildren(res.data, t.id);
            treeData.push({
              id: t.id,
              stationId: t.stationId,
              code: t.code,
              name: t.name,
              type: t.type,
              topId: t.topId,
              channel: t.channel,
              timeout: t.timeout,
              parentId: t.parentId,
              interval: t.interval,
              simCard: t.simCard,
              monitorId: t.monitorId,
              monitorName: t.monitorName,
              online: t.online,
              connectTime: t.connectTime,
              fireTime: t.fireTime,
              children: children
            });
          }
          this.$set(this, 'deviceTree', treeData);
        }
      });
    },
    initChildren: function(list, parentId){
      let result = [];
      let subs = list.filter(item => item.parentId == parentId);
      for(let t of subs){
        let children = this.initChildren(list, t.id);
        result.push({
          id: t.id,
          stationId: t.stationId,
          code: t.code,
          name: t.name,
          type: t.type,
          topId: t.topId,
          channel: t.channel,
          timeout: t.timeout,
          parentId: t.parentId,
          interval: t.interval,
          simCard: t.simCard,
          monitorId: t.monitorId,
          monitorName: t.monitorName,
          online: t.online,
          connectTime: t.connectTime,
          fireTime: t.fireTime,
          children: children
        });
      }
      return result;
    },
    handleAddTop: function(){
      if(this.stations.length == 0){
        this.$Message.warning('请先创建站点');
        return;
      }
      this.editItem = {isAdd: true, data: {stationId: this.stationId <= 0 ? this.stations[0].id : this.stationId, parentId: 0}};
      this.editModal = true;
    },
    handleAddSub: function(params){
      this.editItem = {isAdd: true, data: {stationId: params.row.stationId, parentId: params.row.id}};
      this.editModal = true;
    },
    handleEdit: function(params){
      this.editItem = {isAdd: false, data: params.row};
      this.editModal = true;
    },
    deleteItem: function(list, id){
      for(let i = 0; i < list.length; i++){
        if(list[i].id == id){
          list.splice(i, 1);
          return
        }else if(list[i].children && list[i].children.length > 0){
          this.deleteItem(list[i].children, id);
        }
      }
    },
    handleDelete: function(params){
      if(params.row.children && params.row.children.length > 0){
        this.$Message.warning('有子设备，不能删除');
        return;
      }
      this.$Modal.confirm({
        title: "提示",
        content: `确定要删除设备 ${params.row.name}[ID:${params.row.code}] 吗?`,
        onOk: async () => {
          this.$axios.post(`//${this.domains.trans}/station/lux/DeleteDevice`, {id: params.row.id}).then(res => {
            if(res.code != 0){
              // this.$Message.error(res.data);
            }else{
              this.getDeviceList();
            }
          });
        }
      });
    },
    itemDataSaved: function(){
      this.getDeviceList();
    },
  }
}
</script>
<style scoped>
.config-device-container{
  /* padding: 5px; */
  /* padding-right: 40px; */
  height: 100%;
  display: flex;
  flex-direction: column;
  /* border: solid 1px red; */
}
.device-search-area{
  height: 42px;
  flex: none;
  border-radius: 6px;
  display: flex;
  align-items: center;
  padding: 10px;
  /* margin: 3px; */
  /* margin-bottom: 5px;
  padding-top: 3px; */
}
.device-data-area{
  /* border: solid 1px red; */
  height: 100%;
  flex: auto;
  /* border: solid 1px #DCDEE2; */
  border-radius: 6px;
}
</style>