<template>
  <Modal v-model="showModal" :mask-closable="false" width="500px">
    <div slot="header">编辑普通灯杆信息</div>
    <Form ref="form" :model="form" :rules="rules" :label-width="110">
      <FormItem prop="name" label="名称" style="margin-right: 15px">
        <div class="flex-row">
          <Input type="text" v-model="form.name" placeholder="名称"></Input>
        </div>
      </FormItem>
      <FormItem prop="code" label="灯杆码" style="margin-right: 15px">
        <div class="flex-row">
          <Input type="text" disabled v-model="form.code" placeholder="系统自动生成"></Input>
        </div>
      </FormItem>
      <FormItem prop="groupId" label="所属分组" style="margin-right: 15px">
        <Select v-model="form.groupId">
          <Option v-for="(s, idx) in groups" :key="idx" :value="s.id">{{s.name}}</Option>
        </Select>
      </FormItem>
      <FormItem prop="poleType" label="灯杆类型" style="margin-right: 15px">
        <Input type="text" v-model="form.asset.poleType"></Input>
      </FormItem>
      <FormItem prop="poleType" label="灯臂类型" style="margin-right: 15px">
        <Input type="text" v-model="form.asset.armType"></Input>
      </FormItem>
      <FormItem prop="height" label="高度" style="margin-right: 15px">
        <Input type="number" number v-model="form.asset.height">
          <span slot="append">米</span>
        </Input>
      </FormItem>
    </Form>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">取消</Button>
      <Button type="primary" :loading="loading" @click="ok">保存</Button>
    </div>
    <!-- <ModalSelectGroup v-model="showSelectModal" @newGroupSelected="newGroupSelected" /> -->
  </Modal>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'ModalPoleEdit',
  components:{
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default(){ return {};}
    },
    groups: {
      type: Array,
      default(){ return [];}
    },
  },
  data () {
    return {
      showModal: this.value,
      // showSelectModal: false,
      loading: false,
      isAdd: false,
      types: [],
      groupName: '',
      mains: [],
      devices: [],
      form: {
        id: 0,
        groupId: 0,
        code: '',
        name: '',
        asset: {
          //资产配置相关
          poleType: '', //灯杆类型
          armType: '', //灯臂类型
          height: 8, //高度
        }
      },
      rules: {
      },
    }
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if(newVal){
        // console.log('modal level edit mounted', this.item)
        this.isAdd = this.item.isAdd;
        if(!this.isAdd){
          this.form.id = this.item.data.id;
          this.form.groupId = this.item.data.groupId;
          this.form.name = this.item.data.name;
          this.form.code = this.item.data.code;
          for(let k in this.form.asset){
            this.form.asset[k] = this.item.data.asset[k];
          }
        }else{
          this.form.id = 0;
          this.form.groupId = 0;
          this.form.code = '';
          this.form.name = '';
          this.form.asset = {
            //资产配置相关
            poleType: '', //灯杆类型
            armType: '', //灯臂类型
            height: 8, //高度
          }
        }
      }
    },
    showModal(val){
      this.$emit('showChanged', val);
    },
  },
  computed: {
    ...mapState('common', ['connectTypes']),
  },
  mounted: function(){
  },
  methods: {
    ok: async function () {
      if(this.form.name.trim().length == 0){
        this.$Message.warning('请输入名称');
        return;
      }
      if(this.form.groupId <= 0){
        this.$Message.warning('请选择所属分组');
        return;
      }
      // if(this.form.content.group <= 0 || this.form.content.group > 255){
      //   this.$Message.warning('分组号必须在 1 - 255 之间');
      //   return;
      // }
      this.$axios.post(`//${this.domains.trans}/station/light/SaveLampPole`, this.form).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          this.$Message.info('保存成功');
          this.showModal = false;
          this.$emit('saved', {isAdd: this.isAdd, data: res.data});
        }
      })
    },
    cancel () {
      this.showModal = false;
      // this.$refs.form.resetFields();
    },
  }
}
</script>
<style scoped>
.form-item-group{
  border: solid 1px rgba(129, 124, 124, 0.377);
  border-radius: 12px;
  margin-bottom: 15px;
}
.group-header{
  /* border: solid 1px blue; */
  background-color: rgba(221, 216, 216, 0.808);
  margin-bottom: 15px;
  height: 35px;
  border-radius: 12px 12px 0 0;
  line-height: 35px;
  font-weight: bold;
  padding: 0 10px;
}
  .demo-drawer-footer{
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    border-top: 1px solid #e8e8e8;
    padding: 10px 16px;
    text-align: right;
    background: #fff;
  }
  .form{
    height: calc(100% - 54px);
    overflow: auto;
  }
  .readonly{
    background: #eee
  }
</style>