<template>
  <div class="alarm-rule-container">
    <div class="query-data-area">
      <Input v-model="keyword" placeholder="请输入组织关键字" style="width: 300px"></Input>
      <Button type="primary" style="margin: 0 5px;" @click="keywordBut">搜索</Button>
    </div>
    <div class="alarm-data-area" ref="table1">
      <vxe-table resizable border show-overflow row-key :row-config="{isHover: true}" :key="tabRefresh" :seq-config="{seqMethod}" :tree-config="{transform: true, expandAll: true}" :data="showTreeList" :scroll-y="{enabled: false}" :height="tabHeight">
        <vxe-column type="seq" title="组织名称" tree-node width="400" fixed="left"></vxe-column>
        <vxe-column field="id" title="ID" width="60" header-align="center">
          <template #default="{ row }">
            {{row.id}}
          </template>
        </vxe-column>
        <vxe-column field="linkman" title="联系人" width="150" header-align="center">
          <template #default="{ row }">
            {{row.linkman}}
          </template>
        </vxe-column>
        <vxe-column field="mobile" title="联系方式" width="150" header-align="center">
          <template #default="{ row }">
            {{row.mobile}}
          </template>
        </vxe-column>
        <vxe-column field="enable" title="是否启用" width="80" header-align="center">
          <template #default="{ row }">
            {{row.enable ? '启用' : '未启用'}}
          </template>
        </vxe-column>
        <vxe-column field="appName" title="应用名称" width="300" header-align="center">
          <template #default="{ row }">
            {{row.enable ? row.appName : '-'}}
          </template>
        </vxe-column>
        <vxe-column field="area" title="所在城市" width="100" header-align="center">
          <template #default="{ row }">
            {{row.enable ? row.area : '-'}}
          </template>
        </vxe-column>
        <vxe-column field="entry" title="入口码" width="100" header-align="center">
          <template #default="{ row }">
            {{row.enable ? row.entry : '-'}}
          </template>
        </vxe-column>
        <vxe-column field="profile" title="界面风格" width="100" header-align="center">
          <template #default="{ row }">
            {{getProfileName(row)}}
          </template>
        </vxe-column>
        <vxe-column>
          <template slot="header">
            <AuthButton opCode='scv1' style="margin-right: 5px" @click="getList">刷新</AuthButton>
          </template>
          <template #default="{ row }">
            <AuthButton opCode='sca' style="margin-right: 5px" @click="handleAdd(row)">新增子组织</AuthButton>
            <AuthButton opCode='sce1' style="margin-right: 5px" @click="handleEdit(row)">编辑</AuthButton>
            <AuthButton opCode='srv' type="info" style="margin-right: 5px" @click="handleRole(row)">角色管理</AuthButton>
            <AuthButton opCode='suv' type="info" style="margin-right: 5px" @click="handleUser(row)">用户管理</AuthButton>
            <AuthButton opCode='sce2' :type="row.enable ? 'warning' : 'success'" style="margin-right: 5px" @click="handleEnable(row)">{{row.enable ? '禁用' : '启用'}}</AuthButton>
            <AuthButton opCode='scd' type="error" @click="handleDelete(row)">删除</AuthButton>
          </template>
        </vxe-column>
      </vxe-table>

      <ModalCustomerEdit v-model="showEditModal" :item="editItem" :profiles="profiles" @saved="itemSaved" />
      <ModalRoleList v-model="showRoleModal" :item="editItem" />
      <ModalUserList v-model="showUserModal" :item="editItem" />
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import ModalCustomerEdit from './ModalCustomerEdit'
import ModalRoleList from '../role/ModalRoleList'
import ModalUserList from '../user/ModalUserList'
export default {
  name: 'SystemCustomerIndex',
  components: {
    ModalCustomerEdit,
    ModalRoleList,
    ModalUserList,
  },
  props: {
  },
  data() {
    return {
      curTab: 'hour24',
      list: [],
      tabHeight: 600,
      editItem: { isAdd: true, data: {} },
      showEditModal: false,
      showArgModal: false,
      showRoleModal: false,
      showUserModal: false,
      treeData: [],
      btns: [
        { code: 'updatePowerRated', name: '设定额定功率' },
        { code: 'updateCurrentRated', name: '设定额定电流' },
        { code: 'updateCurrentRate', name: '设定电流倍率' },
        { code: 'updateLeakageRate', name: '设定漏电阈值' },
        { code: 'updateSteelRate', name: '设定防盗阈值' },
      ],
      treeList: [],
      showTreeList: [],
      keyword: "",
      tabRefresh: 0,
      profiles: [],
    }
  },
  computed: {
    ...mapState('cmd', ['windowOnResize']),
    ...mapState('auth', ['token']),
  },
  watch: {
    windowOnResize() {
      this.setTableHeight();
    },
    selectedNode() {
      this.getList();
    }
  },
  mounted: function () {
    // console.log('sys cust index mounted');
    setTimeout(this.setTableHeight, 100);
    this.getProfiles();
    this.getList();
  },
  destroyed: function () {
  },
  methods: {
    getProfileName: function(row){
      if(!row.enable)return '-';
      if(!row.profile)return '默认风格';
      let ps = this.profiles.filter(p => p.code == row.profile);
      if(ps.length == 0)return '默认风格';
      return ps[0].name;
    },
    setTableHeight: function () {
      this.tabHeight = this.$refs.table1.clientHeight - 0;
    },
    handleRole: function (params) {
      if (!params.enable) {
        this.$Message.warning('请先启用应用');
        return;
      }
      this.editItem = params;
      this.showRoleModal = true;
    },
    handleUser: function (params) {
      if (!params.enable) {
        this.$Message.warning('请先启用应用');
        return;
      }
      this.editItem = params;
      this.showUserModal = true;
    },
    handleAdd: function (params) {
      this.editItem = { isAdd: true, data: params, parent: { id: params.id, name: params.name } };
      this.showEditModal = true;
    },
    handleEdit: function (params) {
      let parent = this.getCustItem(params.parentId) || { id: 0, name: '-' };
      this.editItem = { isAdd: false, data: params, parent: { id: parent.id, name: parent.name } };
      this.showEditModal = true;
    },
    itemSaved: function () {
      this.getList();
    },
    handleEnable: function (params) {
      this.$Modal.confirm({
        title: "提示",
        content: `确定要${params.enable ? '禁用' : '启用'}组织[${params.name}]的应用吗?`,
        onOk: async () => {
          this.$axios.post(`//${this.domains.trans}/station/auth/EnableApp`, { id: params.id, enable: !params.enable }).then(res => {
            if (res.code != 0) {
              // this.$Message.error(res.data);
            } else {
              // this.getList();
              params.enable = !params.enable;
              this.$Message.info(!params.enable ? '禁用成功' : '启用成功');
            }
          });
        }
      });
    },
    handleDelete: function (params) {
      this.$Modal.confirm({
        title: "提示",
        content: `确定要删除[${params.name}]吗?`,
        onOk: async () => {
          this.$axios.post(`//${this.domains.trans}/station/auth/DeleteCust`, { id: params.id }).then(res => {
            if (res.code != 0) {
              // this.$Message.error(res.data);
            } else {
              this.$Message.info('删除成功');
              this.getList();
            }
          });
        }
      });
    },
    getCustItem: function (id) {
      let cs = this.treeList.filter(p => p.id == id);
      if (cs.length == 0) return null;
      return cs[0];
    },
    getProfiles: function () {
      this.$axios.post(`//${this.domains.trans}/station/auth/QueryProfiles`, {}).then(res => {
        if (res.code != 0) {
          // this.$Message.error(res.data);
        } else {
          this.$set(this, 'profiles', res.data);
        }
      });
    },
    getList: function () {
      this.$axios.post(`//${this.domains.trans}/station/auth/QueryCust`, {}).then(res => {
        if (res.code != 0) {
          // this.$Message.error(res.data);
        } else {
          let root = res.data.root;
          let list = res.data.list;
          this.$set(this, 'treeList', list);
          this.treeList.unshift(root)
          this.keywordBut()
        }
      });
    },
    seqMethod({ row }) {
      return `${row.level}级：${row.name}`
    },
    keywordBut() {
      this.initTreeData();
      if (this.keyword == "") {
        this.$set(this, 'showTreeList', this.treeList);
      }
      let arr = [];
      this.treeList.forEach(el => {
        if (el.name.indexOf(this.keyword) > -1) {
          arr.push(el);
        }
      });
      this.$set(this, 'showTreeList', arr);
      this.tabRefresh++
    },
    initTreeData: function () {
      this.treeList.forEach(el => {
        el.level = 1;
        let cs = this.treeList.filter(p => p.id == el.parentId);
        if (cs.length > 0) {
          el.level = cs[0].level + 1;
        }
      });
      this.treeList.forEach(el => {
        if (isNaN(el.level)) {
          this.initTreeData();
        }
      });
    },
  }
}
</script>
<style scoped>
.alarm-rule-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: stretch;
  /* padding: 10px; */
  /* padding-right: 40px; */
  /* border: solid 1px red; */
}
.query-data-area {
  height: 45px;
  flex: none;
  display: flex;
  align-items: center;
  border: solid 1px rgba(143, 138, 138, 0.452);
  margin-bottom: 5px;
}
.alarm-data-area {
  /* border: solid 1px red; */
  height: 100px;
  flex: auto;
  /* border: solid 1px #DCDEE2; */
  border-radius: 6px;
  /* padding-left: 10px; */
}
.station-alarm-tabs {
  height: 30px;
  flex: none;
  display: flex;
}
.station-alarm-tab {
  padding: 3px 8px;
  margin: 3px 3px 0 3px;
  border: solid 1px lightgray;
  border-radius: 5px 5px 0 0;
  cursor: pointer;
  background-color: white;
}
.tab-active {
  border: solid 1px rgb(135, 159, 224);
  background-color: rgb(135, 159, 224);
  color: white;
}
.station-alarm-tabcontent {
  height: 500px;
  flex: auto;
  /* border: solid 1px red; */
}
/* .box-body{
  height: 0;
  flex: auto;
  margin-top: 5px;
  border: solid 1px red;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  position: relative;
}
.box-body::-webkit-scrollbar {
  display: none;
} */
</style>