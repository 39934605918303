<template>
  <Modal v-model="showModal" :mask-closable="false" width="1200px" height="650px" :title="`${showInfo.name}的历史记录`" @on-cancel="cancel">
    <div class="lamp-search">
      起始时间：
      <DatePicker v-model="form.start" type="datetime" format="yyyy-MM-dd HH:mm" placeholder="请选择开始时间" style="margin-right: 10px; width: 155px;"></DatePicker>
      截止时间：
      <DatePicker v-model="form.end" type="datetime" format="yyyy-MM-dd HH:mm" placeholder="请选择截止时间" style="margin-right: 10px; width: 155px;"></DatePicker>
      <Button type="info" @click="historyAjax">查询</Button>
      <Button type="success" @click="exportData" style="margin:3px 10px;">导出</Button>
    </div>
    <div class="lamp-tab" ref="table1">
      <vxe-table border resizable show-overflow show-header-overflow keep-source ref="chkTable" :export-config="{}" :key="dataRefresh" @checkbox-change="selectChangeEvent" :data="tabData" height="600" :row-config="{isHover: true}" :checkbox-config="{checkField: 'checked',}" :merge-cells="mergeData">
        <vxe-column field="fireTime" title="时间" width="200">
          <template #default="{ row }">
            {{row.fireTime}}
          </template>
        </vxe-column>
        <vxe-column field="name" title="灯杆名称">
          <template #default="{ row }">
            {{row.name}}
          </template>
        </vxe-column>
        <vxe-column field="en" title="灯头名称">
          <template #default="{ row }">
            {{row.en}}
          </template>
        </vxe-column>
        <vxe-column field="u" title="电压(V)">
          <template #default="{ row }">
            {{row.u}}
          </template>
        </vxe-column>
        <vxe-column field="c" title="电流(A)">
          <template #default="{ row }">
            {{row.c}}
          </template>
        </vxe-column>
        <vxe-column field="p" title="功率(W)">
          <template #default="{ row }">
            {{row.p}}
          </template>
        </vxe-column>
        <vxe-column field="op" title="亮度(%)">
          <template #default="{ row }">
            {{row.op}}
          </template>
        </vxe-column>
      </vxe-table>
    </div>
    <div slot="footer">
    </div>
  </Modal>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
export default {
  name: 'lampHistory',
  components: {
  },
  props: {
    showInfo: {
      type: Object,
      default() { return {}; }
    },
    value: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      list: [],
      tabHeight: 500,
      showModal: this.value,
      form: {
        "start": "2023-02-26 16:58:24",
        "end": "2023-03-01 16:58:24"
      },
      tabData: [],
      dataRefresh: 0,
      history: [],
      mergeData: [],
    }
  },
  computed: {
    ...mapGetters('group', ['groupPathName']),
    ...mapState("cmd", ["commands", "cmdResultRefresh"]),
    ...mapState('common', ['weeks', 'productCodes', 'topDevices', 'deviceTypes', 'powerTableActions', 'monitorActionTypes']),
    ...mapState('auth', ['showSpin', 'stationTabName']),
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if (newVal) {
        this.historyAjax();
      }
    },
  },
  mounted: function () {
    let now = new Date();
    now.setDate(now.getDate());
    this.form.end = now.format('yyyy-MM-dd HH:mm:ss');
    now.setDate(now.getDate() - 7);
    this.form.start = now.format('yyyy-MM-dd HH:mm:ss');
  },
  methods: {
    historyAjax() {
      let params = {
        "paramId": this.showInfo.id,
        "start": this.form.start,
        "end": this.form.end,
      }
      if (this.showInfo.id == -1) { return }
      this.$axios.post(`//${this.domains.trans}/station/param/history`, params).then(res => {
        if (res.code != 0) {
          // this.$Message.error(res.data);
        } else {
          res.data.forEach(el => {
            for (let attr in el.content) {
              if (!isNaN(el.content[attr]) && el.content[attr] < 0) {
                el.content[attr] = 0
              }
            }
          });
          this.$set(this, 'history', res.data);
          this.makeData(res.data)
        }
      })
    },
    selectChangeEvent({ rowIndex }) {
      this.$refs.chkTable.reloadRow(this.dataList, null, rowIndex)
    },
    spanData() {
      this.mergeData = [];
      let spanCols = [{
        name: '时间',
        col: 0
      }, {
        name: '灯杆名称',
        col: 1
      }, {
        name: '电压(V)',
        col: 3
      }];
      if (this.tabData.length < 2) {
        return false;
      }
      let data = "";
      let index = { row: 1, col: 1, rowspan: 1, colspan: 1 }
      for (let i = 0; i < this.tabData.length; i++) {
        if (this.tabData[i + 1] && this.tabData[i].lid == this.tabData[i + 1].lid) {
          if (index.rowspan == 1) {
            index.row = i;
          }
          index.rowspan++;
        } else {
          if (index.rowspan > 1) {
            for (let p = 0; p < spanCols.length; p++) {
              index.col = spanCols[p].col;
              data = JSON.parse(JSON.stringify(index));
              this.mergeData.push(data)
            }
          }
          index.rowspan = 1;
        }
      }
      this.dataRefresh++;
    },
    exportData() {
      this.$refs.chkTable.openExport()
    },
    makeData(data) {
      let arr = [];
      data.forEach((el, index) => {
        let oneOb = {
          lid: index,
          name: this.showInfo.name,
          en: this.showInfo.content.en1 ? this.showInfo.content.nm1 : '--',
          fireTime: new Date(el.fireTime).format('yyyy-MM-dd HH:mm:ss'),
          u: el.content.u,
          c: el.content.c1,
          p: el.content.p1,
          op: el.content.op1,
        }
        let towOb = {
          lid: index,
          name: this.showInfo.name,
          en: this.showInfo.content.en2 ? this.showInfo.content.nm2 : '--',
          fireTime: new Date(el.fireTime).format('yyyy-MM-dd HH:mm:ss'),
          u: el.content.u,
          c: el.content.c2,
          p: el.content.p2,
          op: el.content.op2,
        }
        arr.push(oneOb)
        arr.push(towOb)
      });
      this.$set(this, 'tabData', arr);
      this.spanData();
    },
    cancel() {
      this.showModal = false;
      this.$emit('popupClose', {});
    }
  }
}
</script>
<style scoped>
.lamp-tab {
  margin-top: 20px;
  height: 600px;
}
</style>