<template>
  <div class="lux-dashboard">
    <div class="lux-list-tree">
      <template v-for="(item, idx) in list">
        <a href="javascript:void(0);" :key="idx" :class="['module-nva-but', listIndex == idx ? 'active' : '']" @click="listBut(idx)">
          <img :src="isImg(item)" />
          {{item.name}} [{{item.l.toFixed(0)}}]</a>
      </template>
    </div>
    <div class="lux-right-box">
      <div class="lux-right-top">
        <p>设备操作</p>
        <div class="lux-groupList">
          <template v-for="(item, idx) in showGroups">
            <div class="lux-groupList-box" :key="idx" @click="clickItem(item)">
              <img :src="img.lux.nav.treeIconLarge" />
              <div>{{item.name}}</div>
            </div>
          </template>
        </div>
      </div>
      <div class="lux-right-bottom">
        <div class="lux-bottom-lux">
          <div class="bottom-lux-top">
            <div class="bottom-lux-name"> <img :src="isImg(currentLux)" /> {{currentLux ? currentLux.name : '-'}}</div>
            <div class="bottom-lux-but">
              <a href="javascript:void(0);" @click="viewHistory(currentLux)">历史</a>
            </div>
          </div>
          <div class="bottom-lux-icon">
            <img :src="img.lux.nav.luxMix0" alt="">
            <div>
              <div class="bottom-icon-name">光照度：</div>
              <div class="bottom-icon-value">{{ currentLux ? currentLux.l.toFixed(0) : '-'}} lux</div>
            </div>
          </div>
          <div class="bottom-icon-box">
            <div class="bottom-val-box1">
              <img :src="img.lux.nav.luxVal" alt="">
              <div>
                <div class="bottom-val-name">原始值</div>
                <div class="bottom-val-value" style="width: 300px">{{ currentLux ? currentLux.ls.join(' lux, ') : '-'}} lux</div>
              </div>
            </div>
          </div>
          <div class="bottom-icon-box">
            <div class="bottom-val-box1">
              <img :src="img.lux.nav.temperature" alt="">
              <div>
                <div class="bottom-val-name">温度</div>
                <div class="bottom-val-value">{{ currentLux ? currentLux.t.toFixed(0) : '-'}} ℃</div>
              </div>
            </div>
            <div class="bottom-val-box2">
              <img :src="img.lux.nav.humidity" alt="">
              <div>
                <div class="bottom-val-name">湿度</div>
                <div class="bottom-val-value">{{ currentLux ? currentLux.h.toFixed(0) : '-'}} %</div>
              </div>
            </div>
          </div>
          <div class="bottom-icon-box">
            <div class="bottom-val-box1">
              <img :src="img.lux.nav.time" alt="">
              <div>
                <div class="bottom-val-name">刷新时间</div>
                <div class="bottom-val-value">{{currentLux && currentLux.ts > 0 ? new Date(currentLux.ts).format('yyyy-MM-dd HH:mm:ss') : '-'}} </div>
              </div>
            </div>
            <div class="bottom-val-box2">
              <img :src="img.lux.nav.monitor" alt="">
              <div>
                <div class="bottom-val-name">监测区域</div>
                <div class="bottom-val-value" :title="currentLux ? currentLux.groupName : '-'">{{currentLux ? currentLux.groupName : '-'}}</div>
              </div>
            </div>
          </div>

        </div>
        <div class="lux-bottom-chart">
          <p class="lux-chart-name">光照度趋势</p>
          <div class="lux-chart-box">
            <LineChart ref="lineChart" :option="lineChartData" id="chartsLineData" :key="timer" />
          </div>
        </div>
      </div>
    </div>
    <ModalControlGroup v-model="showControlModal" :item="selectedItem" />
  </div>
</template>
<script>
import { mapState } from 'vuex'
import ModalControlGroup from './ModalControlGroup'
import LineChart from '@/components/charts/line'
export default {
  name: 'LuxDashborad12',
  components: {
    ModalControlGroup,
    LineChart
  },
  data() {
    return {
      list: [],
      listIndex: 0,
      luxRules: [],
      ticker: null,
      monitorDevices: {},
      timeout: null,
      groups: [],
      showGroups: [],
      selectedItem: {},
      showControlModal: false,
      timer: 0,
      lineChartData: {
        id: "lineChartData",
        title: '',
        unit: '',
        x: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun5"],
        series: [
          {
            name: '光照度',
            data: [0],
          },
          {
            name: '温度',
            data: [0],
          },
          {
            name: '湿度',
            data: [0],
          }
        ],
        // cover: {
        //   legend: {
        //     data: ['光照度', '温度', '湿度'],
        //   },
        // },
        setSeries: {
          smooth: true, //平滑折线图
        }
      },
    }
  },
  computed: {
    ...mapState('auth', ['agentChangeValue']),
    ...mapState('group', ['selectedNode']),
    ...mapState('common', ['powerBoxTypes', 'productCodes']),
    ...mapState('cmd', ['luxDataChanged', 'luxContent']),
    online: function () {
      return function (item) {
        if (item.ts <= 0) return '离线';
        if ((new Date().getTime() - item.ts) > 120 * 1000) return '离线';
        return '在线'
      }
    },
    alarmStatus: function () {
      return function (item) {
        let rules = this.luxRules.filter(p => p.id == item.ruleId);
        if (rules.length == 0) return 'status-common';
        let rate = rules[0].openRate;
        if (!rate) return 'status-common';
        if (rate <= 0) return 'status-common';
        if (!item.lastData) return 'status-common';
        if (item.lastData.l <= 0) return 'status-common';
        if (item.lastData.l < rate) return 'status-alarm';
        return 'status-common';
      }
    },
    currentLux: function(){
      if(this.list.length == 0)return null;
      if(this.listIndex >= this.list.length)return null;
      if(this.listIndex < 0)return null;
      return this.list[this.listIndex];
    }
  },
  watch: {
    agentChangeValue() {
      this.debounceInitData();
    },
    luxDataChanged() {
      for(let item of this.list){
        let ctnt = this.luxContent[`s${item.id}`];
        if(!ctnt)continue;
        item.online = true;
        item.l = ctnt.l;
        item.t = ctnt.t;
        item.h = ctnt.h;
        this.$set(item, 'ls', ctnt.ls);
        item.ts = ctnt.time;
        if(this.currentLux && item.id == this.currentLux.id){
          this.lineChartData.x.push(new Date(ctnt.time).format('yyyy-MM-dd HH:mm:ss'));
          this.lineChartData.series[0].data.push(ctnt.l.toFixed(0));
          this.lineChartData.series[2].data.push(ctnt.t.toFixed(0));
          this.lineChartData.series[1].data.push(ctnt.h.toFixed(0));
          this.timer = Math.random();
          // console.log('refresh page data', item)
        }
      }
    },
  },
  mounted: function () {
    this.getLuxRule();
    this.getGroups();
    this.getList();
    this.ticker = setInterval(this.checkOnline, 1000)
  },
  destroyed: function () {
    if (this.ticker) {
      clearInterval(this.ticker);
      this.ticker = null;
    }
  },
  methods: {
    isImg(item) {
      if (!item) return false;
      let rules = this.luxRules.filter(p => p.id == item.ruleId);
      if (rules.length == 0) return this.img.tree.station.offliner;
      if (!item.online) {
        return this.img.lux.nav.offline;
      } else if (item.l < rules[0].openRate) {
        return this.img.lux.nav.police;
      } else {
        return this.img.lux.nav.online;
      }
    },
    listBut(index) {
      this.listIndex = index;
      this.history(this.list[index]);
      this.showGroups1(this.list[index]);
    },
    showGroups1(item) {
      this.showGroups = [];
      if (!(this.groups.length > 0 && item.groupIds.length)) {
        return false;
      }
      this.groups.forEach(el => {
        item.groupIds.forEach(els => {
          if (el.id == els) {
            this.showGroups.push(el)
          }
        });
      });
    },
    clickItem: function (item) {
      this.selectedItem = item;
      this.showControlModal = true;
    },
    getGroups: function () {
      this.$axios.post(`//${this.domains.trans}/station/lux/QueryGroup`, {}).then(res => {
        if (res.code != 0) {
          // this.$Message.error(res.data);
        } else {
          this.$set(this, 'groups', res.data);
        }
      });
    },
    debounceInitData: function () {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(this.getList, 500);
    },
    checkOnline: function () {
      for (let item of this.list) {
        let rules = this.luxRules.filter(p => p.id == item.ruleId);
        if (rules.length == 0) {
          item.online = false;
          continue;
        }
        item.online = (new Date().getTime() - item.ts) < rules[0].interval * 2 * 1000;
      }
    },
    getImgType: function (item) {
      let rules = this.luxRules.filter(p => p.id == item.ruleId);
      if (rules.length == 0) return this.img.tree.station.offliner;
      if (!item.online) {
        return this.img.tree.station.offliner;
      } else if (item.lastData.l < rules[0].openRate) {
        return this.img.tree.station.alarmOn;
      } else {
        return this.img.tree.station.onlineOn;
      }
    },
    getLuxRule: function () {
      this.$axios.post(`//${this.domains.trans}/station/lux/QueryRule`, {}).then(res => {
        if (res.code === 0) {
          if (!res.data.intervals) {
            res.data.intervals = [];
          }
          this.$set(this, 'luxRules', res.data)
        } else {
          // this.$Message.error(res.data);
        }
      });
    },
    viewHistory: function (item) {
      if(!item)return;
      this.$emit('doAction', { cmd: 'gotoSearch', stationId: item.id })
    },
    history(el) {
      let now = new Date();
      now.setDate(now.getDate() + 1);
      let end = now.format('yyyy-MM-dd HH:mm:ss');
      now.setDate(now.getDate() - 2);
      let start = now.format('yyyy-MM-dd HH:mm:ss');
      let ajaxData = {
        "stationId": el.id,
        "start": start,
        "end": end,
        "index": 1,
        "size": 200
      }
      this.$axios.post(`//${this.domains.trans}/station/lux/QueryLog`, ajaxData).then(res => {
        if (res.code === 0) {
          this.lineChartData.x = [];
          this.lineChartData.series[0].data = [];
          this.lineChartData.series[2].data = [];
          this.lineChartData.series[1].data = [];
          res.data.list.sort((a, b) => {
            if (a.createTime == b.createTime) return 0;
            return a.createTime > b.createTime ? 1 : -1;
          })
          res.data.list.forEach(el => {
            this.lineChartData.x.push(el.createTime);
            this.lineChartData.series[0].data.push(el.content.l.toFixed(0));
            this.lineChartData.series[2].data.push(el.content.t.toFixed(0));
            this.lineChartData.series[1].data.push(el.content.h.toFixed(0));
          });
          this.timer = Math.random();
        } else {
          // this.$Message.error(res.data);
        }
      });
    },
    getList: function () {
      this.$axios.post(`//${this.domains.trans}/station/lux/QueryStation`, {}).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
          return;
        }
        this.$set(this, 'list', res.data);
        if(res.data.length > 0){
          this.listBut(0);
        }
      });
    },
  }
}
</script>
<style scoped>
.lux-dashboard {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.lux-list-tree {
  width: 300px;
  height: 100%;
  background: #ffffff;
  padding-top: 20px;
}
.lux-right-box {
  width: calc(100% - 320px);
  height: 100%;
}
.lux-right-top {
  background: #ffffff;
  padding: 20px;
  height: 228px;
}
.lux-right-top p {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #021629;
}
.lux-groupList {
  display: flex;
  flex-direction: revert;
  margin-top: 30px;
}
.lux-groupList-box {
  text-align: center;
  margin: 0 80px 0 20px;
}
.lux-groupList .lux-groupList-box div {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #324252;
  margin-top: 10px;
}
.module-nva-but {
  width: 300px;
  height: 48px;
  display: inline-block;
  line-height: 48px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #021629;
}
.module-nva-but.active {
  background: #ddeffe;
}
.module-nva-but.active::after {
  content: "";
  width: calc(100%);
  height: 48px;
  display: block;
  margin: -48px auto 0;
  border-left: 4px solid #3880fc;
  border-radius: 0px 6px 6px 0px;
}
.module-nva-but img {
  vertical-align: middle;
  margin-left: 40px;
  margin-right: 10px;
}
.lux-right-bottom {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.lux-bottom-lux {
  width: 694px;
  height: 540px;
  background: #ffffff;
  padding: 30px;
}
.lux-bottom-chart {
  width: 930px;
  height: 540px;
  background: #ffffff;
  padding: 20px;
}
.bottom-lux-top {
  display: flex;
  justify-content: space-between;
}
.bottom-lux-name {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #021629;
}
.bottom-lux-name img {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}
.bottom-lux-but a {
  width: 79px;
  height: 32px;
  background: #3880fc;
  border-radius: 4px;
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
  text-align: center;
  color: #ffffff;
  line-height: 32px;
}
.bottom-lux-but a img {
  width: 16px;
  height: 16px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 7px;
}
.bottom-lux-icon {
  display: flex;
  justify-content: flex-start;
  margin-top: 50px;
}
.bottom-lux-icon img {
  margin-right: 39px;
}
.bottom-icon-name {
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #324252;
  line-height: 26px;
  margin-top: 20px;
}
.bottom-icon-value {
  font-size: 40px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #021629;
  line-height: 26px;
  margin-top: 20px;
}
.bottom-icon-box {
  display: flex;
  justify-content: flex-start;
  margin-top: 35px;
}

.bottom-val-box1 {
  display: flex;
  justify-content: flex-start;
  width: 330px;
}
.bottom-val-box2 {
  display: flex;
  justify-content: flex-start;
}
.bottom-val-name {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #324252;
  margin-left: 35px;
}
.bottom-val-value {
  width: 230px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  font-size: 22px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #24334a;
  line-height: 26px;
  margin-left: 35px;
}
.lux-chart-name {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #021629;
}
.lux-chart-box {
  width: 100%;
  height: 460px;
  margin-bottom: 20px;
}
</style>