var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"alarm-rule-container"},[_c('div',{staticClass:"query-data-area"},[_c('Input',{staticStyle:{"width":"300px"},attrs:{"placeholder":"请输入组织关键字"},model:{value:(_vm.keyword),callback:function ($$v) {_vm.keyword=$$v},expression:"keyword"}}),_c('Button',{staticStyle:{"margin":"0 5px"},attrs:{"type":"primary"},on:{"click":_vm.keywordBut}},[_vm._v("搜索")])],1),_c('div',{ref:"table1",staticClass:"alarm-data-area"},[_c('vxe-table',{key:_vm.tabRefresh,attrs:{"resizable":"","border":"","show-overflow":"","row-key":"","row-config":{isHover: true},"seq-config":{seqMethod: _vm.seqMethod},"tree-config":{transform: true, expandAll: true},"data":_vm.showTreeList,"scroll-y":{enabled: false},"height":_vm.tabHeight}},[_c('vxe-column',{attrs:{"type":"seq","title":"组织名称","tree-node":"","width":"400","fixed":"left"}}),_c('vxe-column',{attrs:{"field":"id","title":"ID","width":"60","header-align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.id)+" ")]}}])}),_c('vxe-column',{attrs:{"field":"linkman","title":"联系人","width":"150","header-align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.linkman)+" ")]}}])}),_c('vxe-column',{attrs:{"field":"mobile","title":"联系方式","width":"150","header-align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.mobile)+" ")]}}])}),_c('vxe-column',{attrs:{"field":"enable","title":"是否启用","width":"80","header-align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.enable ? '启用' : '未启用')+" ")]}}])}),_c('vxe-column',{attrs:{"field":"appName","title":"应用名称","width":"300","header-align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.enable ? row.appName : '-')+" ")]}}])}),_c('vxe-column',{attrs:{"field":"area","title":"所在城市","width":"100","header-align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.enable ? row.area : '-')+" ")]}}])}),_c('vxe-column',{attrs:{"field":"entry","title":"入口码","width":"100","header-align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.enable ? row.entry : '-')+" ")]}}])}),_c('vxe-column',{attrs:{"field":"profile","title":"界面风格","width":"100","header-align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.getProfileName(row))+" ")]}}])}),_c('vxe-column',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('AuthButton',{staticStyle:{"margin-right":"5px"},attrs:{"opCode":"sca"},on:{"click":function($event){return _vm.handleAdd(row)}}},[_vm._v("新增子组织")]),_c('AuthButton',{staticStyle:{"margin-right":"5px"},attrs:{"opCode":"sce1"},on:{"click":function($event){return _vm.handleEdit(row)}}},[_vm._v("编辑")]),_c('AuthButton',{staticStyle:{"margin-right":"5px"},attrs:{"opCode":"srv","type":"info"},on:{"click":function($event){return _vm.handleRole(row)}}},[_vm._v("角色管理")]),_c('AuthButton',{staticStyle:{"margin-right":"5px"},attrs:{"opCode":"suv","type":"info"},on:{"click":function($event){return _vm.handleUser(row)}}},[_vm._v("用户管理")]),_c('AuthButton',{staticStyle:{"margin-right":"5px"},attrs:{"opCode":"sce2","type":row.enable ? 'warning' : 'success'},on:{"click":function($event){return _vm.handleEnable(row)}}},[_vm._v(_vm._s(row.enable ? '禁用' : '启用'))]),_c('AuthButton',{attrs:{"opCode":"scd","type":"error"},on:{"click":function($event){return _vm.handleDelete(row)}}},[_vm._v("删除")])]}}])},[_c('template',{slot:"header"},[_c('AuthButton',{staticStyle:{"margin-right":"5px"},attrs:{"opCode":"scv1"},on:{"click":_vm.getList}},[_vm._v("刷新")])],1)],2)],1),_c('ModalCustomerEdit',{attrs:{"item":_vm.editItem,"profiles":_vm.profiles},on:{"saved":_vm.itemSaved},model:{value:(_vm.showEditModal),callback:function ($$v) {_vm.showEditModal=$$v},expression:"showEditModal"}}),_c('ModalRoleList',{attrs:{"item":_vm.editItem},model:{value:(_vm.showRoleModal),callback:function ($$v) {_vm.showRoleModal=$$v},expression:"showRoleModal"}}),_c('ModalUserList',{attrs:{"item":_vm.editItem},model:{value:(_vm.showUserModal),callback:function ($$v) {_vm.showUserModal=$$v},expression:"showUserModal"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }