<template>
<div class="config-station-container">
  <div class="station-data-area" ref="table">
    <u-table :data="list" style="width: 100%;" row-key="id" border stripe :height="tabHeight" default-expand-all>
      <u-table-column prop="name" label="名称" sortable width="200">
        <template slot-scope="params">
          {{params.row.name}}
        </template>
      </u-table-column>
      <!-- <u-table-column prop="type" label="类型" sortable width="100">
        <template slot-scope="params">
          {{powerBoxTypes[params.row.type]}}
        </template>
      </u-table-column> -->
      <u-table-column prop="location" label="安装位置" sortable width="300">
        <template slot-scope="params">
          {{params.row.location}}
        </template>
      </u-table-column>
      <u-table-column prop="install" label="安装时间" sortable width="120">
        <template slot-scope="params">
          {{getDate(params.row.install)}}
        </template>
      </u-table-column>
      <u-table-column prop="dx" label="均差阈值" sortable width="120">
        <template slot-scope="params">
          {{params.row.dx}}
        </template>
      </u-table-column>
      <u-table-column prop="ruleId" label="应用规则" sortable>
        <template slot-scope="params">
          {{params.row.ruleName}}
        </template>
      </u-table-column>
      <u-table-column prop="groupId" label="管控分组" sortable>
        <template slot-scope="params">
          {{params.row.groupName}}
        </template>
      </u-table-column>
      <u-table-column width="130">
        <template slot="header" slot-scope="scope">
          <AuthButton opCode='lsa' style="margin-right: 5px" @click="handleAdd(scope)">新增</AuthButton>
          <AuthButton opCode='lsv' style="margin-right: 5px" @click="getList">刷新</AuthButton>
        </template>
        <template slot-scope="scope">
          <AuthButton opCode='lse' style="margin-right: 5px" @click="handleEdit(scope)">编辑</AuthButton>
          <AuthButton opCode='lsd' type="error" @click="handleDelete(scope)">删除</AuthButton>
        </template>
      </u-table-column>
    </u-table>
  </div>
  <ModalStationEdit v-model="showEditModal" :item="editItem" :groups="groups" :rules="rules" @saved="getList" />
</div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import ModalStationEdit from './ModalStationEdit'
export default {
  name: 'ConfigStationIndex',
  components:{
    ModalStationEdit,
  },
  data () {
    return {
      showEditModal: false,
      tabHeight: 300,
      editItem: {isAdd: true, index: -1, data: {}},
      list: [],
      groups: [],
      rules: [],
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapGetters('group', ['groupPathName']),
    ...mapState('cmd', ['windowOnResize', 'cmds']),
    ...mapState('common', ['powerBoxAlarmTypes', 'productCodes', 'powerBoxPartTypes', 'powerBoxTypes']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
  },
  watch: {
    windowOnResize(){
      this.tabHeight = this.$refs.table.clientHeight
      // console.log('windowOnResize', this.windowOnResize, this.$refs.table.clientHeight)
    },
  },
  mounted: function(){
    setTimeout(() => {
      this.tabHeight = this.$refs.table.clientHeight;
    }, 200);
    this.getRules();
    this.getGroups();
    this.getList();
  },
  methods: {
    getGroupName: function(id){
      let grps = this.groups.filter(p => p.id == id);
      if(grps.length == 0)return '';
      return grps[0].name;
    },
    getList: function(){
      this.$axios.post(`//${this.domains.trans}/station/lux/QueryStation`, {}).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          this.$set(this, 'list', res.data);
        }
      });
    },
    getRules: function(){
      this.$axios.post(`//${this.domains.trans}/station/lux/QueryRule`, {}).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          this.$set(this, 'rules', res.data);
        }
      });
    },
    getGroups: function(){
      this.$axios.post(`//${this.domains.trans}/station/lux/QueryGroup`, {}).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          this.$set(this, 'groups', res.data);
        }
      });
    },
    getDate:function(date){
      let d = new Date(date);
      if(d.getFullYear() == 1970)return '';
      return d.format('yyyy-MM-dd');
    },
    handleAdd: function(){
      this.editItem = {isAdd: true, data: {}};
      this.showEditModal = true;
    },
    handleEdit: function(params){
      this.editItem = {isAdd: false, data: params.row};
      this.showEditModal = true;
    },
    handleDelete: function(params){
      this.$Modal.confirm({
        title: "提示",
        content: `确定要删除站点[${params.row.name}]吗?`,
        onOk: async () => {
          this.$axios.delete(`//${this.domains.trans}/station/lux/list/${params.row.id}`).then(res => {
            if(res.code != 0){
              // this.$Message.error(res.data);
            }else{
              this.getList();
            }
          });
        }
      });
    },
    handleFile: function(){
      this.$Message.warning('暂未实现');
    },
    handleImport: function(){
      this.$Message.warning('暂未实现');
    },
  }
}
</script>
<style scoped>
.config-station-container{
  /* padding: 5px;
  padding-right: 40px; */
  height: 100%;
  display: flex;
  flex-direction: column;
  /* border: solid 1px red; */
}
.station-search-area{
  height: 42px;
  flex: none;
  border: solid 1px #DCDEE2;
  border-radius: 6px;
  margin-bottom: 5px;
  padding-top: 3px;
}
.station-data-area{
  /* border: solid 1px red; */
  height: 100%;
  flex: auto;
  /* border: solid 1px #DCDEE2; */
  border-radius: 6px;
}
</style>