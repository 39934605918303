<template>
  <Modal v-model="showModal" :mask-closable="false" width="1000px">
    <div slot="header" v-if="item">一键控制 - {{item.name}}</div>
    <Form ref="form" :model="form" :label-width="90" inline>
      <FormItem prop="location" label="延时至时间">
        <DatePicker type="datetime" v-model="form.expire" style="width: 175px" format="yyyy-MM-dd HH:mm:ss"></DatePicker>
      </FormItem>
      <FormItem :label-width="0">
        <AuthButton opCode="lvc" size="default" type="error" :loading="loading" @click="handleOpen" style="margin-right: 10px">一键开灯</AuthButton>
        <AuthButton opCode="lvc" size="default" type="default" :loading="loading" @click="handleClose">一键关灯</AuthButton>
      </FormItem>
    </Form>
    <u-table ref="chkTable" :data="devices" style="width: 100%;" row-key="id" border stripe :height="500" default-expand-all>
      <u-table-column prop="id" type="index">
      </u-table-column>
      <u-table-column prop="channelName" label="通道名称" sortable width="120">
        <template slot-scope="params">
          {{params.row.channelName}}
        </template>
      </u-table-column>
      <u-table-column prop="stationName" label="站点名称" sortable width="150">
        <template slot-scope="params">
          {{params.row.stationName}}
        </template>
      </u-table-column>
      <u-table-column prop="channel" label="通道号" sortable width="100">
        <template slot-scope="params">
          K{{params.row.channel}}
        </template>
      </u-table-column>
      <u-table-column prop="timeName" label="时间表" sortable width="150">
        <template slot-scope="params">
          {{params.row.timeName}}
        </template>
      </u-table-column>
      <u-table-column prop="result" label="执行结果" sortable>
        <template slot-scope="params">
          {{getCmdStatus(params.row)}}
        </template>
      </u-table-column>
    </u-table>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">关闭</Button>
    </div>
  </Modal>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
export default {
  name: 'ModalControlGroup',
  components:{
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default(){ return {};}
    },
  },
  data () {
    return {
      showModal: this.value,
      loading: false,
      form: {
        open: false,
        expire: '',
      },
      devices: [],
    }
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if(newVal){
        this.form.open = true;
        let now = new Date();
        now.setHours(now.getHours() + 1);
        this.form.expire = now.format('yyyy-MM-dd HH:mm:ss');
        this.initList();
      }
    },
    showModal(val){
      this.$emit('showChanged', val);
    },
    cmdResultRefresh(){
      // console.log('click commands', this.commands, this.cmdResultRefresh);
      for(let c of this.commands){
        let its = this.devices.filter(p => p.id == c.id);
        if(its.length == 0)continue;
        this.$set(its[0], 'status', c.status)
        this.$set(its[0], 'result', c.result)
        this.$set(its[0], 'cmd', c.code)
        this.$set(its[0], 'content', c.content)
        this.$set(its[0], 'args', c.args)
      }
    },
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapGetters('group', ['groupPathName']),
    ...mapState('cmd', ['cmdResultRefresh', 'commands', 'windowOnResize', 'clearOldLogs']),
    ...mapState('common', ['topDevices', 'productCodes', 'monitorDevices', 'powerBoxTypes', 'mapCenter']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
  },
  mounted: function(){
    // this.getProjects();
  },
  methods: {
    handleAction: function(open){
      let expire = new Date(this.form.expire).format('yyyy-MM-dd HH:mm:ss');
      // console.log('handle action', open, expire, this.devices);
      let cmds = [];
      for(let chk of this.devices){
        let args = {channel: chk.channel, open: open, point: false, expire: expire};
        chk.result = '';
        chk.status = 0;
        cmds.push({id: chk.id, code: 'handControl', name: "一键控制", paramId: chk.id, deviceId: chk.deviceId, args: args});
      }
      this.$store.dispatch('cmd/sendCommands', cmds);
    },
    handleOpen: function(){
      this.$Modal.confirm({
        title: "提示",
        content: `确定要开灯吗?`,
        onOk: async () => {
          this.handleAction(true);
        }
      });
    },
    handleClose: function(){
      this.$Modal.confirm({
        title: "提示",
        content: `确定要关灯吗?`,
        onOk: async () => {
          this.handleAction(false);
        }
      });
    },
    getCmdStatus: function(cmd){
              // console.log('cmd status changed', cmd)
      switch(cmd.status){
        case 0: return `${cmd.result}`;
        case 1: return '等待回复';
        case 2: return '指令超时';
        case 3: return `执行失败：${cmd.result}`;
        case 4: return `执行中：${cmd.content.pkg} / ${cmd.content.total}`;
        case 5: return `失败重试：${cmd.result}`;
        case 9: 
        // console.log('getCmdStatus', cmd)
          switch(cmd.cmd){
            case 'getclock': return `执行成功：${cmd.content.nowTime}`;
            case 'handControl': return `执行成功：${cmd.args.open ? '开灯' :'关灯'} 至时间 ${cmd.args.expire}`;
            case 'getYearPlan': {
              if(cmd.args.month != cmd.content.month){
                return `执行成功：但返回月份不对，需要:${cmd.args.month}，实际:${cmd.content.month}`;
              }else{
                let strs = [];
                let obj = cmd.content[`day${cmd.args.day}`];
                for(let i = 1; i <= 4; i++){
                  let a = this.powerTableActions[obj[`a${i}`]];
                  let t = obj[`t${i}`];
                  strs.push(`${t} - ${a}`);
                }
                return `执行成功：${cmd.args.month}月${cmd.args.day}日计划：${strs.join(', ')}`
              }
            }
            default: return `执行成功`;

          }
      }
    },
    initList: function () {
      if(!this.item.id)return;
      this.$axios.post(`//${this.domains.trans}/station/lux/QueryChannels`, {id: this.item.id}).then(res => {
        if (res.code != 0) {
          // this.$Message.error(res.data);
        } else {
          res.data.map(p => {
            Object.assign(p, {id: p.paramId, time: 0, status: 0, result: ''})
          })
          this.$set(this, 'devices', res.data);
      // console.log('init list', res.data)
        }
      });
    },
    ok: async function () {
      let result = await this.$refs.form.validate();
      if(!result){
        return;
      }
      if(this.form.groupId == 0){
        this.$Message.warning('请选择一个管控分组');
        return;
      }
      if(this.form.dx < 0){
        this.$Message.warning('请设置均差阈值');
        return;
      }
      this.form.install = new Date(this.form.install).format('yyyy-MM-dd');
      this.form.channels = [];
      let req;
      if(this.isAdd){
        req = this.$axios.post(`//${this.domains.trans}/station/lux/list`, this.form);
      }else{
        req = this.$axios.put(`//${this.domains.trans}/station/lux/list/${this.form.id}`, this.form)
      }
      req.then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          this.$Message.info('保存成功');
          this.showModal = false;
          this.$emit('saved');
        }
      });
    },
    cancel () {
      this.showModal = false;
    },
  }
}
</script>
<style scoped>
.form-area{
  /* border: solid 1px red; */
  height: 500px;
  display: flex;
}
.edit-area{
  border: solid 1px rgba(202, 200, 200, 0.473);
  width: 250px;
  flex: none;
  margin-right: 10px;
  padding: 10px;
}
.channel-area{
  border: solid 1px rgba(202, 200, 200, 0.473);
  width: 290px;
  flex: auto;
}
</style>