<template>
  <Modal v-model="showModal" :mask-closable="false" width="600px">
    <div slot="header">编辑光控站点信息</div>
    <Form ref="form" :model="form" :rules="formRules" :label-width="80">
      <FormItem prop="name" label="名称">
        <Input ref="name" type="text" v-model="form.name" placeholder="请输入名称">
        </Input>
      </FormItem>
      <FormItem prop="location" label="安装位置">
        <Input ref="location" type="text" v-model="form.location" placeholder="请输入安装位置">
        </Input>
      </FormItem>
      <FormItem prop="install" label="安装日期">
        <DatePicker ref="install" type="date" format="yyyy-MM-dd" v-model="form.install" placeholder="请选择安装日期" :editable="false"></DatePicker>
      </FormItem>
      <FormItem prop="groupIds" label="管控分组">
        <Select v-model="form.groupIds" multiple>
          <template v-for="(item, idx) in groups">
            <Option :value="item.id" :key="idx">{{item.name}}</Option>
          </template>
        </Select>
      </FormItem>
      <FormItem prop="ruleId" label="应用规则">
        <Select v-model="form.ruleId">
          <template v-for="(item, idx) in rules">
            <Option :value="item.id" :key="idx">{{item.name}}</Option>
          </template>
        </Select>
      </FormItem>
      <FormItem prop="dx" label="均差阈值">
        <Input ref="dx" type="number" number v-model="form.dx" placeholder="请输入均差阈值">
          <span slot="append">%</span>
        </Input>
        <span style="color:red">当光控站点有多个传感器时，会排除采集值与三项均值相差超过此比例的值</span>
      </FormItem>
    </Form>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">取消</Button>
      <Button type="primary" :loading="loading" @click="ok">保存</Button>
    </div>
  </Modal>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
export default {
  name: 'ModalStationEdit',
  components:{
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default(){ return {};}
    },
    groups: {
      type: Array,
      default(){ return [];}
    },
    rules: {
      type: Array,
      default(){ return [];}
    },
  },
  data () {
    return {
      showModal: this.value,
      // showSelectModal: false,
      loading: false,
      isAdd: false,
      groupName: '',
      form: {
        id: 0,
        name: '',
        location: '',
        install: '',
        groupIds: [],
        dx: 5,
        ruleId: 0,
      },
      channels: [],
      formRules: {
        name: { required: true, type: 'string', max: 20, message: '名称不能为空且长度必须少于20个字符', trigger: 'blur' },
        // groupIds: { required: true, type: 'number', min: 1, message: '必须选择管控分组', trigger: 'on-change' },
        location: { required: false, type: 'string', max: 50, message: '安装位置长度必须少于50个字符', trigger: 'blur' },
        dx: { required: true, type: 'number', min: 0, message: '必须设置均差阈值', trigger: 'blur' },
      },
    }
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if(newVal){
        this.isAdd = this.item.isAdd;
        if(!this.isAdd){
          this.form.id = this.item.data.id;
          this.form.groupIds = this.item.data.groupIds;
          this.form.name = this.item.data.name;
          this.form.location = this.item.data.location;
          this.form.dx = this.item.data.dx;
          this.form.ruleId = this.item.data.ruleId;
          this.form.install = new Date(this.item.data.install).format('yyyy-MM-dd');
        }else{
          this.form.id = 0;
          this.form.groupIds = [];
          this.form.name = '';
          this.form.dx = 5;
          this.form.location = '';
          this.form.ruleId = 0;
          this.form.install = new Date().format('yyyy-MM-dd');
        }
      }
    },
    showModal(val){
      this.$emit('showChanged', val);
    },
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapGetters('group', ['groupPathName']),
    ...mapState('cmd', ['cmdResultRefresh', 'cmds']),
    ...mapState('common', ['topDevices', 'productCodes', 'monitorDevices', 'powerBoxTypes', 'mapCenter']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
  },
  mounted: function(){
    // this.getProjects();
  },
  methods: {
    ok: async function () {
      let result = await this.$refs.form.validate();
      if(!result){
        return;
      }
      if(this.form.groupIds.length == 0){
        this.$Message.warning('请至少选择一个管控分组');
        return;
      }
      if(this.form.ruleId == 0){
        this.$Message.warning('请选择一个应用规则');
        return;
      }
      if(this.form.dx < 0){
        this.$Message.warning('请设置均差阈值');
        return;
      }
      this.form.install = new Date(this.form.install).format('yyyy-MM-dd');
      this.$axios.post(`//${this.domains.trans}/station/lux/SaveStation`, this.form).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          this.$Message.info('保存成功');
          this.showModal = false;
          this.$emit('saved');
        }
      })
    },
    cancel () {
      this.showModal = false;
    },
  }
}
</script>
<style scoped>
.form-area{
  /* border: solid 1px red; */
  height: 500px;
  display: flex;
}
.edit-area{
  border: solid 1px rgba(202, 200, 200, 0.473);
  width: 250px;
  flex: none;
  margin-right: 10px;
  padding: 10px;
}
.channel-area{
  border: solid 1px rgba(202, 200, 200, 0.473);
  width: 290px;
  flex: auto;
}
</style>