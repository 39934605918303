<template>
  <div class="monitor-content">
    <div class="a-line" style="height:100%">
      <div class="left-name">
        <img :src="img.light.factory.monitor.adjust.titleBg" />
        <span class="left-title">管<br>理<br>区<br>域</span>
        <img :src="img.light.factory.monitor.adjust.titleBg" />
      </div>
      <div class="right-contentTop">
        <div v-for="(area, idx) in areas" :key="idx" :style="{backgroundImage: `url(${selectedAreaIds.indexOf(area.id) != -1? img.light.factory.monitor.adjust.areaBgActive: img.light.factory.monitor.adjust.areaBg})`,}" :class="['control-area-item',selectedAreaIds.indexOf(area.id) != -1 ? 'selected' : '',]" @click="selectArea(area)">
          <img class="area-title-icon" :src="img.light.factory.monitor.adjust.areaIcon" />
          <div class="area-title-title">{{ area.name }}</div>
          <div class="area-title-id">ID: {{ area.code }}</div>
          <div class="area-title-bright">亮度：{{ area.bright }}%</div>
          <img class="area-item-img" :src="img.light.factory.monitor.adjust.areaImg" />
        </div>
      </div>
    </div>
    <div class="a-line">
      <div class="left-name">
        <img :src="img.light.factory.monitor.adjust.titleBg" />
        <span class="left-title">模<br>式<br>调<br>光</span>
        <img :src="img.light.factory.monitor.adjust.titleBg" />
      </div>
      <div class="right-content" v-if="funCodes('dco')">
        <div v-for="(scene, idx) in scenes" :key="idx" :class="['adjust-area-item', scene.id == selectedLevel ? 'selected' : '',]" @click="selectLevel(scene)">
          <img :src="adjustBrightImg(scene.bright)" style="margin: 0 15px 0 0px" />
          <div style="display: flex; flex-direction: column">
            <div class="area-item-name">{{ scene.name }}</div>
            <div class="area-item-arg">
              亮度：<i class="blue">{{ scene.bright }}%</i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="a-line">
      <div class="left-name">
        <img :src="img.light.factory.monitor.adjust.titleBg" />
        <span class="left-title">自<br>由<br>调<br>光</span>
        <img :src="img.light.factory.monitor.adjust.titleBg" />
      </div>
      <div class="right-content">
        <div style="display: flex;">
          <div class="adjust-title-small">亮度%</div>
          <Input type="number" v-model="bright" number placeholder="请输入亮度" class="adjust-input"></Input>
          <Slider v-model="bright" class="adjust-slider"></Slider>
          <div v-if="funCodes('dco')" class="adjust-button" @click="freeAdjust">发送</div>
          <!-- <div class="adjust-button">定时发送</div> -->
        </div>
      </div>
    </div>
    <div class="a-line">
      <div class="left-name">
        <img :src="img.light.factory.monitor.adjust.titleBg" />
        <span class="left-title">设<br>备<br>运<br>维</span>
        <img :src="img.light.factory.monitor.adjust.titleBg" />
      </div>
      <div class="right-content">
        <div class="power-buttons" v-if="currentStation">
          <div class="power-button-item" v-for="(cmd, idx) in cmds" :key="idx" @click="doCommand(cmd)">
            <template v-if="funCodes(cmd.opcode)">
              <div class="power-button-imgBox" :style="{backgroundImage: `url(${cmd.name== '更多'? img.light.factory.power.devOps2.devOps16:img.light.factory.power.devOps2.devOps15})`}"><img :src="cmd.img" /></div>
              <div>{{cmd.name}}</div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="a-line" v-if="isShowMore">
      <div class="left-name">
      </div>
      <div class="right-content">
        <div class="power-buttons" v-if="currentStation">
          <div class="power-button-item" v-for="(cmd, idx) in cmds" :key="idx" @click="doCommand(cmd)">
            <template v-if="idx>7 && funCodes(cmd.opcode)">
              <div class="power-button-imgBox" :style="{backgroundImage: `url(${img.light.factory.power.devOps2.devOps15})`,}"> <img :src="cmd.img" /></div>
              <div>{{cmd.name}}</div>
            </template>
          </div>
        </div>
      </div>
    </div>

    <ModelMonitorCmdArgs v-model="showArgModal" :item="cmdItem" />
  </div>
</template>
<script>
import { mapState,mapGetters } from "vuex";
// import Vue from "vue";
import ModelMonitorCmdArgs from "@/views/common/command/ModelMonitorCmdArgs";
export default {
  name: "FactoryMonitorItem",
  components: {
    ModelMonitorCmdArgs,
  },
  props: {
    dataLoaded: {
      type: Number,
      default: 0
    },
    adjust: {
      type: Object,
      default () { return {}; }
    },
    areas: {
      type: Array,
      default () { return []; }
    },
    lights: {
      type: Array,
      default () { return []; }
    },
  },
  data() {
    return {
      adjustAreaIds: [], //存储已更新过的区域ID
      selectedAreaIds: [1],
      selectedLevel: 1,
      showArgModal: false,
      cmdItem: {},
      aa: {},
      scenes: [],
      hoverButtonName: "",
      bright: 0, //自由调光亮度值
      showChannelControlModal: false,
      isShowMore: false,
      cmds: [
        // { code: 'forceRead', name: '远程召测', opcode: 'dcs', flag: 1, img: this.img.light.factory.power.devOps2.devOps1 },
        // { code: 'getclock', name: '读取时钟', opcode: 'dcs', flag: 1, img: this.img.light.factory.power.devOps2.devOps2 },
        // { code: 'setclock', name: '校准时钟', opcode: 'dcc', flag: 1, img: this.img.light.factory.power.devOps2.devOps3 },
        // { code: 'getCmdCount', name: '查询指令数量', opcode: 'dcs', flag: 0, img: this.img.light.factory.power.devOps2.devOps6 },
        // { code: 'clearCmds', name: '清除指令队列', opcode: 'dcs', flag: 0, img: this.img.light.factory.power.devOps2.devOps7 },
        // { code: 'reset', name: '重启设备', opcode: 'dco', flag: 2, img: this.img.light.factory.power.devOps2.devOps9 },
        // { code: 'init', name: '初始化', opcode: 'dck', flag: 2, img: this.img.light.factory.power.devOps2.devOps10 },
        // { code: 'getWhite', name: '查询白名单', opcode: 'dcs', flag: 1, img: this.img.light.factory.power.devOps2.devOps11 },
        // { code: 'setWhite', name: '设置白名单', opcode: 'dcc', flag: 1, img: this.img.light.factory.power.devOps2.devOps11 },
        // { code: 'update', name: 'OTA升级', opcode: 'dck', flag: 0, img: this.img.light.factory.power.devOps2.devOps14 },
        // { code: 'setLoraWork', name: '设置Lora参数', opcode: 'dcc', flag: 0, img: this.img.light.factory.power.devOps2.devOps13 },
        // { code: 'getLoraWork', name: '查询Lora参数', opcode: 'dcs', flag: 1, img: this.img.light.factory.power.devOps2.devOps13 },
        // { code: 'getInfo', name: '设备信息', opcode: 'dcs', flag: 1, img: this.img.light.factory.power.devOps2.devOps4 },
        // { code: 'getNetwork', name: '查询网络', opcode: 'dcs', flag: 1, img: this.img.light.factory.power.devOps2.devOps4 },
        // { code: 'getSimInfo', name: 'SIM卡信息', opcode: 'dcs', flag: 1, img: this.img.light.factory.power.devOps2.devOps4 },
        // { code: 'getLuxCtrlMode', name: '查询光控模式', opcode: 'dcs', flag: 1, img: this.img.light.factory.power.devOps2.devOps4 },
        // { code: 'setLuxCtrlMode', name: '设置光控模式', opcode: 'dcc', flag: 0, img: this.img.light.factory.power.devOps2.devOps4 },
        // { code: 'getLuxLevelMode', name: '查询光控挡位', opcode: 'dcs', flag: 1, img: this.img.light.factory.power.devOps2.devOps4 },
        // { code: 'setLuxLevelMode', name: '设置光控挡位', opcode: 'dcc', flag: 0, img: this.img.light.factory.power.devOps2.devOps4 },
        // { code: 'getLuxAutoMode', name: '查询恒照度参数', opcode: 'dcs', flag: 1, img: this.img.light.factory.power.devOps2.devOps4 },
        // { code: 'setLuxAutoMode', name: '设置恒照度参数', opcode: 'dcc', flag: 0, img: this.img.light.factory.power.devOps2.devOps4 },
        // { code: 'getLuxValue', name: '查询光控值', opcode: 'dcs', flag: 1, img: this.img.light.factory.power.devOps2.devOps4 },
      ],
    };
  },
  computed: {
    ...mapGetters('auth', ['funCodes']),
    ...mapState('common', ['productCodes']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
    ...mapState('cmd', ['cmdResultRefresh', "commands"]),
    currentStation: function () {
      if (this.selectedDevices.length > 0) return this.selectedDevices[0];
      return null;
    },
    currentLights: function () {
      if (!this.currentStation) return [];
      let ls = this.currentStation.params.filter(p => p.type == 199);
      ls.sort((a, b) => {
        if (a.name == b.name) return 0;
        return a.name > b.name ? 1 : -1;
      });
      return ls;
    },
    adjustBrightImg: function () {
      return function (bright) {
        if (bright == 0)
          return this.img.light.factory.monitor.adjust.lamp0;
        else if (bright <= 20)
          return this.img.light.factory.monitor.adjust.lamp20;
        else if (bright <= 40)
          return this.img.light.factory.monitor.adjust.lamp40;
        else if (bright == 50)
          return this.img.light.factory.monitor.adjust.lamp50;
        else if (bright <= 60)
          return this.img.light.factory.monitor.adjust.lamp60;
        else if (bright <= 80)
          return this.img.light.factory.monitor.adjust.lamp80;
        else if (bright <= 90)
          return this.img.light.factory.monitor.adjust.lamp90;
        else return this.img.light.factory.monitor.adjust.lamp100;
      };
    },
  },
  watch: {
    dataLoaded(){
      this.selectedAreaIds = [];
      // console.log('adjust chianged 1', this.currentStation, this.adjust, this.selectedNode)
      this.getScenes();
    },
    cmdResultRefresh() {
      this.ajaxExtra()
    },
  },
  mounted: function () {
    this.selectedAreaIds = [];
    this.getScenes();
  },
  methods: {
    getScenes: function(){
      if(!this.selectedNode.type == this.productCodes.station){
        this.$set(this, 'scenes', []);
        return;
      }
      this.$axios.post(`//${this.domains.trans}/station/config/QueryScene`, {groupId: 0, stationId: this.selectedNode.id}).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          this.$set(this, 'scenes', res.data);
          // console.log('get scenes', this.scenes)
        }
      });
    },
    getCmdCount: async function () {
      if (!this.adjust) return;
      this.$axios.post(`//${this.domains.trans}/station/command/device`, { type: 'count', deviceId: this.adjust.deviceId }).then(res => {
        if (res.code !== 0) {
          // this.$Message.error(res.data);
          return;
        }
        this.$Message.info(`当前队列指令数量： ${res.data}`);
      })
    },
    clearCmds: async function () {
      this.$Modal.confirm({
        title: "提示",
        content: `确定要清除[${this.currentStation.name}]的指令队列吗?`,
        onOk: async () => {
          this.$axios.post(`//${this.domains.trans}/station/command/device`, { type: 'clear', deviceId: this.adjust.deviceId }).then(res => {
            if (res.code !== 0) {
              // this.$Message.error(res.data);
              // this.hideLoading();
              return;
            }
            this.$Message.info("清除成功");
          })
        },
      });
    },
    freeAdjust: function () {
      // if (!this.adjust) {
      //   this.$Message.warning("未配置调光属性");
      //   return;
      // }
      if (this.selectedAreaIds.length == 0) {
        this.$Message.warning("请选择要调光的区域");
        return;
      }
      let args = {
        list: this.selectedAreaIds,
        bright: this.bright,
        color: 100,
        expire: 600,
      }
      this.$axios.post(`//${this.domains.trans}/station/command/Cat1AdjustGroup`, args).then(res => {
        if (res.code !== 0) {
          // this.$Message.error(res.data);
          // this.hideLoading();
          return;
        }
        this.areas.map(p => {
          if(this.selectedAreaIds.indexOf(p.id) == -1)return;
          p.bright = this.bright;
          p.color = 100;
        });
        this.$Message.info("已发送分组调光指令");
      })
    },
    setLed: function () {
      let areas = [],
        adjusts = [];
      for (let a of this.areas) {
        areas.push({ name: a.name, group: a.code });
      }
      let cnt = 6 - areas.length
      for (let i = 0; i < cnt; i++) {
        areas.push({ name: "未设置", group: 0 });
      }
      for (let s of this.scenes) {
        adjusts.push({
          name: s.name,
          bright: s.bright,
          expire: s.expire,
        });
      }
      cnt = 6 - adjusts.length;
      for (let i = 0; i < cnt; i++) {
        adjusts.push({ name: "未设置", bright: 0, expire: 1000 });
      }
      let cmds = [];
      cmds.push({
        id: this.adjust.id,
        devName: this.currentStation.name,
        deviceId: this.adjust.deviceId,
        code: "setLed",
        name: "设置控制屏",
        args: { areas: areas, adjusts: adjusts },
      });
      this.cmdItem = {
        cmd: { code: "setLed", name: "设置控制屏", flag: 4 },
        devs: cmds,
      };
      this.showArgModal = true;
    },
    setWhite: function () {
      if (!this.adjust) {
        this.$Message.warning("未配置调光属性");
        return;
      }
      let list = this.lights.filter(p => p.code).map(p => p.code);
      let ls = list.join(",");
      let cmds = [];
      cmds.push({
        id: this.adjust.id,
        devName: this.currentStation.name,
        deviceId: this.adjust.deviceId,
        code: "setWhite",
        name: "设置白名单",
        args: { list: ls },
      });
      this.cmdItem = {
        cmd: { code: "setWhite", name: "设置白名单", flag: 4 },
        devs: cmds,
      };
      this.showArgModal = true;
    },
    handControlByChannel: function () {
      let cmds = [];
      cmds.push({
        id: this.adjust.id,
        devName: this.currentStation.name,
        deviceId: this.adjust.deviceId,
        code: "handControlByChannel",
        name: "回路控制",
        args: {},
      });
      this.cmdItem = {
        cmd: { code: "handControlByChannel", name: "回路控制", monitor: this.adjust },
        devs: cmds,
      };
      this.showArgModal = true;
    },
    setLightName: function () {
      // let areas = [],
      //   adjusts = [];
      let list = [];
      // console.log('set light name', this.currentLights, this.areas)
      // let ls = this.currentLights;
      for (let l of this.currentLights) {
        let devs = this.currentStation.devices.filter(p => p.id == l.deviceId);
        if (devs.length == 0) continue;
        let areas = this.areas.filter(p => p.id == l.content.area);
        let groupId = 0;
        if (areas.length > 0) {
          groupId = areas[0].code;
        }
        list.push({ id: devs[0].code, name: l.name, group: groupId, power: 0, bright: 0 });
      }
      let cmds = [];
      cmds.push({
        id: this.adjust.id,
        devName: this.currentStation.name,
        deviceId: this.adjust.deviceId,
        code: "setLightName",
        name: "设置灯名称",
        args: { list: list },
      });
      this.cmdItem = {
        cmd: { code: "setLightName", name: "设置灯名称", flag: 4 },
        devs: cmds,
      };
      this.showArgModal = true;
    },
    doCommand: function (item) {
      // if (item.name == "更多") {
      //   this.isShowMore = !this.isShowMore;
      //   return false;
      // }
      // if (!this.adjust) {
      //   this.$Message.warning("未配置调光属性");
      //   return;
      // }
      // console.log('do cmd', item, this.adjust);
      switch (item.code) {
        case 'setLed': this.setLed(); break;
        case 'setWhite': this.setWhite(); break;
        case 'getCmdCount': this.getCmdCount(); break;
        case 'clearCmds': this.clearCmds(); break;
        case 'handControlByChannel': this.handControlByChannel(); break;
        case 'setLightName': this.setLightName(); break;
        default: this.doCommand1(item); break;
      }
    },
    doCommand1: function (item) {
      // if (!this.adjust) {
      //   this.$Message.warning("未配置调光属性");
      //   return;
      // }
      let devs = [];
      devs.push({
        id: this.adjust.id,
        devName: this.currentStation.name,
        deviceId: this.adjust.deviceId,
        code: "",
        name: "",
        args: {},
      })
      // }
      this.cmdItem = { cmd: item, devs: devs };
      this.showArgModal = true;
    },
    ajaxExtra: async function () {
      this.commands.forEach((el) => {
        if (el.status == 9 && el.name == "组控单灯") {
          if (this.adjustAreaIds.indexOf(el.id) != -1) return;
          this.adjustAreaIds.push(el.id);
          this.ajaxExtraModify(el)
        }
      });
    },
    ajaxExtraModify: async function (el) {
      let data = {
        "id": el.id,
        "bright": el.args.bright1,
        "expire": el.args.expire,
      };
      let res = await this.$axios.post(`//${this.domains.trans}/station/config/HandGroup`, data);
      if (res.code !== 0) {
        return false;
      }
      let products = this.areas.filter(p => p.id == el.id);
      if (products.length == 0) return false;
      products[0].bright = el.args.bright1;
    },
    selectLevel: function (scene) {
      // if (!this.adjust) {
      //   this.$Message.warning("未配置调光属性");
      //   return;
      // }
      if (this.selectedAreaIds.length == 0) {
        this.$Message.warning("请选择要调光的区域");
        return;
      }
      let args = {
        list: this.selectedAreaIds,
        bright: scene.bright,
        color: scene.color,
        expire: scene.expire,
      }
      this.$axios.post(`//${this.domains.trans}/station/command/Cat1AdjustGroup`, args).then(res => {
        if (res.code !== 0) {
          // this.$Message.error(res.data);
          // this.hideLoading();
          return;
        }
        this.areas.map(p => {
          if(this.selectedAreaIds.indexOf(p.id) == -1)return;
          p.bright = scene.bright;
          p.color = scene.color;
        });
        this.$Message.info("已发送分组调光指令");
      })
    },
    selectArea: function (area) {
      let index = this.selectedAreaIds.indexOf(area.id);
      if (index == -1) {
        this.selectedAreaIds.push(area.id);
      } else {
        this.selectedAreaIds.splice(index, 1);
      }
    },
    buttonOver: function (params) {
      this.hoverButtonName = params;
    },
    buttonOut: function () {
      this.hoverButtonName = "";
    },
  },
};
</script>
<style scoped>
.a-line {
  height: 237px;
  width: 100%;
  display: flex;
  justify-content: left;
}
.left-name {
  width: 132px;
  height: 170px;
  display: flex;
  justify-content: center;
}
.left-title {
  text-align: center;
  font-size: 22px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  padding: 15px 5px 0;
}
.right-contentTop {
  /* border: solid 1px white; */
  width: calc(100% - 182px);
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.right-content {
  /* border: solid 1px white; */
  width: calc(100% - 182px);
  /* height: 170px; */
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.monitor-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  /* position: relative; */
  overflow-y: auto;
  justify-content: space-between;
}
.monitor-title {
  /* border: solid 1px red; */
  height: 70px;
  flex: none;
  display: flex;
  justify-content: center;
  margin: 20px;
}
.monitor-title-center {
  width: 313px;
  text-align: center;
  display: flex;
  flex-direction: column;
  font-size: 22px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}
.form-header {
  /* border: solid 1px red; */
  height: 100px;
  flex: none;
  font-size: 28px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #51e7f3;
  position: relative;
  text-align: center;
  padding-top: 50px;
}
.form-header img {
  position: absolute;
  top: 30px;
  right: 30px;
  width: 16px;
  height: 16px;
  cursor: pointer;
}
.form-content {
  /* border: solid 1px red; */
  height: 100px;
  flex: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.button-item {
  text-align: center;
  /* border: solid 1px red; */
  margin: 0 40px;
  width: 120px;
  /* height: 180px; */
  flex: none;
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  cursor: pointer;
}
.channel-control-form {
  width: 939px;
  height: 532px;
  background: #033977;
  opacity: 0.9;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
}
.channel-control-modal {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background: rgba(0, 0, 0, 0.5);
  /* background: #000000;
  opacity: 0.5; */
}
.adjust-button {
  width: 68px;
  height: 40px;
  background: linear-gradient(0deg, #3da4f3, #48f3f1);
  border-radius: 6px;

  padding: 0 10px;
  margin: 0 10px;
  cursor: pointer;

  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #00203b;
  line-height: 40px;
  text-align: center;
  /* width: 100px; */
}
.adjust-input {
  margin: 0 10px;
  width: 90px;
  background: #084473;
  /* box-shadow: 0px -1px 1px 0px rgba(255, 255, 255, 0.3), 0px 2px 3px 0px #001220; */
  height: 40px;
  border-radius: 5px;
}
.adjust-input >>> .ivu-input {
  height: 40px;
  background: #084473;
  /* box-shadow: 0px -1px 1px 0px rgba(255, 255, 255, 0.3), 0px 2px 3px 0px #001220; */
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  border: none;
}
.adjust-slider {
  width: 180px;
  margin: 0 10px;
}
.adjust-area-free {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0 50px 0;
}
.adjust-title-big {
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}
.adjust-title-small {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  line-height: 40px;
}
.adjust-area-list {
  /* border: solid 1px red; */
  height: 100px;
  flex: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0 50px 0;
}
.adjust-area-item {
  width: 182px;
  height: 109px;
  flex: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  cursor: pointer;
  background: linear-gradient(0deg, #0252ab, #003065);
  border: 1px solid #2a8afa;
  box-shadow: 0px 0px 10px 0px rgba(1, 36, 71, 0.5);
  border-radius: 12px;
}
.selected {
  background: linear-gradient(0deg, #0252ab, #003065);
  border: 1px solid #2a8afa;
  box-shadow: 0px 0px 10px 0px rgba(1, 36, 71, 0.5);
}
.adjust-area-item .blue {
  font-style: initial;
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #01ecff;
}
.power-buttons {
  /* border: solid 1px red; */
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  /* margin-bottom: 30px; */
}
.power-button-item {
  /* border: solid 1px red; */
  width: 120px;
  margin-right: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  cursor: pointer;
  flex-direction: column;
}
.power-button-imgBox {
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  border-radius: 50%;
}
.power-button-imgBox:hover {
  box-shadow: 0px 0px 10px 0px #00c1ff inset;
}
.power-button-item img {
  width: 32px;
  height: 32px;
}
.control-area-list {
  /* border: solid 1px red; */
  height: 210px;
  flex: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0 50px 0;
}
.control-area-item {
  /* border: solid 1px red; */
  width: 182px;
  height: 148px;
  flex: none;
  margin-right: 39px;
  background: linear-gradient(0deg, #00305d, #023b71);
  border-radius: 6px;
  padding: 20px;
  cursor: pointer;
  position: relative;
  float: left;
  margin-top: 20px;
}
.area-title-icon {
  position: absolute;
  top: 7px;
  left: 10px;
}
.area-title-title {
  position: absolute;
  top: 5px;
  left: 40px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}
.area-title-id {
  position: absolute;
  top: 45px;
  left: 10px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}
.area-title-bright {
  position: absolute;
  top: 75px;
  left: 10px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}
.area-item-img {
  position: absolute;
  top: 40px;
  right: 10px;
  width: 50px;
  height: 93px;
}

.area-item-name {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}
.area-item-arg {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  margin-top: 3px;
  color: #ffffff;
}
</style>
