<template>
  <div class="config-container">
    <div class="config-header">
      <div :class="['header-tab-item', item.code == current ? 'header-item-selected' : '']" v-for="(item, idx) in menus" :key="idx" @click="clickMenu(item)">{{item.name}}</div>
    </div>
    <div class="config-content">
      <template v-for="(item, idx) in menus">
        <component v-if="item.code == current" :key="idx" v-bind:is="item.com" :stationId="stationId"></component>
      </template>
    </div>
  </div>
</template>
<script>
import LuxSearchValue from './luxlog/Index'
import LuxSearchAction from './action/Index'
export default {
  name: 'Home',
  components: {
    // AuthMenuItem
  },
  props: {
    sys: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      current: 's1',
      menus: [
        { code: 's1', name: '光照度值查询', com: LuxSearchValue, args: {stationId: 0} },
        { code: 's2', name: '光照度触发动作查询', com: LuxSearchAction, args: {stationId: 0} },
      ],
      stationId: 0,
    }
  },
  watch: {
  },
  mounted: function () {
  },
  methods: {
    setStationId: function(id){
      this.stationId = id;
      // console.log('arg sid change', this.stationId)
    },
    clickMenu: function (item) {
      this.current = item.code;
    },
  }
}
</script>
<style scoped>
.config-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 5px;
  background-color: #fff;
  padding: 20px;
}
.config-header {
  height: 46px;
  flex: none;
  display: flex;
  margin-bottom: 20px;
}
.header-tab-item {
  padding: 0 10px;
  height: 46px;
  background: #e8f0f7;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000;
  text-align: center;
  line-height: 46px;
  cursor: pointer;
  margin-left: 1px;
}
.header-tab-item.header-item-selected {
  background: #007eff;
  color: #ffffff;
}
.config-header .header-tab-item:first-child {
  border-radius: 8px 0px 0px 8px;
}
.config-header .header-tab-item:last-child {
  border-radius: 0px 8px 8px 0px;
}

.config-content {
  /* border: solid 1px rgba(58, 46, 46, 0.445); */
  /* margin: 3px; */
  height: 500px;
  flex: auto;
}
</style>
