<template>
  <div class="config-container">
    <div class="config-tree">
      <CommonTree />
    </div>
    <div class="config-content">
      <ButtonGroup class="config-menu">
        <template v-for="(item, idx) in tabs" >
          <Button :key="idx" @click="switchMenu(item.code)" v-if="funCodes(...item.opCodes)" :type="currentMenu == item.code ? 'primary' : 'default'">{{item.name}}</Button>
        </template>
      </ButtonGroup>
      <template v-for="(item, idx) in tabs">
        <component v-if="item.code == currentMenu" :key="idx" v-bind:is="item.com" :args="item.args"></component>
      </template>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import ConfigGroupIndex from '@/components/config/station/group/Index'
import ConfigGatewayIndex from '@/components/config/light/gateway/Index'
import ConfigAreaIndex from '@/components/config/light/area/Index'
import ConfigLightIndex from '@/components/config/light/lamp1/Index'
import SceneIndex from '@/components/config/light/scene/Index'
import CommonTree from '../tree/Index'
import ConfigPoleIndex from '@/components/config/light/pole1/Index'
import Light6TableIndex from '@/components/config/timetable/ltime6/Index'
import Light7TableIndex from '@/components/config/timetable/ltime7/Index'
import ConfigAccountIndex from '@/views/common/account/Index'
import AlarmIndex from '@/components/config/alarm/Index'
import { mapState } from 'vuex'
export default {
  name: 'FactoryConfigIndex',
  components: {
    CommonTree,
  },
  data() {
    return {
      currentMenu: 's7',
      tabs: [
        { code: 's1', name: '分区管理', com: ConfigGroupIndex, args: {}, opCodes: ['gv', 'ga', 'ge', 'gd'] },
        { code: 's13', name: '外接账号管理', com: ConfigAccountIndex, args: { tab: 's4' }, opCodes:['azv', 'aza', 'aze','azd'] },
        { code: 's2', name: '网关管理', com: ConfigGatewayIndex, args: {}, opCodes: ['cgwv', 'cgwa', 'cgwe','cgwd'] },
        { code: 's3', name: '灯杆管理', com: ConfigPoleIndex, args: {}, opCodes: ['lpv2', 'lpa2', 'lpe2','lpd2'] },
        { code: 's4', name: '分组管理', com: ConfigAreaIndex, args: {}, opCodes: ['lgv', 'lga', 'lge','lgd'] },
        { code: 's11', name: '调光模式', com: SceneIndex, args: {}, opCodes:['lmv', 'lma', 'lme','lmd'] },
        // { code: 's12', name: '情景模式管理', com: ProfileIndex, args: {}, opCodes:['lpv', 'lpa', 'lpe','lpd'] },
        { code: 's6', name: '计时日表管理', com: Light6TableIndex, args: {}, opCodes:['tlv', 'tla', 'tle','tld'] },
        { code: 's14', name: '准时日表管理', com: Light7TableIndex, args: {}, opCodes:['tlv', 'tla', 'tle','tld'] },
        // { code: 's19', name: '调光属性', com: AdjustIndex, args: {}, opCodes:['lav', 'laa', 'lae','lad'] },
        { code: 's7', name: '单灯管理', com: ConfigLightIndex, args: {}, opCodes:['lv', 'la', 'le','ld'] },
        { code: 's10', name: '告警管理', com: AlarmIndex, args: {}, opCodes: ['___'] },
      ],
      isCollapsed: false,
      currentName: 'light', //role,user
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes']),
    ...mapState('common', ['powerBoxAlarmTypes', 'productCodes', 'powerBoxPartTypes', 'powerBoxTypes']),
  },
  watch: {
    $route: {
      handler: function (route) {
        this.currentName = route.path.replace(`/index/light/factory/config/`, '');
      },
      immediate: true
    },
  },
  mounted: function () {
    this.$store.dispatch('group/getGroupAndStations', -1)
    this.$store.dispatch('common/getAlarmTypes', -1)
  },
  methods: {
    switchMenu: function (item) {
      this.currentMenu = item;
    },
  }
}
</script>
<style scoped>
.config-container {
  /* border: solid 1px red; */
  width: 100%;
  display: flex;
}
.config-tree {
  /* border: solid 1px red; */
  width: 300px;
  /* margin: 3px; */
  flex: none;
}
.config-content {
  /* border: solid 1px red; */
  margin: 3px;
  width: 0;
  flex: auto;
  display: flex;
  flex-direction: column;
  /* background: linear-gradient(180deg, #001323, #0E244A);
  box-shadow: inset 0px 0px 32px 0px #013565; */
}
.config-menu {
  flex: none;
  margin: 5px 10px;
}
.menu-content {
  height: 10px;
  flex: auto;
  /* border: solid 1px red; */
}
</style>
