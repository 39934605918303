<template>
  <div class="list-common-container">
    <div class="list-common-query">
      名称：
      <Input v-model="filter.name" type="text" clearable style="width: 100px; margin-right: 10px;"></Input>
      通信ID：
      <Input v-model="filter.code" type="text" clearable style="width: 100px; margin-right: 10px;"></Input>
      单灯类型：
      <Select v-model="filter.type" style="width: 150px; margin-right: 10px;">
        <Option :value="0">全部</Option>
        <template v-for="(v, k) in newLightTypes">
          <Option :key="k" :value="parseInt(k)">{{v}}</Option>
        </template>
      </Select>
      <!-- 时间表类型：
      <Select v-model="filter.timeType" style="width: 150px; margin-right: 10px;">
        <Option :value="-1">全部</Option>
        <Option :value="0">计时日表</Option>
        <Option :value="1">准时日表</Option>
        <Option :value="2">光控模式</Option>
      </Select> -->
      时间表：
      <Select v-model="filter.timeId" style="width: 150px; margin-right: 10px;">
        <Option :value="0">全部</Option>
        <template v-for="item in timeTables">
          <Option :key="item.id" :value="item.id">[{{item.type}}]{{item.name}}</Option>
        </template>
      </Select>
      分组：
      <Select v-model="filter.group" style="width: 150px; margin-right: 10px;">
        <Option :value="0">全部</Option>
        <template v-for="(item, idx) in areas">
          <Option :key="idx" :value="parseInt(item.id)">{{item.name}}[{{item.code}}]</Option>
        </template>
      </Select>
      <Button type="primary" @click="initList">查询</Button>
    </div>
    <div class="alarm-data-area" ref="table">
      <vxe-table border resizable show-overflow show-header-overflow keep-source ref="chkTable" :key="dataRefresh" @checkbox-change="selectChangeEvent" :data="list" :height="tabHeight" :row-config="{isHover: true}" :checkbox-config="{checkField: 'checked',}" :merge-cells="mergeData">
        <!-- :span-method="spanMethods" -->
        <vxe-column width="50" type="checkbox" field="checkbox" fixed="left"></vxe-column>
        <vxe-column v-if="colCanShow('nm')" field="name" title="名称" fixed="left" width="150">
          <template #default="{ row }">
            {{row.name}}
          </template>
        </vxe-column>
        <vxe-column v-if="colCanShow('id')" field="code" title="通信ID" width="150">
          <template #default="{ row }">
            {{row.code}}
          </template>
        </vxe-column>
        <vxe-column v-if="colCanShow('ch')" field="ch" title="通道" width="80">
          <template #default="{ row }">
            K{{row.ch}}:{{row.n}}
          </template>
        </vxe-column>
        <vxe-column v-if="colCanShow('ol')" field="online" title="在线" width="50">
          <template #default="{ row }">
            <img :src="row.online ? img.light.road.tree.online : img.light.road.tree.offline" />
          </template>
        </vxe-column>
        <vxe-column v-if="colCanShow('u')" field="u" title="电压/V" width="100">
          <template #default="{ row }">
            {{img.val(row.u, 2)}}
          </template>
        </vxe-column>
        <vxe-column v-if="colCanShow('c')" field="c" title="电流/A" width="100">
          <template #default="{ row }">
            {{img.val(row.c, 2)}}
          </template>
        </vxe-column>
        <vxe-column v-if="colCanShow('p')" field="p" title="功率/W" width="100">
          <template #default="{ row }">
            {{img.val(row.p, 2)}}
          </template>
        </vxe-column>
        <vxe-column v-if="colCanShow('op')" field="op" title="亮度/%" width="100">
          <template #default="{ row }">
            {{img.val(row.op, 2)}}
          </template>
        </vxe-column>
        <vxe-column v-if="colCanShow('q')" field="q" title="电能/KWh" width="100">
          <template #default="{ row }">
            {{img.val(row.q / 10, 2)}}
          </template>
        </vxe-column>
        <vxe-column v-if="colCanShow('oc')" field="oc" title="色温" width="100">
          <template #default="{ row }">
            {{img.val(row.oc, 2)}}
          </template>
        </vxe-column>
        <vxe-column v-if="colCanShow('cl')" field="cl" title="漏电电流/mA" width="120">
          <template #default="{ row }">
            {{img.val(row.cl, 2)}}
          </template>
        </vxe-column>
        <vxe-column v-if="colCanShow('tc')" field="tc" title="温度/℃" width="80">
          <template #default="{ row }">
            {{img.val(row.tc, 2)}}
          </template>
        </vxe-column>
        <vxe-column v-if="colCanShow('lt')" field="lampType" title="类型" width="120">
          <template #default="{ row }">
            {{row.lampType}}
          </template>
        </vxe-column>
        <vxe-column v-if="colCanShow('an')" field="areaName" title="分组" width="120">
          <template #default="{ row }">
            {{row.areaName}}
          </template>
        </vxe-column>
        <vxe-column v-if="colCanShow('md')" field="mode" title="模式" width="120">
          <template #default="{ row }">
            {{row.mode==0?"计时日表":"准时日表"}}
          </template>
        </vxe-column>
        <vxe-column v-if="colCanShow('tn1')" field="timeName1" title="计时日表" width="120">
          <template #default="{ row }">
            {{row.timeName1}}
          </template>
        </vxe-column>
        <vxe-column v-if="colCanShow('tn2')" field="timeName2" title="准时日表" width="120">
          <template #default="{ row }">
            {{row.timeName2}}
          </template>
        </vxe-column>
        <vxe-column field="fireTime" title="最后通信时间" sortable width="180">
          <template #default="{ row }">
            {{row.fireTime}}
          </template>
        </vxe-column>
        <vxe-column field="result" title="执行结果" width="300" sortable :show-overflow-tooltip="true">
          <template #default="{ row }">
            {{getCmdStatus(row)}}
          </template>
        </vxe-column>
        <vxe-column v-if="colCanShow('gx')" field="gx" title="X轴" width="80">
          <template #default="{ row }">
            {{row.gx}}
          </template>
        </vxe-column>
        <vxe-column v-if="colCanShow('gy')" field="gy" title="Y轴" width="80">
          <template #default="{ row }">
            {{row.gy}}
          </template>
        </vxe-column>
        <vxe-column v-if="colCanShow('gz')" field="gz" title="Z轴" width="80">
          <template #default="{ row }">
            {{row.gz}}
          </template>
        </vxe-column>
      </vxe-table>
    </div>
    <ModalArgEdit v-model="showArgModal" :command="cmdItem" :areas="areas" @saved="argsSaved" />
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import ModalArgEdit from '../args/ModalArgEdit'
import exportXlsx from '@/components/xlsx/exportXlsx'
export default {
  name: 'LightControlListCommon',
  components: {
    ModalArgEdit,
  },
  props: {
    type: {
      type: String,
      default: 'common'
    },
  },
  data() {
    return {
      timeTables: [],
      filter: {
        stationId: 0, //当前站点ID
        group: 0, //所选分组ID
        type: 0, //单灯类型
        timeType: -1, //时间表类型
        timeId: 0, //时间表ID
        code: '', //单灯通信ID
        name: '', //单灯名称
      },
      tabHeight: 600,
      clearOldLog: this.clearOldLogs,
      showArgModal: false,
      showPlanModal: false,
      showCreateModal: false,
      cmdItem: { item: {}, other: {}, args: {} },
      groups: [],
      list: [],
      showTransModal: false,
      lights: [],
      showExtra: false,
      dataRefresh: 0,
      mergeData: [],
      items: [],
      areas: [],
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes']),
    ...mapState('auth', ['config']),
    ...mapState('cmd', ['cmdResultRefresh', 'commands', 'windowOnResize', 'clearOldLogs', 'lightRealDataRefresh']),
    ...mapState('common', ['deviceTypes', 'productCodes', 'connectTypes', 'newLightTypes', 'leakagAlarmeStatus']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
    colCanShow: function(){
      return function(code){
        return this.config.lightShowColumns.indexOf(code) != -1;
      };
    },
  },
  watch: {
    clearOldLog() {
      this.$store.commit('cmd/setOldLogStatus', this.clearOldLog)
    },
    lightRealDataRefresh() {
      this.refreshLightData();
    },
    windowOnResize() {
      this.setTableHeight();
    },
    selectedNode() {
      this.filter.groupId = this.selectedNode.id;
      this.initList();
    },
    cmdResultRefresh() {
      for (let c of this.commands) {
        let lid = parseInt(c.itemId);
        let its = this.list.filter(p => p.lid == lid);
        if (its.length == 0) continue;
        for (let it of its) {
          this.$set(it, 'status', c.status)
          this.$set(it, 'result', c.result)
          this.$set(it, 'cmd', c.code)
          this.$set(it, 'content', c.content)
          this.$set(it, 'args', c.args)
          if (c.status == 9) {
            this.$set(it, 'online', true);
            if (c.code == 'forceRead') {
              this.$set(it, 'c', c.content[`current${it.ch}`].toFixed(2));
              this.$set(it, 'cl', c.content[`leakage${it.ch}`]);
              this.$set(it, 'ct', c.content[`relayTime${it.ch}`]);
              this.$set(it, 'oc', c.content[`outColor${it.ch}`]);
              this.$set(it, 'op', c.content[`outRate${it.ch}`]);
              this.$set(it, 'p', c.content[`power${it.ch}`]);
              this.$set(it, 'po', c.content[`powerOnTime`]);
              this.$set(it, 'mode', c.content[`mode`]);
              this.$set(it, 'q', c.content[`quantity1`]);
              this.$set(it, 'start', `${c.content.startHour}:${c.content.startMinute}`);
              this.$set(it, 'tc', c.content.tempChip);
              this.$set(it, 'tm', c.content.tempModule);
              this.$set(it, 'u', c.content.voltage.toFixed(2));
              this.$set(it, 'w', c.content[`workTime${it.ch}`]);
              this.$set(it, 'fireTime', new Date(c.content.time).format('yyyy-MM-dd HH:mm:ss'));
              this.$set(it, 'gx', c.content.gyrox);
              this.$set(it, 'lampType', this.newLightTypes[c.content.type]);
              this.$set(it, 'timeName1', c.timeName1);
              this.$set(it, 'timeName2', c.timeName2);
              this.$set(it, 'areaName', c.areaName);
              this.$set(it, 'gy', c.content.gyroy);
              this.$set(it, 'gz', c.content.gyroz);
            }
          }
        }
      }
    },
  },
  mounted: function () {
    this.clearOldLog = this.clearOldLogs;
    setTimeout(this.setTableHeight, 100);
    this.queryAreas();
    this.getUseablePlan();
    this.initList();
    // this.$store.dispatch('common/getAlarmTypes');
  },
  destroyed: function () {
  },
  methods: {
    queryAreas: function () {
      this.$axios.post(`//${this.domains.trans}/station/config/QueryArea`, {}).then(res => {
        if (res.code != 0) {
          // this.$Message.error(res.data);
        } else {
          this.$set(this, 'areas', res.data);
        }
      });
    },
    dealCmd: function (params) {
      if (params.code == 'showExtra') {
        this.showExtra = params.args.show;
        return;
      }
      let chks = this.$refs.chkTable.getCheckboxRecords();
      if (chks.length == 0) {
        this.$Message.warning("请选择要处理的单灯");
        return;
      }
      if (params.args.item.needPswd || params.args.item.needArgs) {
        this.cmdItem = { code: params.args.item.code, name: params.args.item.name, pswd: params.args.item.needPswd, args: {} };
        this.showArgModal = true;
      } else {
        if (this.clearOldLog) {
          for (let item of this.list) {
            item.result = '';
            item.status = 0;
          }
        }
        let ids = chks.map(p => p.lid);
        this.$store.dispatch('cmd/sendLightCommand', {ids, code: params.args.item.code, name: params.args.item.name, args: {}}).then(res => {
          if(res.code == 0){
            this.$Message.info({
              title: '指令',
              content: '指令已发送',
            });
          }
        });
      }
    },
    getCheckedItems: function () {//查询所有被选中的单灯记录
      return this.$refs.chkTable.getCheckboxRecords();
    },
    timeTableCanSelected: function (item) {
      if (this.filter.timeType < 0) return true;
      if (this.filter.timeType == 0) return item.type == '计时日表';
      if (this.filter.timeType == 1) return item.type == '准时日表';
      return false;
    },
    getUseablePlan: function () {
      this.$axios.post(`//${this.domains.trans}/station/plan/QueryLightPlan`, {}).then(res => {
        if (res.code != 0) {
          // this.$Message.error(res.data);
        } else {
          this.$set(this, 'timeTables', res.data);
        }
      });
    },
    getLightGroup: function () {
      if (!this.filter.stationId || this.filter.stationId <= 0) {
        this.$set(this, 'groups', []);
        return;
      }
      this.$axios.post(`//${this.domains.trans}/station/config/QueryArea`, { stationId: this.filter.stationId }).then(res => {
        if (res.code != 0) {
          // this.$Message.error(res.data);
        } else {
          this.$set(this, 'groups', res.data);
          setTimeout(() => {
            this.$set(this.filter, 'group', 0);
          }, 100);
        }
      });
    },
    selectChangeEvent({ rowIndex }) {
      this.$refs.chkTable.reloadRow(this.list, null, rowIndex)
    },
    getConnectTypeName: function (type) {
      let ts = this.connectTypes.filter(p => p.code == type);
      if (ts.length == 0) return '';
      return ts[0].name;
    },
    refreshLightData: function () {
      // console.log('light data refresh 1')
      for (let l of this.list) {
        let lls = this.lights.filter(p => p.id == l.lid);
        if (lls.length == 0) continue;
        // console.log('light data refresh', lls)
        if (l.fireTime >= lls[0].fireTime || lls[0].fireTime <= 0) continue;
        l.c = lls[0].lastData[`c${l.ch}`];
        l.cl = lls[0].lastData[`cl${l.ch}`];
        l.ct = lls[0].lastData[`ct${l.ch}`];
        l.oc = lls[0].lastData[`oc${l.ch}`];
        l.op = lls[0].lastData[`op${l.ch}`];
        l.p = lls[0].lastData[`p${l.ch}`];
        l.po = lls[0].lastData.po;
        l.mode = lls[0].lastData.mode;
        l.q = lls[0].lastData[`q1`];
        l.start = `${lls[0].lastData.sh}:${lls[0].lastData.sm}`;
        l.tc = lls[0].lastData.tc;
        l.tm = lls[0].lastData.tm;
        l.u = lls[0].lastData.u.toFixed(2);
        l.w = lls[0].lastData[`w${l.ch}`];
        l.gx = lls[0].lastData[`gx`];
        l.gy = lls[0].lastData[`gy`];
        l.gz = lls[0].lastData[`gz`];
        l.fireTime = new Date(lls[0].fireTime).format('yyyy-MM-dd HH:mm:ss');
        l.lampType = this.newLightTypes[lls[0].lastData[`type`]];
        l.timeName1 = lls[0].timeName1;
        l.timeName2 = lls[0].timeName2;
        l.areaName = lls[0].areaName;
      }
    },
    getCmdStatus: function (cmd) {
      switch (cmd.status) {
        case 0: return `${cmd.result}`;
        case 1: return '等待回复';
        case 2: return '指令超时';
        case 3: return `执行失败：${cmd.result}`;
        case 4: return `执行中：${cmd.content.pkg} / ${cmd.content.total}`;
        case 5: return `失败重试：${cmd.result}`;
        case 9:
          switch (cmd.cmd) {
            case 'getclock': return `执行成功：${cmd.content.nowTime}`;
            case 'getGroup': return `执行成功：当前分组号 ${cmd.content.no}`;
            case 'getInfo': return `硬件版本：${cmd.content.hardware}，软件版本：${cmd.content.software}，生产厂商：${cmd.content.manufacture}`;
            case 'getSimInfo': return `ICCID：${cmd.content.iccid}，IMEI：${cmd.content.imei}，RSSI：${cmd.content.rssi}`;
            case 'getDayPlan1': {
              let str = '';
              for (let n = 0; n < 6; n++) {
                let item = cmd.content[n];
                if (!item) continue;
                str += `时段${n + 1}，时间：${item.min}分，`;
                for (let c = 0; c < 4; c++) {
                  let bright = item[`ch${c + 1}`];
                  let color = item[`c${c + 1}`];
                  if (bright != undefined) {
                    str += `${c + 1}通道亮度：${bright}，`;
                  }
                  if (color != undefined) {
                    str += `${c + 1}通道色温：${color}，`;
                  }
                }
                // str += `时段${n+1}：时间：${item.min}分,1通道亮度：${item.ch1}，1通道色温：${item.c1},2通道亮度：${cmd.content[`bright2${n}`]}，2通道色温：${cmd.content[`color2${n}`]};`
              }
              return str;
            }
            case 'getDayPlan2': {
              let str = '';
              for (let n = 0; n < 6; n++) {
                let item = cmd.content[n];
                if (!item) continue;
                str += `时段${n + 1}，时间：${item.min}，`;
                for (let c = 0; c < 4; c++) {
                  let bright = item[`ch${c + 1}`];
                  let color = item[`c${c + 1}`];
                  if (bright != undefined) {
                    str += `${c + 1}通道亮度：${bright}，`;
                  }
                  if (color != undefined) {
                    str += `${c + 1}通道色温：${color}，`;
                  }
                }
                // str += `时段${n}：${cmd.content[`enable1${n}`] ? '启用' : '禁用'}；时间：${cmd.content[`start1${n}`]},1通道亮度：${cmd.content[`bright1${n}`]}，1通道色温：${cmd.content[`color1${n}`]},2通道亮度：${cmd.content[`bright2${n}`]}，2通道色温：${cmd.content[`color2${n}`]};`
              }
              return str;
            }
            case 'getPlanType': {
              switch (cmd.content.type) {
                case 0: return '计时日表';
                case 1: return '准时日表';
                case 2: return '光控模式';
                case 255: return '日表无效';
                default: return '计时日表';
              }
            }
            case 'getNetwork': return `IP:${cmd.content.host}, 端口:${cmd.content.port}, 心跳间隔:${cmd.content.heart}秒`
            case 'getLuxOpenValue': return `光感值:${cmd.content.lux}`
            case 'getAlarmStatus': {
              return `电压报警:${cmd.content.voltage ? '启用' : '禁用'}, 电流报警：${cmd.content.current ? '启用' : '禁用'}, 温度报警：${cmd.content.temp ? '启用' : '禁用'}, 漏电报警：${cmd.content.leakage ? '启用' : '禁用'}`
            }
            case 'getLeakageAlarmLevel': {
              return `一级：${cmd.content.l1}; 二级：${cmd.content.l2}; 三级：${cmd.content.l3}`;
            }
            case 'getLeakageAlarmEnable': {
              return `状态：${cmd.content.enable ? '启用' : '禁用'}; 检查时间：${cmd.content.check}; 上报次数：${cmd.content.count}`;
            }
            case 'getLeakageAlarmStatus': {
              return `状态：${this.leakagAlarmeStatus[cmd.content.level]}; 漏电值：${cmd.content.val};`;
            }
            case 'getUnionParam': {
              return `
              <p>灯1延时时间：${cmd.content.keep1}秒</p>
              <p>灯2延时时间：${cmd.content.keep2}秒</p>
              <p>是否启用感应启用：${cmd.content.sensor == 1 ? '有效' : '无效'}；</p>
              <p>是否发送433信号：${cmd.content.txen > 10 ? '有效' : '无效'}；</p>
              <p>重发周期：${cmd.content.period}秒</p>
              <p>联动编号：${cmd.content.rfsn}；</p>
              <p>433发送功率：${cmd.content.txpower}；</p>
              <p>433应用频道：${cmd.content.channel}；</p>
              <p>响应范围：${cmd.content.range}；</p>
              <p>是否接收433信号：${cmd.content.rxen == 1 ? '接收' : '不接收'}；</p>
              <p>联动方式：${cmd.content.mode == 1 ? '联动控制' : '灯杆互联'}；</p>
              <p>重发次数：${cmd.content.repeat}；</p>
              `
            }
            default: return `执行成功`;

          }
      }
    },
    initList: function () {
      this.$axios.post(`//${this.domains.trans}/station/light/QueryLight`, this.filter).then(res => {
        if (res.code != 0) {
          // this.$Message.error(res.data);
        } else {
          this.$set(this, 'lights', res.data);
          let devs = [];
          for (let l of res.data) {
            for (let i = 1; i <= 4; i++) {
              if (!l.content[`en${i}`]) continue;
              devs.push({
                id: `l${l.id}c${i}`,
                lid: l.id,
                name: l.name,
                code: l.code,
                typeName: l.deviceTypeName,
                connectType: l.connectType,
                devId: l.deviceId,
                online: l.online,
                fireTime: l.fireTime == 0 ? '-' : new Date(l.fireTime).format('yyyy-MM-dd HH:mm:ss'),
                tc: l.lastData && typeof (l.lastData.tc) == 'number' && l.lastData.tc >= 0 ? l.lastData.tc : '-',
                tm: l.lastData && typeof (l.lastData.tm) == 'number' && l.lastData.tm >= 0 ? l.lastData.tm : '-',
                start: l.lastData && typeof (l.lastData.sh) == 'number' && l.lastData.sh >= 0 ? `${l.lastData.sh}:${l.lastData.sm}` : '-',
                po: l.lastData && typeof (l.lastData.po) == 'number' && l.lastData.po >= 0 ? l.lastData.po : '-',
                u: l.lastData && typeof (l.lastData.u) == 'number' && l.lastData.u >= 0 ? l.lastData.u.toFixed(2) : '-',
                mode: l.lastData && typeof (l.lastData.mode) == 'number' && l.lastData.mode >= 0 ? l.lastData.mode : '-',
                ch: i,
                n: l.content[`nm${i}`],
                op: l.lastData && typeof (l.lastData[`op${i}`]) == 'number' && l.lastData[`op${i}`] >= 0 ? l.lastData[`op${i}`] : '-',
                oc: l.lastData && typeof (l.lastData[`oc${i}`]) == 'number' && l.lastData[`oc${i}`] >= 0 ? l.lastData[`oc${i}`] : '-',
                ct: l.lastData && typeof (l.lastData[`ct${i}`]) == 'number' && l.lastData[`ct${i}`] >= 0 ? l.lastData[`ct${i}`] : '-',
                w: l.lastData && typeof (l.lastData[`w${i}`]) == 'number' && l.lastData[`w${i}`] >= 0 ? l.lastData[`w${i}`] : '-',
                p: l.lastData && typeof (l.lastData[`p${i}`]) == 'number' && l.lastData[`p${i}`] >= 0 ? l.lastData[`p${i}`] : '-',
                q: l.lastData && typeof (l.lastData[`q1`]) == 'number' && l.lastData[`q1`] >= 0 ? l.lastData[`q1`] : '-',
                c: l.lastData && typeof (l.lastData[`c${i}`]) == 'number' && l.lastData[`c${i}`] >= 0 ? l.lastData[`c${i}`].toFixed(2) : '-',
                cl: l.lastData && typeof (l.lastData[`cl${i}`]) == 'number' && l.lastData[`cl${i}`] >= 0 ? l.lastData[`cl${i}`] : '-',
                gx: l.lastData && typeof (l.lastData[`gx`]) == 'number' && l.lastData[`gx`] >= 0 ? l.lastData[`gx`] : '-',
                gy: l.lastData && typeof (l.lastData[`gy`]) == 'number' && l.lastData[`gy`] >= 0 ? l.lastData[`gy`] : '-',
                gz: l.lastData && typeof (l.lastData[`gz`]) == 'number' && l.lastData[`gz`] >= 0 ? l.lastData[`gz`] : '-',
                lampType: this.newLightTypes[l.content[`type`]],
                timeName1: l.timeName1,
                timeName2: l.timeName2,
                areaName: l.areaName,
                cmdId: 0,
                status: 0,
                content: {},
                result: '',
              });
            }
          }
          devs.sort((a, b) => {
            if (a.name == b.name) return 0;
            return a.name > b.name ? 1 : -1;
          })
          this.$set(this, 'list', devs);
          this.spanData();
          // this.$refs.chkTable.setAllCheckboxRow()
        }
      });
    },
    spanData() {
      this.mergeData = [];
      let spanCols = [{
        name: '全选',
        col: 0
      }, {
        name: '名称',
        col: 1
      }, {
        name: '通信ID',
        col: 2
      }, {
        name: '在线',
        col: 4
      }, {
        name: '电压',
        col: 5
      }, {
        name: '温度',
        col: 11
      }, {
        name: '最后通信时间',
        col: 12
      }, {
        name: '执行结果',
        col: 13
      }];
      if (this.list.length < 2) {
        return false;
      }
      let data = "";
      let index = { row: 1, col: 1, rowspan: 1, colspan: 1 }
      for (let i = 0; i < this.list.length; i++) {
        if (this.list[i + 1] && this.list[i].lid == this.list[i + 1].lid) {
          if (index.rowspan == 1) {
            index.row = i;
          }
          index.rowspan++;
        } else {
          if (index.rowspan > 1) {
            for (let p = 0; p < spanCols.length; p++) {
              index.col = spanCols[p].col;
              data = JSON.parse(JSON.stringify(index));
              this.mergeData.push(data)
            }
          }
          index.rowspan = 1;
        }
      }
      this.dataRefresh++;
    },
    exportResult: function () {
      let cmdData = [];
      this.list.map(item => {
        cmdData.push({
          name: item.name,
          sname: item.sname,
          code: item.code,
          result: this.getCmdStatus(item)
        })
      });
      let tabHead = {
        name: '通道名称',
        sname: '站点名称',
        code: '通信ID',
        result: '执行结果'
      }
      exportXlsx(cmdData, tabHead, "指令执行结果");
    },
    setTableHeight: function () {
      this.tabHeight = this.$refs.table.clientHeight - 5;
    },
    sendFailed: function () {
      let chks = this.$refs.chkTable.getCheckboxRecords();
      let sids = [];
      for (let d of this.list) {
        if ((d.status == 2 || d.status == 3) && chks.filter(p => p.id == d.id).length > 0) {
          this.$set(d, 'status', 0)
          this.$set(d, 'result', '准备重发')
          sids.push(d.id);
          // this.$set(d, 'cmd', c.code)
          // this.$set(d, 'content', c.content)
        } else if (this.clearOldLog) {
          this.$set(d, 'status', 0)
          this.$set(d, 'result', '')
        }
      }
      setTimeout(() => {
        this.$store.dispatch('cmd/sendFailed', sids).then(res => {
          if (res.code == 0) {
            for (let c of this.commands) {
              let its = this.list.filter(p => p.id == c.id);
              if (its.length == 0) continue;
              this.$set(its[0], 'status', c.status)
              this.$set(its[0], 'result', c.result)
              this.$set(its[0], 'cmd', c.code)
              this.$set(its[0], 'content', c.content)
            }
          }
        });
      }, 500)
    },
    getCmdCount: async function (item) {
      // if (!this.currentAdjustParam) return;
      // console.log('get cmd cnt', item)
      item.status = 0;
      item.result = `开始查询队列指令数量`;
      this.$axios.post(`//${this.domains.trans}/station/command/device`, { type: 'count', deviceId: item.devId }).then(res => {
        if (res.code !== 0) {
          // this.$Message.error(res.data);
          // this.hideLoading();
          // return;
          item.status = 3;
          item.result = `${res.data}`;
        } else {
          item.status = 9;
          item.result = `当前队列指令数量： ${res.data}`;
        }
        // this.$Message.info(`当前队列指令数量： ${res.data}`);
      })
    },
    clearCmds: async function (item) {
      this.$Modal.confirm({
        title: "提示",
        content: `确定要清除[${item.name}]的指令队列吗?`,
        onOk: async () => {
          item.status = 0;
          item.result = `开始清除队列指令数量`;
          this.$axios.post(`//${this.domains.trans}/station/command/device`, { type: 'clear', deviceId: item.devId }).then(res => {
            if (res.code !== 0) {
              item.status = 3;
              item.result = `执行失败： ${res.data}`;
            } else {
              item.status = 9;
              item.result = `指令队列清除成功`;
            }
          })
        },
      });
    },
    argsSaved: function (params) {
      if (this.clearOldLog) {
        for (let item of this.list) {
          item.result = '';
          item.status = 0;
        }
      }
      let chks = this.$refs.chkTable.getCheckboxRecords();
      let ids = chks.map(p => p.lid);
      let ns = chks.map(p => p.name);
      let args = this.clone(params.args);
      let code = params.code;
      if (params.code == 'openSingle' || params.code == 'closeSingle') {
        code = 'handSingle';
      } else if (params.code == 'update') {
        args.flag = 1;
      }
      if (params.code == 'update') {
        this.$Modal.confirm({
          title: "提示",
          content: `确定要升级设备[${ns}]吗?`,
          onOk: async () => {
            // this.$store.dispatch('cmd/sendCommands', cmds);
            this.$store.dispatch('cmd/sendLightCommand', {ids, code, name: params.name, args})
          },
        });
      } else {
        // this.$store.dispatch('cmd/sendCommands', cmds);
        this.$store.dispatch('cmd/sendLightCommand', {ids, code, name: params.name, args})
      }
    },
  }
}
</script>
<style scoped>
.list-common-container {
  /* border: solid 1px red; */
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: stretch;
}
.list-common-query {
  /* border: solid 1px red; */
  height: 40px;
  flex: none;
  background-color: white;
  margin: 3px 1px;
  display: flex;
  align-items: center;
  padding: 10px;
  white-space: nowrap;
}
.transfer-flex {
  width: 50%;
}
.btns-flex {
  width: 50%;
  display: flex;
  flex-wrap: wrap;
}
.alarm-data-area {
  /* border: solid 1px red; */
  height: 100px;
  flex: auto;
  border-radius: 6px;
}
.tab-active {
  border: solid 1px rgb(135, 159, 224);
  background-color: rgb(135, 159, 224);
  color: white;
}
.station-alarm-tabcontent {
  height: 500px;
  flex: auto;
  /* border: solid 1px red; */
}
.alarm-data-area >>> .plTableBox .el-table tr .umy-table-beyond {
  white-space: break-spaces !important;
  color: #324252;
}
/* .box-body{
  height: 0;
  flex: auto;
  margin-top: 5px;
  border: solid 1px red;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  position: relative;
}
.box-body::-webkit-scrollbar {
  display: none;
} */
</style>