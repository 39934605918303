<template>
  <div class="search-data-container">
    <div class="search-data-header">
      <div class="search-data-query">
        单灯：
        <Select v-model="form.paramId" style="width: 100px; margin: 0 10px">
          <Option :value="0">全部单灯</Option>
          <Option :value="l.id" v-for="l in lights" :key="l.id">{{l.name}}</Option>
        </Select>
        级别：
        <Select v-model="form.level" number style="margin-right: 10px; width: 100px;">
          <Option :value="0">全部</Option>
          <template v-for="(name, level) in alarmLevels">
            <Option :value="level" :key="level">{{name}}</Option>
          </template>
        </Select>
        类型：
        <Select v-model="form.type" number style="margin-right: 10px; width: 150px;">
          <Option :value="0">全部</Option>
          <template v-for="(name, t) in alarmTypes">
            <Option :value="t" :key="t">{{name}}</Option>
          </template>
        </Select>
        起始时间：
        <DatePicker v-model="form.start" type="datetime" format="yyyy-MM-dd HH:mm" placeholder="请选择开始时间" style="margin-right: 10px; width: 155px;"></DatePicker>
        截止时间：
        <DatePicker v-model="form.end" type="datetime" format="yyyy-MM-dd HH:mm" placeholder="请选择截止时间" style="margin-right: 10px; width: 155px;"></DatePicker>
        <Button v-if="funCodes('ars')" type="info" :loading="loading" @click="getList">查询</Button>
        <Button type="success" @click="exportData" style="margin:3px 10px;">导出</Button>
      </div>
    </div>
    <div class="search-data-content" ref="table1">
      <u-table ref="chkTable" :data="list" style="width: 100%;" row-key="id" border stripe :height="tabHeight" :page-sizes="[15, 50, 100, 200, 500]" :pagination-show="true" :total="form.total" :page-size="form.size" :current-page="form.num" @handlePageSize="handlePageSize">
        <u-table-column prop="id" type="selection"></u-table-column>
        <u-table-column prop="id" label="报警ID" width="80">
          <template slot-scope="params">
            {{params.row.id}}
          </template>
        </u-table-column>
        <u-table-column prop="stationId" label="报警站点" sortable width="150">
          <template slot-scope="params">
            {{params.row.stationName}}
          </template>
        </u-table-column>
        <u-table-column prop="paramId" label="报警属性" sortable>
          <template slot-scope="params">
            {{params.row.pName}}
          </template>
        </u-table-column>
        <u-table-column prop="level" label="报警级别" sortable>
          <template slot-scope="params">
            {{alarmLevels[params.row.level]}}
          </template>
        </u-table-column>
        <u-table-column prop="type" label="报警类型" sortable>
          <template slot-scope="params">
            {{params.row.name}}
          </template>
        </u-table-column>
        <u-table-column prop="type" label="报警信息" sortable>
          <template slot-scope="params">
            {{params.row.extra}}
          </template>
        </u-table-column>
        <u-table-column prop="startTime" label="发生时间" sortable wdith="200">
          <template slot-scope="params">
            {{params.row.createTime}}
          </template>
        </u-table-column>
        <u-table-column prop="confirmTime" label="消除时间" sortable wdith="200">
          <template slot-scope="params">
            {{params.row.doneTime}}
          </template>
        </u-table-column>
        <u-table-column width="145">
          <template slot="header">
            <AuthButton opCode='ars' style="margin-right: 5px" @click="getList">刷新</AuthButton>
            <AuthButton opCode='ard1' type="error" @click="handleMutiDelete">批量删除</AuthButton>
          </template>
          <template slot-scope="scope">
            <AuthButton opCode='ars' style="margin-right: 5px" @click="showAlarmHisData(scope.row)">查看</AuthButton>
            <!-- <AuthButton opCode='arc' style="margin-right: 5px" @click="handleEdit(scope)">处理</AuthButton> -->
            <AuthButton opCode='ard1' type="error" @click="handleDelete(scope)">删除</AuthButton>
          </template>
        </u-table-column>
      </u-table>
      <Spin size="large" fix v-if="loading"></Spin>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
// import exportXlsx from '@/components/xlsx/exportXlsx'
// import TempHistoryData from './PowerHistoryItem'
// import VirtualList from 'vue-virtual-scroll-list'
export default {
  name: 'SearchPowerboxIndex',
  components: {
    // VirtualList,
    // 'virtual-list': VirtualList
  },
  props: {
  },
  data() {
    return {
      loading: false,
      curTab: 'group',
      tabHeight: 1,
      // tempHistoryData: TempHistoryData,
      pos: {},
      form: {
        paramId: 0,
        level: 0,
        type: 0,
        start: "",
        end: "",
        size: 15,
        index: 1,
      },
      viewCode: '',
      showTable: true,
      list: [],
      searchLight: null,
      lights: [],
      dataRefresh: 0,
      tabData: [],
      mergeData: [],
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes']),
    ...mapState('cmd', ['windowOnResize', 'cmds']),
    ...mapState('common', ['weeks', 'productCodes', 'alarmTypes', 'alarmLevels']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
    selectedStations: function () {
      return this.selectedDevices.filter(p => p.type == this.productCodes.station);
    },
    selectedStationIds: function () {
      let pids = this.selectedDevices.filter(p => p.type == this.productCodes.station).map(p => { return p.id });
      return pids;
    },
  },
  watch: {
    selectedNode() {
      this.initLights();
    },
    windowOnResize() {
      this.setTableHeight();
    },
  },
  mounted: function () {
    let now = new Date();
    now.setDate(now.getDate() + 1);
    this.form.end = now.format('yyyy-MM-dd HH:mm:ss');
    now.setDate(now.getDate() - 3);
    this.form.start = now.format('yyyy-MM-dd HH:mm:ss');
    setTimeout(() => {
      this.setTableHeight();
    }, 100);
    this.initLights();
    // this.getList();
  },
  destroyed: function () {
  },
  methods: {
    initLights: function () {
      this.$axios.post(`//${this.domains.trans}/station/light/QueryLight`, { groupId: this.selectedNode.id }).then(res => {
        if (res.code != 0) {
          // this.$Message.error(res.data);
        } else {
          this.$set(this, 'lights', res.data);
          this.form.paramId = 0;
        }
      });
    },
    handlePageSize: function(params){
      // console.log('handle page size', params);
      this.form.size = params.size;
      this.form.index = params.page;
      this.getList();
    },
    setTableHeight: function () {
      this.tabHeight = this.$refs.table1.clientHeight - 55;
    },
    setDefaultStation: function () {
      if (this.selectedStations.length == 0) {
        this.form.stationId = 0;
        return;
      }
      if (this.form.stationId == 0) {
        this.form.stationId = this.selectedStations[0].id;
        return;
      }
      let ss = this.selectedStations.filter(p => p.id == this.form.stationId)
      if (ss.length == 0) {
        this.form.stationId = this.selectedStations[0].id;
      }
    },
    exportData() {
      this.$refs.chkTable.openExport()
    },
    getList() {
      this.form.start = new Date(this.form.start).format("yyyy-MM-dd HH:mm:ss");
      this.form.end = new Date(this.form.end).format("yyyy-MM-dd HH:mm:ss");
      let params = {
        groupId: this.selectedNode.id,
        paramId: this.form.paramId,
        level: this.form.level,
        type: this.form.type,
        done: false, 
        start: this.form.start, 
        end: this.form.end, 
        size: this.form.size, 
        index: this.form.index
      };
      this.$axios.post(`//${this.domains.trans}/station/light/QueryAlarm`, params).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          this.form.total = res.data.count;
          this.$set(this, 'list', res.data.list);
          // console.log('done time', res.data.list)
        }
      });
    },
    selectChangeEvent({ rowIndex }) {
      this.$refs.chkTable.reloadRow(this.dataList, null, rowIndex)
    },
    handleMutiDelete: function(){
      let chks = this.$refs.chkTable.getCheckboxRecords();
      if(chks.length == 0){
        this.$Message.warning("请选择要删除的记录");
        return;
      }
      let ids = chks.map(p => p.id);
      this.$Modal.confirm({
        title: "提示",
        content: `确定要删除所选报警记录吗?`,
        onOk: async () => {
          this.$axios.post(`//${this.domains.trans}/station/alarm/DeleteAlarm`, {ids}).then(res => {
            if(res.code != 0){
              // this.$Message.error(res.data);
            }else{
              this.$Message.info('删除成功');
              this.getList();
            }
          });
        }
      });
    },
    handleDelete: function(params){
      this.$Modal.confirm({
        title: "提示",
        content: `确定要删除报警记录[${params.row.id}]吗?`,
        onOk: async () => {
          this.$axios.post(`//${this.domains.trans}/station/alarm/DeleteAlarm`, {ids: [params.row.id]}).then(res => {
            if(res.code != 0){
              // this.$Message.error(res.data);
            }else{
              this.$Message.info('删除成功');
              this.getList();
            }
          });
        }
      });
    },
  }
}
</script>
<style scoped>
.active {
  background-color: #f2f6fa;
}
td {
  height: 40px;
  white-space: nowrap;
  width: 120px;
  border: solid 1px #cfe3f7;
}
.sub-header {
  background-color: #cfe3f7;
  /* background: #CFE3F7; */
  height: 46px;
  text-align: center;
}
.history-item {
  /* border-width: 1px;
  border-collapse: collapse;
  border-color: cyan; */
  width: 100%;
  background-color: white;
  margin-bottom: 30px;
}
.search-data-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: stretch;
  /* border: solid 1px blue; */
}
.search-data-header {
  height: 40px;
  flex: none;
  display: flex;
  /* border: solid 1px blue; */
}
.search-data-content {
  height: 100px;
  flex: auto;
  overflow-y: auto;
  /* border: solid 1px blue; */
}
.search-data-query {
  width: 830px;
  flex: auto;
  background-color: white;
  /* margin: 3px 1px; */
  display: flex;
  align-items: center;
  padding: 10px;
  /* border: solid 1px blue; */
  white-space: nowrap;
}
.search-data-view {
  width: 300px;
  flex: auto;
  display: flex;
  align-items: center;
  /* border: solid 1px blue; */
  white-space: nowrap;
  padding: 10px;
}
.table-data {
  overflow: auto;
}
</style>