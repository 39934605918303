<template>
  <div class="lux-dashboard">
    <div class="control-group-tree">
      <template v-for="(item, idx) in groups">
        <a href="javascript:void(0);" :key="idx" :class="['module-nva-but', groupsIndex == item.id ? 'active' : '']" @click="clickItem(item)">
          <img :src="img.lux.nav.treeIcon" />
          {{item.name}}</a>
      </template>
    </div>

    <div class="control-tab">
      <ModalControlGroup :item="selectedItem" :itemData="itemData" />
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import ModalControlGroup from './ModalControlGroup'
export default {
  name: 'LuxDashborad',
  components: {
    ModalControlGroup,
  },
  data() {
    return {
      list: [],
      luxRules: [],
      ticker: null,
      showTransModal: false,
      monitorDevices: {},
      timeout: null,
      groups: [],
      groupsIndex: 0,
      itemData:0,
      selectedItem: {},
    }
  },
  computed: {
    ...mapState('auth', ['agentChangeValue']),
    ...mapState('group', ['selectedNode']),
    ...mapState('common', ['powerBoxTypes', 'productCodes']),
    ...mapState('cmd', ['luxDataChanged', 'luxParams']),
    online: function () {
      return function (item) {
        // console.log('online status', new Date().getTime() - item.ts, item.ts)
        if (item.ts <= 0) return '离线';
        if ((new Date().getTime() - item.ts) > 120 * 1000) return '离线';
        return '在线'
      }
    },
    alarmStatus: function () {
      return function (item) {
        // console.log('alarm status', item, this.luxRules)
        let rules = this.luxRules.filter(p => p.id == item.ruleId);
        if (rules.length == 0) return 'status-common';
        let rate = rules[0].openRate;
        if (!rate) return 'status-common';
        if (rate <= 0) return 'status-common';
        if (!item.lastData) return 'status-common';
        if (item.lastData.l <= 0) return 'status-common';
        if (item.lastData.l < rate) return 'status-alarm';
        return 'status-common';
      }
    },
  },
  watch: {
    agentChangeValue() {
      // this.getGroups();
    },
    luxDataChanged() {
      for (let key in this.luxParams) {
        let item = this.luxParams[key];
        let ps = this.list.filter(p => p.paramId == key);
        if (ps.length == 0) continue;
        if (ps[0].ts > item.ts) continue;
        ps[0].ts = item.ts;
        ps[0].lastData = item.content;
      }
    },
  },
  mounted: function () {
    this.getGroups();
  },
  destroyed: function () {
    if (this.ticker) {
      clearInterval(this.ticker);
      this.ticker = null;
    }
  },
  methods: {
    clickItem: function (item) {
      this.groupsIndex = item.id;
      this.selectedItem = item;
      this.itemData = Math.random();
    },
    getGroups: function () {
      this.$axios.post(`//${this.domains.trans}/station/lux/QueryGroup`, {}).then(res => {
        if (res.code != 0) {
          // this.$Message.error(res.data);
        } else {
          this.$set(this, 'groups', res.data);
          if(res.data.length > 0){
            this.clickItem(res.data[0])
          }
        }
      });
    },

  }
}
</script>
<style scoped>
.lux-dashboard {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.control-group-tree {
  width: 250px;
  height: 100%;
  background: #ffffff;
  padding-top: 20px;
}
.control-tab {
  width: calc(100% - 270px);
  height: 100%;
  background: #ffffff;
}
.module-nva-but {
  width: 250px;
  height: 48px;
  display: inline-block;
  line-height: 48px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #021629;
}
.module-nva-but.active {
  background: #ddeffe;
}
.module-nva-but.active::after {
  content: "";
  width: calc(100%);
  height: 48px;
  display: block;
  margin: -48px auto 0;
  border-left: 4px solid #3880fc;
  border-radius: 0px 6px 6px 0px;
}
.module-nva-but img {
  vertical-align: middle;
  margin-left: 40px;
  margin-right: 10px;
}
</style>