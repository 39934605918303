<template>
<div class="auth-container">
  <div class="auth-header">
    <template v-for="item in menus">
      <!-- <AuthMenuDiv  :key="item.code" :class="['auth-menu', currentName == item.code ? 'menu-selected' : '']" :opCodes="item.ops" :title="item.name" @click.native="menuSelected(item.code)"></AuthMenuDiv> -->
      <div v-if="funCodes(...item.ops)" :key="item.code" :class="['auth-menu', currentName == item.code ? 'menu-selected' : '']" @click="menuSelected(item.code)">{{item.name}}</div>
    </template>
    <div class="auth-empty"></div>
    <div class="auth-hide" @click="clickHide"></div>
  </div>
  <div class="auth-content">
    <template v-for="(item, idx) in menus">
      <component v-if="item.code == currentName" :key="idx" v-bind:is="item.com" :args="item.args"></component>
    </template>
  </div>
</div>
</template>
<script>
import { mapGetters } from 'vuex'
// import CustSetting from './cust/Setting'
import CustList from '@/components/sys/cust/Index'
import SysSetting from '@/components/sys/setting/Index'
// import RoleList from './role/Index'
// import UserList from './user/Index'
// import LangList from '@/components/sys/lang/Index'
import OpenList from '@/components/sys/open/Index'
// import DictList from '@/components/sys/dict/Index'
// import FormList from '@/components/sys/form/Index'
// import SourceList from '@/components/sys/source/Index'
// import FlowDefine from '@/components/sys/flow/define/Index'
// import FlowMine from '@/components/sys/flow/instance/Mine'
// import FlowManage from '@/components/sys/flow/instance/Index'
// import FlowHistory from '@/components/sys/flow/history/Index'
import LogPage from '@/components/sys/logSystem/operate'
// import Migrate from '@/components/sys/migrate/Index'
export default {
  name: 'FactorySystemIndex',
  components: {
  },
  data(){
    return {
      isCollapsed: false,
      currentName: 'custs', //role,user
      menus: [
        {code: 'custs', name: '组织管理', com: CustList, ops: ['scv1', 'sca', 'sce1', 'scd', 'sce2'], args: {}, level: 0},
        // {code: 'source', name: '数据源管理', com: SourceList, ops: ['sdsv', 'sdsa', 'sdse', 'sdsd'], args: {}, level: 10},
        // {code: 'dict', name: '数据字典管理', com: DictList, ops: ['sdiv', 'sdia', 'sdie', 'sdid', 'sdda', 'sdde', 'sddd'], args: {}, level: 10},
        // {code: 'form', name: '数据表单管理', com: FormList, ops: ['sfiv', 'sfia', 'sfie', 'sfid', 'sffa', 'sffe', 'sffd'], args: {}, level: 10},
        // {code: 'define', name: '流程定义', com: FlowDefine, ops: ['sfov', 'sfoa', 'sfoe', 'sfod', 'sfos'], args: {}, level: 10},
        // {code: 'instanceLog', name: '我的流程', com: FlowMine, ops: ['sftv', 'sfte', 'sftc', 'sftd'], args: {}, level: 10},
        // {code: 'instanceManage', name: '流程管理', com: FlowManage, ops: ['sftv', 'sfte', 'sftc', 'sftd'], args: {}, level: 10},
        // {code: 'instanceHistory', name: '流程查询', com: FlowHistory, ops: ['sftv', 'sfte', 'sftc', 'sftd'], args: {}, level: 10},
        {code: 'setting', name: '系统配置', com: SysSetting, ops: ['ssv', 'sse'], args: {}, level: 0},
        {code: 'open', name: '数据开放管理', com: OpenList, ops: ['sosv', 'sose', 'sosd'], args: {}, level: 0},
        {code: 'log', name: '日志管理', com: LogPage, ops: ['slav','slad','slpv','slpd','slev','sled'], args: {}, level: 10},
        // {code: 'lang', name: '多语言管理', com: LangList, ops: ['slgv','slga','slge'], args: {}, level: 10},
        // {code: 'migrate', name: '数据迁移', com: Migrate, ops: ['ssvxxx', 'ssexxx'], args: {}, level: 0},
      ],
      count: 0,
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes']),
  },
  // watch: {
  //   $route: {
  //     handler: function(route) {
  //       this.currentName = route.path.replace(`/index/pole/system/`, '');
  //     },
  //     immediate: true
  //   },
  // },
  mounted: function(){
  },
  methods: {
    clickHide: function(){
      this.count++;
    },
    menuSelected: function(name){
      if(this.currentName == name) return;
      this.currentName = name;
      // this.$router.push(`/index/pole/system/${name}`);
    },
  }
}
</script>
<style scoped>
.auth-empty{
  /* border: solid 1px red; */
  width: 30px;
  height: 30px;
  flex: auto;
}
.auth-hide{
  /* border: solid 1px red; */
  width: 30px;
  height: 30px;
  flex: none;
}
.auth-container{
  /* border: solid 1px red; */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
}
.auth-header{
  border-bottom: solid 1px rgba(156, 153, 153, 0.322);
  height: 30px;
  flex: none;
  display: flex;
  align-items: stretch;
}
.auth-menu{
  padding: 3px 8px;
  margin: 3px 5px 0 5px;
  border: solid 1px lightgray;
  border-bottom: solid 0 red;
  border-radius: 5px 5px 0 0;
  cursor: pointer;
  background-color: white;
  white-space: nowrap;
}
.auth-content{
  height: 400px;
  flex: auto;
  padding: 3px;
}
.menu-selected{
  border: solid 1px rgb(135, 159, 224);
  background-color: rgb(135, 159, 224);
  color: white;
}
.system-menu{
  /* border: solid 1px red; */
  width: 300px;
  margin: 3px;
  flex: none;
}
.system-content{
  /* border: solid 1px red; */
  margin: 3px;
  width: 0;
  flex: auto;
}
</style>
