<template>
  <div class="auth-container">
    <div class="auth-header">
      <template v-for="item in menus">
        <AuthMenuDiv v-if="item.appTypes.indexOf(appType) != -1" :key="item.code" :class="['auth-menu', currentName == item.code ? 'menu-selected' : '']" :opCodes="item.ops" :title="item.name" @click.native="menuSelected(item.code)"></AuthMenuDiv>
      </template>
    </div>
    <div class="auth-content">
      <template v-for="(item, idx) in menus">
        <component v-if="item.code == currentName" :key="idx" v-bind:is="item.com" :args="item.args"></component>
      </template>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import Energy from './Energy'
// import TransSetting from './TransSetting'
import Home from './Home'
import LightColumn from './LightColumn'
import DevicePage from './DevicePage'
import LightCommand from './LightCommand'
import PowerBoxCommand from './PowerBoxCommand'
import CommandAuth from './CommandAuth'
import OutputCommand from './OutputCommand'
import CurrentCommand from './CurrentCommand'
import MonitorCommand from './MonitorCommand'
export default {
  name: 'FactorySystemIndex',
  components: {
  },
  data() {
    return {
      isCollapsed: false,
      currentName: 'Energy', 
      menus: [
        { code: 'Energy', name: '能耗配置', com: Energy, ops: ['ssv', 'sse'], appTypes: ['road', 'factory', 'light', 'pole'], args: {}, level: 0 },
        // { code: 'Trans', name: '通信配置', com: TransSetting, ops: ['tsv', 'tse'], appTypes: ['road', 'factory', 'light', 'pole'], args: {}, level: 0 },
        { code: 'Home', name: '首页配置', com: Home, ops: ['sshv','sshe'], appTypes: ['light'], args: {}, level: 0 },
        { code: 'CommandAuth', name: '指令授权配置', com: CommandAuth, ops: ['slcav', 'slcae'], appTypes: ['road'], args: {}, level: 0 },
        { code: 'DevicePage', name: '设备状态页配置', com: DevicePage, ops: ['sdpv','sdpe'], appTypes: ['light'], args: {}, level: 0 },
        { code: 'LightColumn', name: '单灯表格配置', com: LightColumn, ops: ['slcv', 'slce'], appTypes: ['road'], args: {}, level: 0 },
        { code: 'PowerBoxCommand', name: '监控器指令配置', com: PowerBoxCommand, ops: ['spcv', 'spce'], appTypes: ['road'], args: {}, level: 0 },
        { code: 'OutputCommand', name: '控制通道指令配置', com: OutputCommand, ops: ['socv', 'soce'], appTypes: ['road'], args: {}, level: 0 },
        { code: 'CurrentCommand', name: '电流通道指令配置', com: CurrentCommand, ops: ['sccv2', 'scce2'], appTypes: ['road'], args: {}, level: 0 },
        { code: 'MonitorCommand', name: '集中器指令配置', com: MonitorCommand, ops: ['smcv', 'smce'], appTypes: ['road'], args: {}, level: 0 },
        { code: 'LightCommand', name: '单灯指令配置', com: LightCommand, ops: ['slcmv', 'slcme'], appTypes: ['road'], args: {}, level: 0 },
      ],
      count: 0,
    }
  },
  computed: {
    ...mapState('auth', ['appType']),
  },
  mounted: function () {
    // console.log('app type', this.appType)
  },
  methods: {
    menuSelected: function (name) {
      if (this.currentName == name) return;
      this.currentName = name;
      // this.$router.push(`/index/pole/system/${name}`);
    },
  }
}
</script>
<style scoped>
.auth-empty {
  /* border: solid 1px red; */
  width: 30px;
  height: 30px;
  flex: auto;
}
.auth-hide {
  /* border: solid 1px red; */
  width: 30px;
  height: 30px;
  flex: none;
}
.auth-container {
  /* border: solid 1px red; */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
}
.auth-header {
  border-bottom: solid 1px rgba(156, 153, 153, 0.322);
  height: 30px;
  flex: none;
  display: flex;
  align-items: stretch;
}
.auth-menu {
  padding: 3px 8px;
  margin: 3px 5px 0 5px;
  border: solid 1px lightgray;
  border-bottom: solid 0 red;
  border-radius: 5px 5px 0 0;
  cursor: pointer;
  background-color: white;
  white-space: nowrap;
}
.auth-content {
  height: 400px;
  flex: auto;
  padding: 3px;
}
.menu-selected {
  border: solid 1px rgb(135, 159, 224);
  background-color: rgb(135, 159, 224);
  color: white;
}
.system-menu {
  /* border: solid 1px red; */
  width: 300px;
  margin: 3px;
  flex: none;
}
.system-content {
  /* border: solid 1px red; */
  margin: 3px;
  width: 0;
  flex: auto;
}
</style>
