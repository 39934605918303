<template>
<Modal v-model="showModal" :mask-closable="false" width="600px">
  <div slot="header">{{title}}</div>
  <Form ref="form" :model="form" :rules="rules" :label-width="120">
    <!-- <FormItem prop="isTop" label="是否顶级设备">
      <i-switch ref="isTop" v-model="form.isTop" />
    </FormItem> -->
    <FormItem prop="stationId" label="所属站点">
      <Select ref="stationId" v-model="form.stationId" number>
        <template v-for="(item, idx) in stations">
          <Option :value="item.id" :key="idx">{{item.name}}</Option>
        </template>
      </Select>
    </FormItem>
    <FormItem prop="parentId" label="上级设备">
      <Select ref="parentId" v-model="form.parentId" number>
        <Option value="0">无</Option>
        <template v-for="(item, idx) in devices">
          <Option :value="item.id" :key="idx">{{types[item.type]}}：{{item.name}}[ID:{{item.code}}]</Option>
        </template>
      </Select>
    </FormItem>
    <FormItem prop="type" label="设备类型">
      <Select ref="type" v-model="form.type">
        <OptionGroup v-for="(group, gkey) in types.groups" :key="gkey" :label="group.name">
          <Option v-for="(item, key) in group.list" :value="item" :key="key">{{ types[item] }}</Option>
        </OptionGroup>
        <!-- <Option v-for="(item, key) in types" :value="key" :key="key">{{item}}</Option> -->
      </Select>
    </FormItem>
    <FormItem prop="name" label="设备名称">
      <Input ref="name" type="text" v-model="form.name"></Input>
    </FormItem>
    <FormItem prop="code" label="通信ID">
      <Input ref="code" type="text" maxlength="20" v-model="form.code" placeholder="请输入设备通信ID"></Input>
    </FormItem>
    <FormItem prop="channel" label="通道参数">
      <Input ref="channel" type="text" maxlength="20" v-model="form.channel" placeholder="上级通道透传该设备的通道参数"></Input>
    </FormItem>
    <FormItem prop="simCard" label="SIM卡号">
      <Input ref="simCard" type="text" v-model="form.simCard"></Input>
    </FormItem>
    <!-- <FormItem prop="interval" label="数据巡检时间">
      <Select v-model="form.interval">
        <Option :value="0">不巡检</Option>
        <Option :value="1">每隔1分钟正点</Option>
        <Option :value="2">每隔2分钟正点</Option>
        <Option :value="3">每隔3分钟正点</Option>
        <Option :value="5">每隔5分钟正点</Option>
        <Option :value="10">每隔10分钟正点</Option>
        <Option :value="15">每隔15分钟正点</Option>
        <Option :value="20">每隔20分钟正点</Option>
        <Option :value="30">每隔30分钟正点</Option>
        <Option :value="60">每隔60分钟正点</Option>
      </Select>
    </FormItem> -->
    <FormItem prop="timeout" label="超时时间">
      <Input ref="timeout" type="number" v-model="form.timeout"><span slot="append">分钟</span></Input>
    </FormItem>
  </Form>
  <div slot="footer">
    <Button style="margin-right: 8px" @click="cancel">取消</Button>
    <Button type="primary" :loading="loading" @click="ok">保存</Button>
  </div>
</Modal>
</template>
<script>
import {mapState} from 'vuex'
export default {
  name: 'ModalEditDevice',
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default () { return {}; }
    },
    types: {
      type: Object,
      default () { return {}; }
    },
    devices: {
      type: Array,
      default () { return []; }
    },
    stations: {
      type: Array,
      default () { return []; }
    },
  },
  data () {
    return {
      title: '编辑设备信息',
      loading: false,
      showModal: this.value,
      isAdd: false,
      form: {
        id: '',
        stationId: 0,
        name: '',
        code: '',
        type: '',
        parentId: 0,
        channel: '',
        interval: 15,
        simCard: '',
        timeout: 5,
      },
      rules: {
        name: { required: true, type: 'string', max: 30, message: '设备名称不能为空且长度不能超过30个字符', trigger: 'blur' },
        type: { required: true, type: 'string', message: '必须选择一个设备类型', trigger: 'on-change' },
        stationId: { required: true, type: 'number', message: '必须选择一个所属站点', trigger: 'on-change' },
      }
    }
  },
  computed: {
    ...mapState('auth', ['roles', 'customers', 'appScenes']),
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if(newVal){
        // console.log('edit device', this.item)
        this.isAdd = this.item.isAdd;
        if(this.isAdd){
          this.form.id = 0;
          this.form.stationId = this.item.data.stationId;
          this.form.code = "";
          this.form.type = "";
          this.form.name = '';
          this.form.channel = '';
          this.form.parentId = this.item.data.parentId;
          this.form.interval = 0;
          this.form.timeout = 5;
          this.form.simCard = '';
        }else{
          this.form.id = this.item.data.id;
          this.form.stationId = this.item.data.stationId;
          this.form.code = this.item.data.code;
          this.form.type = this.item.data.type;
          this.form.name = this.item.data.name;
          this.form.channel = this.item.data.channel;
          this.form.parentId = this.item.data.parentId;
          this.form.interval = this.item.data.interval;
          this.form.timeout = this.item.data.timeout;
          this.form.simCard = this.item.data.simCard;
        }
      }
    },
    showModal(val){
      this.$emit('showChanged', val);
    }
  },
  mounted: function(){
    
  },
  methods: {
    ok: async function () {
      let result = await this.$refs.form.validate();
      if(!result){
        return;
      }
      this.form.parentId = parseInt(this.form.parentId);
      // console.log('save form', this.form)
      if(this.form.parentId == 0 && this.form.code == ''){
        this.$Message.warning('顶级设备请输入通信ID')
        return;
      }
      this.loading = true;
      this.$axios.post(`//${this.domains.trans}/station/lux/SaveDevice`, this.form).then(res => {
      this.loading = false;
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          this.$Message.info('保存成功')
          setTimeout(() => {
            this.showModal = false;
            this.$emit('saved');
          }, 800);
        }
      });
    },
    cancel () {
      this.showModal = false;
      this.$refs.form.resetFields();
    },
  }
}
</script>