<template>
  <div class="alarmTime-box">
    <div class="alarmTime-horn-left"></div>
    <div class="alarmTime-horn-right"></div>
    <div class="alarmTime-con">
      <div class="alarmTime-horn-spot spot-dark spot-top-left"></div>
      <div class="alarmTime-horn-spot spot-dark spot-top-right"></div>
      <div class="alarmTime-horn-spot spot-lower-left"></div>
      <div class="alarmTime-horn-spot spot-lower-right"></div>
      <div class="alarmTime-top">
        <div>
          <img :src="img.light.carat.home.TimeAlerts" alt="" class="alarmTime-top-icon">
          <div class="alarmTime-title">实时报警</div>
        </div>
        <img :src="img.light.carat.home.arrow" alt="">
      </div>
      <div class="alarmTime-tab">
        <table border="0" cellspacing="0">
          <tr>
            <th>设备</th>
            <th>状态</th>
            <th>时间</th>
          </tr>
          <tr v-for="el,index in alarms" :key="index">
            <td class="tab-name" :title="el.stationName"><span>{{el.stationName}}</span></td>
            <td class=" tab-state" :title="el.name"><span class="red">{{el.name}}</span></td>
            <td class="tab-time">{{el.createTime}}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'alarmTimeBox',
  components: {
  },
  data() {
    return {
      timeout: null,
      alarms: [],
    }
  },
  computed: {
    ...mapState('auth', ['config']),
    ...mapState('group', ['selectedNode', 'groupLoaded', 'selectedDevices']),
  },
  created: function () {
  },
  mounted: function () {
    this.debounceInitData();
  },
  methods: {
    debounceInitData: function () {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(this.initData, 500);
    },
    initData: function () {
      this.$axios.post(`//${this.domains.trans}/station/alarm/QueryFloatAlarm`, { groupId: 0, stationId: 0 }).then(res => {
        if (res.code != 0) {
          return;
        } else {
          this.$set(this, 'alarms', res.data);
          // console.log(res.data)
        }
      });
    },
  },
}
</script>
<style scoped>
.alarmTime-box {
  width: 420px;
  height: 100%;
  background: rgba(3, 16, 33, 0.9);
  border: 1px solid #2f3c48;
  position: relative;
  padding: 7px 4px;
}
.alarmTime-horn-left {
  width: 8px;
  height: 8px;
  border-top: 2px solid #49e1fc;
  border-left: 2px solid #49e1fc;
  position: absolute;
  top: -2px;
  left: -2px;
}
.alarmTime-horn-right {
  width: 8px;
  height: 8px;
  border-top: 2px solid #49e1fc;
  border-right: 2px solid #49e1fc;
  position: absolute;
  top: -2px;
  right: -2px;
}
.alarmTime-horn-spot {
  width: 3px;
  height: 3px;
  background: #777b82;
  position: absolute;
}
.spot-dark {
  background: #49e1fc;
}
.spot-top-left {
  top: 6px;
  left: 4px;
}
.spot-top-right {
  top: 6px;
  right: 4px;
}
.spot-lower-left {
  bottom: 4px;
  left: 4px;
}
.spot-lower-right {
  bottom: 4px;
  right: 4px;
}
.alarmTime-top {
  display: flex;
  flex-direction: initial;
  width: 100%;
  height: 35px;
  background: rgba(36, 48, 66, 0.8);
  border: 1px solid #445565;
  line-height: 35px;
  padding: 0 16px;
  justify-content: space-between;
}
.alarmTime-top > div {
  display: flex;
  flex-direction: initial;
}
.alarmTime-top img {
  width: 23px;
  height: 16px;
  margin-top: 10px;
}
.alarmTime-top .alarmTime-top-icon {
  width: 18px;
  height: 20px;
  margin-top: 5px;
}
.alarmTime-top .alarmTime-title {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #baf1fe;
  margin-left: 11px;
}
.alarmTime-tab table {
  width: 90%;
  border: none;
  margin: 15px auto 0;
}
.alarmTime-tab tr th {
  width: 33px;
  height: 32px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #49e1fc;
  line-height: 32px;
}
.alarmTime-tab tr:nth-child(even) {
  background: #1d2a3c;
}
.alarmTime-tab tr td span {
  width: 85px;
  height: 32px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #99aac0;
  line-height: 32px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}
.alarmTime-tab tr td span.red {
  color: #e92e5e;
}
.alarmTime-tab tr td.yellow {
  color: #f9dd43;
}
.tab-name {
  width: 60px;
  padding: 0 10px;
}
.tab-state {
  width: 60px;
  padding: 0 10px;
}
.alarmTime-tab {
  height: 400px;
  overflow-y: overlay;
}
.tab-time {
  width: 255px;
  padding: 0 10px;
  height: 32px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #99aac0;
  line-height: 32px;
}
</style>