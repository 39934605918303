<template>
  <Modal v-model="showModal" :mask-closable="false" width="1000px">
    <div slot="header">{{ name }}</div>
    <Form ref="form" :label-width="120">
      <FormItem label="光控模式" v-if="['setLuxCtrlMode'].indexOf(code) != -1" class="form-item">
        <RadioGroup v-model="form.luxmode">
          <Radio :label="0">禁用</Radio>
          <Radio :label="1">挡位控制</Radio>
          <Radio :label="2">恒照度控制</Radio>
        </RadioGroup>
      </FormItem>
      <FormItem label="是否启用" v-if="['setLuxAutoMode'].indexOf(code) != -1" class="form-item">
        <i-switch v-model="form.luxEnable" size="large" true-color="#13ce66" false-color="#ff4949">
          <span slot="open">启用</span>
          <span slot="close">禁用</span>
        </i-switch>
      </FormItem>
      <FormItem label="光控分组号" v-if="['setLuxAutoMode'].indexOf(code) != -1" class="form-item">
        <Input v-model="form.luxGroupNo" type="number" min="0" max="255" style="width: 120px;height:32px;"></Input>
      </FormItem>
      <FormItem label="目标光照度" v-if="['setLuxAutoMode'].indexOf(code) != -1" class="form-item">
        <Input v-model="form.luxTarget" type="number" min="0" max="65535" style="width: 120px;height:32px;"></Input>
      </FormItem>
      <FormItem label="误差范围" v-if="['setLuxAutoMode'].indexOf(code) != -1" class="form-item">
        <Input v-model="form.luxError" type="number" min="0" max="3000" style="width: 120px;height:32px;"></Input>
      </FormItem>
      <FormItem label="光控挡位" v-if="['setLuxLevelMode'].indexOf(code) != -1" class="form-item">
        <div v-for="(group, gidx) in form.luxGroups" :key="gidx" style="display: flex;align-items:center;" class="bgray">
          <div style="width: 180px; flex: none; display: flex;align-items:center;"> 光控分组号：<Input v-model="group.no" type="number" min="0" max="255" style="width: 80px;height:32px;"></Input> </div>
          <div style="width: 150px; flex: none; display: flex;align-items:center;"> 是否启用：<i-switch v-model="group.enable" size="large" true-color="#13ce66" false-color="#ff4949"><span slot="open">启用</span><span slot="close">禁用</span></i-switch>
          </div>
          <div style="width: 200px; flex: auto; display: flex; flex-direction: column">
            <div v-for="(level, lidx) in group.levels" :key="lidx" style="display: flex;" class="bgray">
              <div style="width: 150px; flex: none; display: flex;align-items:center;"> 光控值：<Input v-model="level.lux" type="number" min="0" max="65535" style="width: 80px;height:32px;"></Input> </div>
              <div style="width: 150px; flex: none; display: flex;align-items:center;"> 亮度值：<Input v-model="level.bright" type="number" min="0" max="100" style="width: 80px;height:32px;"></Input> </div>
              <div style="width: 150px; flex: none; display: flex;align-items:center;"> 色温值：<Input v-model="level.color" type="number" min="0" max="100" style="width: 80px;height:32px;"></Input> </div>
            </div>
          </div>
        </div>
      </FormItem>
      <FormItem label="时间表" v-if="['setDayPlan'].indexOf(code) != -1" class="form-item">
        <Select v-model="form.timeId">
          <Option v-for="item in singleLightTables" :key="item.id" :value="item.id">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <FormItem label="分组" v-if="['setGroup', 'adjustGroup'].indexOf(code) != -1" class="form-item">
        <RadioGroup v-model="form.group">
          <Radio v-for="n in 5" :key="n" :label="n">{{ n }}</Radio>
        </RadioGroup>
      </FormItem>
      <FormItem label="通道" v-if="['setLoraWork'].indexOf(code) != -1">
        <Input v-model="form.channel" type="number" style="width: 120px;height:32px;"></Input>
      </FormItem>
      <FormItem label="1号输出" v-if="['adjustColor', 'adjustMuti', 'adjustGroup'].indexOf(code) != -1" class="form-item">
        <div style="display: flex">
          <Input v-model="form.output1" type="number" number style="width: 120px; height: 32px">
          <!-- <span slot="prepend">http://</span> -->
          <span slot="append">%</span>
          </Input>
          <Slider v-model="form.output1" :min="0" :max="100" style="flex: auto; margin: 0 15px"></Slider>
        </div>
      </FormItem>
      <FormItem label="2号输出" v-if="['adjustColor', 'adjustMuti', 'adjustGroup'].indexOf(code) != -1" class="form-item">
        <div style="display: flex">
          <Input v-model="form.output2" type="number" number style="width: 120px; height: 32px">
          <!-- <span slot="prepend">http://</span> -->
          <span slot="append">%</span>
          </Input>
          <Slider v-model="form.output2" :min="0" :max="100" style="flex: auto; margin: 0 15px"></Slider>
        </div>
      </FormItem>
      <FormItem label="色温1" v-if="['adjustGroup'].indexOf(code) != -1" class="form-item">
        <div style="display: flex">
          <Input v-model="form.color1" type="number" number style="width: 120px; height: 32px">
          <!-- <span slot="prepend">http://</span> -->
          <span slot="append">%</span>
          </Input>
          <Slider v-model="form.color1" :min="0" :max="100" style="flex: auto; margin: 0 15px"></Slider>
        </div>
      </FormItem>
      <FormItem label="色温2" v-if="['adjustGroup'].indexOf(code) != -1" class="form-item">
        <div style="display: flex">
          <Input v-model="form.color2" type="number" number style="width: 120px; height: 32px">
          <!-- <span slot="prepend">http://</span> -->
          <span slot="append">%</span>
          </Input>
          <Slider v-model="form.color2" :min="0" :max="100" style="flex: auto; margin: 0 15px"></Slider>
        </div>
      </FormItem>
      <FormItem class="form-item" label="开关" v-if="['handControlByChannel'].indexOf(code) != -1">
        <div class="switchsBut">
          <template v-if="loopList.length>-1">
            <div v-for="n in 4" :key="n" :class="[loopList[n-1]!=0?'loopList':'']" @click="switchsBut(n, loopList[n - 1])">
              <div :class="[loopList[n-1]!=0?'loopText':'']" v-if='loopList[n-1]!=0'>选中</div>
              <p>K{{ n }}</p>
              <div v-if="monitor.extraData  && monitor.extraData.list && monitor.extraData.list[n - 1]">
                <img :src="img.light.factory.monitor.switch.open" />
              </div>
              <div v-else>
                <img :src="img.light.factory.monitor.switch.close" />
              </div>
              <p>{{ monitor.extraData && monitor.extraData.list && monitor.extraData.list[n - 1] ? "开启" : "关闭" }}</p>
            </div>
          </template>

        </div>
        <!-- 885522 -->
      </FormItem>
      <FormItem class="form-item" label="延时时间" v-if="[''].indexOf(code) != -1">
        <div style="display: flex">
          <Input v-model="form.delay" type="number" number style="width: 120px; height: 32px">
          <span slot="append">分钟</span>
          </Input>
          <Slider v-model="form.delay" :min="1" :max="600" style="flex: auto; margin: 0 15px"></Slider>
          <Input :value="stopTime" readonly style="width: 160px; height: 32px">
          <span slot="prepend">至</span>
          </Input>
        </div>
      </FormItem>
      <FormItem label="联动编号" v-if="['setReactionArgs'].indexOf(code) != -1" class="form-item">
        <div style="display: flex">
          <Input v-model="form.rfsn" type="number" number style="width: 120px; height: 32px">
          <!-- <span slot="prepend">http://</span> -->
          <!-- <span slot="append">秒</span> -->
          </Input>
          <Slider v-model="form.rfsn" :min="0" :max="100" style="flex: auto; margin: 0 15px"></Slider>
        </div>
      </FormItem>
      <FormItem label="启动感应控制" v-if="['setReactionArgs'].indexOf(code) != -1" class="form-item">
        <i-switch v-model="form.sensor" size="large" true-color="#13ce66" false-color="#ff4949">
          <span slot="open">开启</span>
          <span slot="close">关闭</span>
        </i-switch>
      </FormItem>
      <FormItem label="通道1延时" v-if="['setReactionArgs'].indexOf(code) != -1" class="form-item">
        <div style="display: flex">
          <Input v-model="form.keep1" type="number" number style="width: 120px; height: 32px">
          <!-- <span slot="prepend">http://</span> -->
          <span slot="append">秒</span>
          </Input>
          <Slider v-model="form.keep1" :min="0" :max="100" style="flex: auto; margin: 0 15px"></Slider>
        </div>
      </FormItem>
      <FormItem label="通道2延时" v-if="['setReactionArgs'].indexOf(code) != -1" class="form-item">
        <div style="display: flex">
          <Input v-model="form.keep2" type="number" number style="width: 120px; height: 32px">
          <!-- <span slot="prepend">http://</span> -->
          <span slot="append">秒</span>
          </Input>
          <Slider v-model="form.keep2" :min="0" :max="100" style="flex: auto; margin: 0 15px"></Slider>
        </div>
      </FormItem>
      <FormItem label="是否发送信号" v-if="['setReactionArgs'].indexOf(code) != -1" class="form-item">
        <i-switch v-model="form.txen" size="large" true-color="#13ce66" false-color="#ff4949">
          <span slot="open">开启</span>
          <span slot="close">关闭</span>
        </i-switch>
      </FormItem>
      <FormItem :label="code == 'setReactionArgs' ? '433频道' : '信道'" v-if="['setReactionArgs', 'setLoraChannel'].indexOf(code) != -1" class="form-item">
        <div style="display: flex">
          <Input v-model="form.channel" type="number" number style="width: 120px; height: 32px">
          <!-- <span slot="prepend">http://</span> -->
          <!-- <span slot="append">秒</span> -->
          </Input>
          <Slider v-model="form.channel" :min="0" :max="255" style="flex: auto; margin: 0 15px"></Slider>
        </div>
      </FormItem>
      <FormItem label="433功率" v-if="['setReactionArgs'].indexOf(code) != -1" class="form-item">
        <div style="display: flex">
          <Input v-model="form.txpower" type="number" number style="width: 120px; height: 32px">
          <!-- <span slot="prepend">http://</span> -->
          <!-- <span slot="append">秒</span> -->
          </Input>
          <Slider v-model="form.txpower" :min="0" :max="255" style="flex: auto; margin: 0 15px"></Slider>
        </div>
      </FormItem>
      <FormItem label="发送周期" v-if="['setReactionArgs'].indexOf(code) != -1" class="form-item">
        <div style="display: flex">
          <Input v-model="form.period" type="number" number style="width: 120px; height: 32px">
          <!-- <span slot="prepend">http://</span> -->
          <span slot="append">毫秒</span>
          </Input>
          <Slider v-model="form.period" :min="0" :max="10000" style="flex: auto; margin: 0 15px"></Slider>
        </div>
      </FormItem>
      <FormItem label="重发次数" v-if="['setReactionArgs'].indexOf(code) != -1" class="form-item">
        <div style="display: flex">
          <Input v-model="form.repeat" type="number" number style="width: 120px; height: 32px">
          <!-- <span slot="prepend">http://</span> -->
          <!-- <span slot="append">秒</span> -->
          </Input>
          <Slider v-model="form.repeat" :min="0" :max="10" style="flex: auto; margin: 0 15px"></Slider>
        </div>
      </FormItem>
      <FormItem label="是否接收信号" v-if="['setReactionArgs'].indexOf(code) != -1" class="form-item">
        <i-switch v-model="form.rxen" size="large" true-color="#13ce66" false-color="#ff4949">
          <span slot="open">开启</span>
          <span slot="close">关闭</span>
        </i-switch>
      </FormItem>
      <FormItem label="联动方式" v-if="['setReactionArgs'].indexOf(code) != -1" class="form-item">
        <Select v-model="form.mode">
          <Option :value="1">按范围</Option>
          <Option :value="2">全局</Option>
        </Select>
      </FormItem>
      <FormItem label="响应范围" v-if="['setReactionArgs'].indexOf(code) != -1" class="form-item">
        <div style="display: flex">
          <Input v-model="form.range" type="number" number style="width: 120px; height: 32px">
          <!-- <span slot="prepend">http://</span> -->
          <!-- <span slot="append">秒</span> -->
          </Input>
          <Slider v-model="form.range" :min="0" :max="255" style="flex: auto; margin: 0 15px"></Slider>
        </div>
      </FormItem>
      <FormItem label="每包大小" v-if="['update'].indexOf(code) != -1" class="form-item">
        <div style="display: flex">
          <Input v-model="form.packLen" type="number" number style="width: 120px; height: 32px">
          <!-- <span slot="prepend">http://</span> -->
          <span slot="append">字节</span>
          </Input>
        </div>
      </FormItem>
      <FormItem label="选择升级文件" v-if="['update'].indexOf(code) != -1" class="form-item">
        <div style="display: flex">
          <div class="logo-upload">
            <input ref="fileInput" type="file" accept=".bin" :disabled="loading" />
          </div>
        </div>
      </FormItem>
      <FormItem v-if="commands.length > 0" :label-width="0" class="form-item">
        <table class="muti-table" :key="cmdResultRefresh">
          <tr>
            <td style="text-align: center; width: 50px">序号</td>
            <td style="text-align: center; width: 200px">名称</td>
            <!-- <td style="text-align: center; width: 100px">编码</td> -->
            <td style="text-align: center">指令执行结果</td>
          </tr>
          <tr v-for="(cmd, idx) in commands" :key="idx">
            <td>{{ idx + 1 }}</td>
            <td>{{ cmd.devName }}</td>
            <!-- <td>{{ cmd.monitorCode ? cmd.monitorCode : cmd.topCode }}</td> -->
            <td>
              <div v-if="cmd.status == 0">{{cmd.result}}</div>
              <div v-else-if="cmd.status == 1">等待回复</div>
              <div v-else-if="cmd.status == 2">指令超时</div>
              <div v-else-if="cmd.status == 3">执行失败：{{cmd.result}}</div>
              <div v-else-if="cmd.status == 4">执行中：{{cmd.content.pkg}} / {{cmd.content.total}}</div>
              <div v-else-if="cmd.status == 5">重试：{{cmd.result}}</div>
              <div v-else-if="cmd.status == 9">
                <div v-if="cmd.code == 'getLuxCtrlMode'" style="display: flex; flex-direction: column">
                  当前光控模式：{{luxTypes[cmd.content.flag]}}
                </div>
                <div v-else-if="cmd.code == 'getLuxValue'" style="display: flex; flex-direction: column">
                  当前光控值：{{cmd.content.val}}
                </div>
                <div v-else-if="cmd.code == 'getCycle'" style="display: flex; flex-direction: column">
                  <div v-for="n in 4" :key="n">
                    回路{{ n }}: {{ cmd.content.list[n - 1] ? "连通" : "断开" }}
                  </div>
                </div>
                <div v-else-if="cmd.code == 'getLuxAutoMode'" style="display: flex; flex-direction: column">
                  <div> 是否启用：{{ cmd.content.enable ? '启用' : '禁用' }} </div>
                  <div> 光控分组号：{{ cmd.content.no }} </div>
                  <div> 光控目标值：{{ cmd.content.lux }} </div>
                  <div> 光控误差值：{{ cmd.content.error }} </div>
                </div>
                <div v-else-if="cmd.code == 'getLuxLevelMode'" style="display: flex; flex-direction: column">
                  <div v-for="(group, gidx) in cmd.content.groups" :key="gidx" style="display: flex;align-items:center;" class="bgray">
                    <div style="width: 150px; flex: none;"> 光控分组号：{{ group.no }} </div>
                    <div style="width: 150px; flex: none;"> 是否启用：{{ group.enable ? '启用' : '禁用' }} </div>
                    <div style="width: 200px; flex: auto; display: flex; flex-direction: column">
                      <div v-for="(level, lidx) in group.levels" :key="lidx" style="display: flex;" class="bgray">
                        <div style="width: 150px; flex: none;"> 光控值：{{ level.lux }} </div>
                        <div style="width: 150px; flex: none;"> 亮度值：{{ level.bright }} </div>
                        <div style="width: 150px; flex: none;"> 色温值：{{ level.color }} </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else-if="cmd.code == 'getWhite'" style="display: flex; flex-wrap: wrap">
                  <div>单灯ID列表：</div>
                  <div v-for="(item, idx) in cmd.content.list" :key="idx" style="margin-right: 5px">{{ item }},</div>
                </div>
                <div v-else-if="cmd.code == 'getLightName'" style="display: flex; flex-wrap: wrap">
                  <table>
                    <tr>
                      <td style="text-align: center">单灯ID</td>
                      <td style="text-align: center">单灯名称</td>
                      <td style="text-align: center">分组号</td>
                      <td style="text-align: center">有功功率</td>
                      <td style="text-align: center">亮度</td>
                    </tr>
                    <tr v-for="(item, idx) in cmd.content.list" :key="idx">
                      <td>{{ item.id }}</td>
                      <td>{{ item.name }}</td>
                      <td>{{ item.group }}</td>
                      <td>{{ item.power }}</td>
                      <td>{{ item.bright }}</td>
                    </tr>
                  </table>
                </div>
                <div v-else-if="cmd.code == 'getLed'" style="display: flex; flex-wrap: wrap">
                  <table>
                    <tr>
                      <td colspan="2" style="width: 200px; text-align: center">
                        区域列表
                      </td>
                      <td colspan="3" style="width: 300px; text-align: center">
                        模式列表
                      </td>
                    </tr>
                    <tr>
                      <td style="text-align: center">名称</td>
                      <td style="text-align: center">分组号</td>
                      <td style="text-align: center">名称</td>
                      <td style="text-align: center">亮度</td>
                      <td style="text-align: center">延时</td>
                    </tr>
                    <tr v-for="n in 6" :key="n">
                      <td>{{ cmd.content.areas[n - 1].name }}</td>
                      <td>{{ cmd.content.areas[n - 1].group }}</td>
                      <td>{{ cmd.content.adjusts[n - 1].name }}</td>
                      <td>{{ cmd.content.adjusts[n - 1].bright }}</td>
                      <td>{{ cmd.content.adjusts[n - 1].expire }}</td>
                    </tr>
                  </table>
                </div>
                <div v-else-if="cmd.code == 'getInfo'">
                  硬件版本：{{ cmd.content.hardware }}； 软件版本：{{cmd.content.software}}； 出厂日期：{{ cmd.content.manufacture }}；
                </div>
                <div v-else-if="cmd.code == 'getclock'">
                  当前时间：{{ cmd.content.nowTime }}；
                  {{ weeks[cmd.content.nowWeek] }}；
                </div>
                <div v-else-if="cmd.code == 'getLoraWork'">
                  当前信道：{{ cmd.content.channel }}；
                  当前速率：{{ cmd.content.speed }}；
                </div>
                <div v-else-if="cmd.code == 'getNetwork'">
                  服务端IP：{{ cmd.content.host }}； 服务端口：{{
                    cmd.content.port
                  }}；心跳间隔时间：{{ cmd.content.heart }}秒；
                </div>
                <div v-else-if="cmd.code == 'getSimInfo'">
                  IMEI：{{ cmd.content.imei }}； ICCID：{{
                    cmd.content.iccid
                  }}；RSSI：{{ cmd.content.rssi }}；
                </div>
                <div v-else-if="cmd.code == 'getWeekPlan'">
                  通道号：{{ cmd.content.channel }}；{{
                    cmd.content.enable ? "启用" : "禁用"
                  }}； 周一：{{ cmd.content.day1 ? "启用" : "禁用" }}； 周二：{{
                    cmd.content.day2 ? "启用" : "禁用"
                  }}； 周三：{{ cmd.content.day3 ? "启用" : "禁用" }}； 周四：{{
                    cmd.content.day4 ? "启用" : "禁用"
                  }}； 周五：{{ cmd.content.day5 ? "启用" : "禁用" }}； 周六：{{
                    cmd.content.day6 ? "启用" : "禁用"
                  }}； 周日：{{ cmd.content.day7 ? "启用" : "禁用" }}；
                </div>
                <div v-else-if="cmd.code == 'getYearPlan'">
                  年表：{{ getYearPlanContent(cmd.content) }}
                </div>
                <div v-else-if="cmd.code == 'getPlanType'">
                  通道号：{{ cmd.content.channel }}； 手动：{{
                    cmd.content.hand ? "启用" : "禁用"
                  }}； 临时表：{{ cmd.content.temp ? "启用" : "禁用" }}； 周表：{{
                    cmd.content.week ? "启用" : "禁用"
                  }}； 年表：{{ cmd.content.year ? "启用" : "禁用" }}；
                </div>
                <div v-else-if="cmd.code == 'getCtrlType'">
                  通道号：{{ cmd.content.channel }}； 状态：{{
                    cmd.content.status ? "启用" : "禁用"
                  }}； 类型：{{ cmd.content.ctrl }}；
                </div>
                <div v-else-if="cmd.code == 'getDigitUnionParams'">
                  分组号：{{ cmd.content.flag }}； 开入量通道：{{
                    cmd.content.cfgs.inch
                  }}； 开入量值：{{ cmd.content.cfgs.inval }}； 开出量通道：{{
                    cmd.content.cfgs.outch
                  }}； 开出量值：{{ cmd.content.cfgs.outval }}； 复位模式：{{
                    cmd.content.cfgs.resetMode
                  }}； 截止时间：{{ cmd.content.cfgs.setTime }}； 持续时间：{{
                    cmd.content.cfgs.keepTime
                  }}；
                </div>
                <div v-else-if="cmd.code == 'getAnalogUnionParams'">
                  分组号：{{ cmd.content.flag }}； 开入量通道：{{
                    cmd.content.cfgs.inch
                  }}； 触发模式：{{ cmd.content.cfgs.inmode }}； 开出量通道：{{
                    cmd.content.cfgs.outch
                  }}； 开出量值：{{ cmd.content.cfgs.outval }}； 复位模式：{{
                    cmd.content.cfgs.resetMode
                  }}； 截止时间：{{ cmd.content.cfgs.setTime }}； 持续时间：{{
                    cmd.content.cfgs.keepTime
                  }}；
                </div>
                <div v-else-if="cmd.code == 'getAnalogAlarmRange'" style="display: flex; flex-direction: column">
                  <div>A相漏电电流，上限值：{{ cmd.content.lamax }}，下限值：{{cmd.content.lamin}}</div>
                  <div>B相漏电电流，上限值：{{ cmd.content.lbmax }}，下限值：{{cmd.content.lbmin}}</div>
                  <div>C相漏电电流，上限值：{{ cmd.content.lcmax }}，下限值：{{cmd.content.lcmin}}</div>
                  <div>A相电压，上限值：{{ cmd.content.vamax }}，下限值：{{cmd.content.vamin}}</div>
                  <div>B相电压，上限值：{{ cmd.content.vbmax }}，下限值：{{cmd.content.vbmin}}</div>
                  <div>C相电压，上限值：{{ cmd.content.vcmax }}，下限值：{{cmd.content.vcmin}}</div>
                  <div v-for="n in 12" :key="n">第{{ n }}路电流，上限值：{{cmd.content[`i${n}max`]}}，下限值：{{ cmd.content[`i${n}min`] }}；</div>
                  <div>温度上限值：{{ cmd.content.tempmax }}</div>
                </div>
                <div v-else-if="cmd.code == 'getLeakageChannel'">A相漏电电流支路：{{ cmd.content.lach }}； B相漏电电流支路：{{cmd.content.lbch}}； C相漏电电流支路：{{ cmd.content.lcch }}；</div>
                <div v-else-if="cmd.code == 'getCurrentLevel' || cmd.code == 'getCurrentError'">
                  <div v-for="n in 8" :key="n">
                    第{{ n }}级值：{{ cmd.content.levels[n - 1] }}；
                  </div>
                </div>
                <div v-else-if="cmd.code == 'setCycle' ">
                  <div v-for="(n,index) in cmd.args.list" :key="n">
                    回路K{{index+1}}：{{ n?"开启":"关闭"}}；
                  </div>
                </div>
                <div v-else>执行成功</div>
              </div>
              <!-- <div v-else-if="cmd.code == 'update'">{{updateProgress(cmd.content)}}</div> -->
            </td>
          </tr>
        </table>
        <!-- 很奇怪，没有下面的一行代码，指令执行结果无法刷新  style="display: none"-->
        <!-- <div>{{ commands }}</div> -->
      </FormItem>
    </Form>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">关闭</Button>
      <template v-if=" this.item.cmd && this.item.cmd.name=='回路控制' && this.item.cmd.code=='handControlByChannel' ">
        <Button type="primary" :loading="loading" @click="ok(true)" style="margin-left: 10px">一键开</Button>
        <Button type="error" :loading="loading" @click="ok(false)" style="margin-left: 10px">一键关</Button>
      </template>
      <template v-else>
        <Button type="primary" :loading="loading" @click="ok" style="margin-left: 10px">发送</Button>
      </template>
    </div>
  </Modal>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "ModelMonitorCmdArgs",
  components: {},
  model: {
    prop: "value",
    event: "showChanged",
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default() {
        return {
          cmd: {},
          devs: [
            {
              id: "指令ID",
              devName: "设备名称，对用户方便识别",
              // productCode: "产品类型编码",
              // productId: "产品ID",
              // topType: "通信设备类型编码",
              // topCode: "通信设备编码",
              // devType: "控制设备类型编码",
              // devCode: "控制设备编码",
              deviceId: 0, //执行设备ID
              code: '指令编码',
              name: '指令名称',
              args: {} //指令参数
            },
          ],
        };
      },
    },
  },
  data() {
    return {
      loading: false,
      showModal: this.value,
      code: "",
      name: "",
      cmd: {},
      devs: [],
      percent: 0, //升级进度
      luxTypes: {
        0: '禁用',
        1: '挡位控制',
        2: '恒照度控制',
      },
      form: {
        packLen: 512, //OTA升级包大小
        delay: 60, //控制延时时间，单位分钟
        output1: 0, //1号功率输出值
        output2: 0, //2号功率输出值
        group: 1, //单灯分组号
        color1: 0, //1号色温
        color2: 0, //2号色温
        timeId: 0, //日表号
        //以下联动控制参数
        rfsn: 0, //联动模块编号
        channel: 0, //433应用频道
        txpower: 0, //433发送功率
        txen: true, //是否发送433信号,对于检测方可以发送，其他的只能RX
        repeat: 2, //重发次数 0~10有效，默认2
        period: 100, //重发周期 1 对于1ms
        range: 10, //响应范围，这个对发送方无意义，对接收方有用
        rxen: true, //是否接收433联动信号  1=允许接收
        mode: 1, //联动方式， 1=按Range ,2:全局，Range无效
        keep1: 1, //灯1延时时间（秒）
        keep2: 1, //灯2延时时间（秒）
        sensor: true, //是否启用感应启用，RX方只管自己
        open: [false, false, false, false], //回路开关状态
        speed: 7, //lora速率
        luxmode: 0, //光控模式，0、禁用，1、挡位控制，2、自动控制
        luxGroupNo: 0, //光控分组
        luxEnable: false, //是否启用
        luxTarget: 100, //光控目标值
        luxError: 100, //光控误差值
        luxGroups: [
          {
            enable: false,
            no: 0,
            levels: [
              { lux: 0, bright: 50, color: 50 },
              { lux: 0, bright: 50, color: 50 },
              { lux: 0, bright: 50, color: 50 },
              { lux: 0, bright: 50, color: 50 },
            ]
          },
          {
            enable: false,
            no: 0,
            levels: [
              { lux: 0, bright: 50, color: 50 },
              { lux: 0, bright: 50, color: 50 },
              { lux: 0, bright: 50, color: 50 },
              { lux: 0, bright: 50, color: 50 },
            ]
          },
          {
            enable: false,
            no: 0,
            levels: [
              { lux: 0, bright: 50, color: 50 },
              { lux: 0, bright: 50, color: 50 },
              { lux: 0, bright: 50, color: 50 },
              { lux: 0, bright: 50, color: 50 },
            ]
          },
          {
            enable: false,
            no: 0,
            levels: [
              { lux: 0, bright: 50, color: 50 },
              { lux: 0, bright: 50, color: 50 },
              { lux: 0, bright: 50, color: 50 },
              { lux: 0, bright: 50, color: 50 },
            ]
          },
        ],
      },
      loopList: [0, 0, 0, 0],
      monitor: {},
    };
  },
  computed: {
    ...mapState("cmd", ["commands", "cmdResultRefresh"]),
    ...mapState("common", ["weeks", "productCodes", "powerBoxTypes"]),
    ...mapState("timeTable", ["powerTableTypes", "powerBoxTables", "powerTableActions"]),
    stopTime: function () {
      let now = new Date();
      now.setMinutes(now.getMinutes() + this.form.delay);
      return now.format("yyyy-MM-dd HH:mm:ss");
    },
  },
  mounted: function () {

  },
  watch: {
    value(newVal) {
      if (this.item.cmd.monitor != undefined) {
        this.monitor = JSON.parse(JSON.stringify(this.item.cmd.monitor));
      }
      this.showModal = newVal;
      if (newVal) {
        let now = new Date();
        this.form.date = now.format("yyyy-MM-dd");
        this.$store.commit("cmd/clearHistory");
        this.cmd = this.item.cmd;
        this.devs = this.item.devs;
        this.name = this.item.cmd.name;
        this.code = this.item.cmd.code;
        if (this.cmd.flag == 1) {
          for (let d of this.devs) {
            d.code = this.cmd.code;
            d.name = this.cmd.name;
            d.args = {};
          }
          this.$store.dispatch("cmd/sendCommands", this.devs);
        } else if (this.cmd.flag == 2) {
          for (let d of this.devs) {
            d.code = this.cmd.code;
            d.name = this.cmd.name;
            d.args = {};
          }
          this.$Modal.confirm({
            title: "提示",
            content: `确定要${this.cmd.name}吗?`,
            onOk: async () => {
              this.$store.dispatch("cmd/sendCommands", this.devs);
            },
          });
        } else if (this.cmd.flag == 4) {
          //指令参数已配置好，直接发送即可
          this.$store.dispatch("cmd/sendCommands", this.devs);
        }
        if (this.item.cmd.code == "handControlByChannel") {
          let ks = [false, false, false, false];
          if (this.item.cmd.monitor.extraData && this.item.cmd.monitor.extraData.list && this.item.cmd.monitor.extraData.list >= 4) {
            for (let i = 0; i < 4; i++) {
              ks[i] = this.item.cmd.monitor.extraData.list[i];
            }
          }
          this.form.open = ks
        }
      }
    },
    showModal(val) {
      this.$emit("showChanged", val);
    },
    cmdResultRefresh() {
      // console.log('cmd result refresh', this.commands)
      if (this.commands[0].status == 9 && this.commands[0].name == '回路控制' && this.commands[0].code == "setCycle") {
        this.monitor.extraData.list = this.commands[0].args.list
      }
    }
  },
  methods: {
    sendFailed: function () {
      // for(let d of this.commands){
      //   if(d.status == 2 || d.status == 3){
      //     this.$set(d, 'status', 0)
      //     this.$set(d, 'result', '准备重发')
      //     // this.$set(d, 'cmd', c.code)
      //     // this.$set(d, 'content', c.content)
      //   }
      // }
      setTimeout(() => {
        this.$store.dispatch('cmd/sendFailed')
        // .then(res => {
        //   if(res.code == 0){
        //     for(let c of this.commands){
        //       let its = this.devices.filter(p => p.id == c.id);
        //       if(its.length == 0)continue;
        //       this.$set(its[0], 'status', c.status)
        //       this.$set(its[0], 'result', c.result)
        //       this.$set(its[0], 'cmd', c.code)
        //       this.$set(its[0], 'content', c.content)
        //     }
        //   }
        // });
      }, 500)
    },
    updateProgress: function (content) {
      if (!content.total) {
        return "等待进度上报";
      }
      let total = parseInt(content.total);
      if (total == 0) return "0%";
      let pkg = parseInt(content.pkg ? content.pkg : content.total);
      let percent = parseInt((pkg * 100) / total);
      if (percent >= 100) {
        return "指令执行完成";
      } else {
        return `当前进度：${pkg} / ${total} = ${percent}%`;
      }
    },
    argsSaved: function (params) {
      if (this.cmd.flag == 4) {
        this.$store.dispatch("cmd/sendCommands", this.devs);
        return;
      }
      let args;
      let code = params.code;
      if (code == "getYearPlan") {
        if (this.form.useIds.length == 0) {
          this.$Message.warning("请选择要查询的时间表应用");
          return;
        }
        let devs = [];
        let cmdIndex = 0;
        for (let uid of this.form.useIds) {
          let uses = this.form.timeUses.filter((p) => p.id == uid);
          if (uses.length == 0) continue;
          for (let d of this.devs) {
            devs.push({
              id: `cmd_index_${cmdIndex++}`,
              devName: `${d.devName}_${uses[0].name}_第${uses[0].channel}路`,
              productCode: d.productCode,
              productId: d.productId,
              topType: d.topType,
              topCode: d.topCode,
              devType: d.devType,
              devCode: d.devCode,
              code: code,
              name: this.item.cmd.name,
              args: {
                channel: uses[0].channel,
                month: new Date(this.form.date).getMonth() + 1,
              },
            });
            // d.code = code;
            // d.name = params.name;
            // d.args = args;
          }
        }
        this.$store.dispatch("cmd/sendCommands", devs);
        return;
      } else if (code == "setYearPlan") {
        if (this.form.useIds.length == 0) {
          this.$Message.warning("请选择要下发的时间表应用");
          return;
        }
        let devs = [];
        let cmdIndex = 0;
        for (let uid of this.form.useIds) {
          let uses = this.form.timeUses.filter((p) => p.id == uid);
          if (uses.length == 0) continue;
          for (let d of this.devs) {
            devs.push({
              id: `cmd_index_${cmdIndex++}`,
              devName: `${d.devName}_${uses[0].name}_第${uses[0].channel}路`,
              productCode: d.productCode,
              productId: d.productId,
              topType: d.topType,
              topCode: d.topCode,
              devType: d.devType,
              devCode: d.devCode,
              code: code,
              name: this.item.cmd.name,
              args: { channel: uses[0].channel, timeId: uses[0].timeId },
            });
            // d.code = code;
            // d.name = params.name;
            // d.args = args;
          }
        }
        this.$store.dispatch("cmd/sendCommands", devs);
        return;
      }
      switch (params.code) {
        case 'setLoraWork': {
          if (params.args.channel < 0 || params.args.channel > 100) {
            this.$Message.warning('通道必须在 0 - 100 之间');
            return;
          }
          args = {
            channel: params.args.channel,
            speed: params.args.speed,
          };
          break;
        }
        case "handControlByChannel": {
          code = "setCycle";
          for (let d of this.devs) {
            d.code = code;
            d.name = this.cmd.name;
            // let monitor = this.cmd.monitor;
            // let switchs = monitor.switchs;
            // switchs[0]=this.form.open[0]
            // switchs[1]=this.form.open[1]
            // switchs[2]=this.form.open[2]
            // switchs[3]=this.form.open[3]
            d.args = { list: this.form.open };
          }
          this.$store.dispatch("cmd/sendCommands", this.devs);
          return;
        }
        case "getYearPlanByChannel": {
          code = "getYearPlan";
          for (let d of this.devs) {
            d.code = code;
            d.name = this.cmd.name;
            d.args = {
              channel: this.cmd.channel,
              month: new Date(this.form.date).getMonth() + 1,
            };
          }
          this.$store.dispatch("cmd/sendCommands", this.devs);
          return;
        }
        case "setWeekPlanByChannel": {
          code = "setWeekPlan";
          for (let d of this.devs) {
            d.code = code;
            d.name = this.cmd.name;
            d.args = { channel: this.cmd.channel, timeId: this.form.timeId };
          }
          this.$store.dispatch("cmd/sendCommands", this.devs);
          return;
        }
        case "setYearPlanByChannel": {
          code = "setYearPlan";
          for (let d of this.devs) {
            d.code = code;
            d.name = this.cmd.name;
            d.args = { channel: this.cmd.channel, timeId: this.form.timeId };
          }
          this.$store.dispatch("cmd/sendCommands", this.devs);
          return;
        }
        case "getYearPlanMuti": {
          code = "getYearPlan";
          let devs = [];
          let cmdIndex = 0;
          for (let d of this.devs) {
            let times = this.powerBoxTables.filter((p) => p.id == d.timeId);
            if (times.length == 0) continue;
            devs.push({
              id: `cmd_index_${cmdIndex++}`,
              devName: `${d.devName}_${times[0].name}_第${d.channel}路`,
              productCode: d.productCode,
              productId: d.productId,
              topType: d.topType,
              topCode: d.topCode,
              devType: d.devType,
              devCode: d.devCode,
              code: code,
              name: d.name,
              args: {
                channel: d.channel,
                month: new Date(this.form.date).getMonth() + 1,
              },
            });
          }
          this.$store.dispatch("cmd/sendCommands", devs);
          return;
        }
        case "setYearPlanMuti": {
          code = "setYearPlan";
          let devs = [];
          let cmdIndex = 0;
          for (let d of this.devs) {
            let times = this.powerBoxTables.filter((p) => p.id == d.timeId);
            if (times.length == 0) continue;
            devs.push({
              id: `cmd_index_${cmdIndex++}`,
              devName: `${d.devName}_${times[0].name}_第${d.channel}路`,
              productCode: d.productCode,
              productId: d.productId,
              topType: d.topType,
              topCode: d.topCode,
              devType: d.devType,
              devCode: d.devCode,
              code: code,
              name: d.name,
              args: { channel: d.channel, timeId: d.timeId },
            });
          }
          this.$store.dispatch("cmd/sendCommands", devs);
          return;
        }
        case "setNetwork":
          args = {
            heart: params.args.heartTime,
            host: params.args.serverIp,
            port: params.args.serverPort,
          };
          break;
        case "update": {
          args = {
            version: 3,
            flag: 0,
            len: params.args.len,
            content: params.args.content,
          };
          break;
        }
        case "setCurrentRate": {
          args = { channel: params.args.channel, k: params.args.currentRate };
          break;
        }
        case "setPlanType":
          args = {
            channel: params.args.channel,
            week: params.args.enableWeek,
            year: params.args.enableYear,
            hand: true,
            temp: false,
          };
          break;
        case "setDigitUnionParams":
          args = {
            flag: params.args.group,
            cfgs: {
              inch: params.args.inChannel,
              inval: params.args.inValue,
              outch: params.args.outChannel,
              outval: params.args.outValue,
              resetMode: params.args.resetMode,
              setTime: params.args.setTime,
              keepTime: params.args.keepTime,
            },
          };
          break;
        case "setAnalogUnionParams":
          args = {
            flag: params.args.group,
            cfgs: {
              inch: params.args.inChannel,
              inmode: params.args.inmode,
              outch: params.args.outChannel,
              outval: params.args.outValue,
              resetMode: params.args.resetMode,
              setTime: params.args.setTime,
              keepTime: params.args.keepTime,
            },
          };
          break;
        case "setAnalogAlarmRange":
          args = {
            lamax: params.args.lamax,
            lbmax: params.args.lbmax,
            lcmax: params.args.lcmax,
            lamin: params.args.lamin,
            lbmin: params.args.lbmin,
            lcmin: params.args.lcmin,
            vamax: params.args.vamax,
            vbmax: params.args.vbmax,
            vcmax: params.args.vcmax,
            vamin: params.args.vamin,
            vbmin: params.args.vbmin,
            vcmin: params.args.vcmin,
            i1min: params.args.i1min,
            i2min: params.args.i2min,
            i3min: params.args.i3min,
            i4min: params.args.i4min,
            i5min: params.args.i5min,
            i6min: params.args.i6min,
            i7min: params.args.i7min,
            i8min: params.args.i8min,
            i9min: params.args.i9min,
            i10min: params.args.i10min,
            i11min: params.args.i11min,
            i12min: params.args.i12min,
            i1max: params.args.i1max,
            i2max: params.args.i2max,
            i3max: params.args.i3max,
            i4max: params.args.i4max,
            i5max: params.args.i5max,
            i6max: params.args.i6max,
            i7max: params.args.i7max,
            i8max: params.args.i8max,
            i9max: params.args.i9max,
            i10max: params.args.i10max,
            i11max: params.args.i11max,
            i12max: params.args.i12max,
            tempmax: params.args.tempmax,
          };
          break;
        case "setLeakageChannel":
          args = {
            lach: params.args.lach,
            lbch: params.args.lbch,
            lcch: params.args.lcch,
          };
          break;
        case "setCurrentLevel":
        case "setCurrentError":
          args = {
            levels: [
              params.args.level1,
              params.args.level2,
              params.args.level3,
              params.args.level4,
              params.args.level5,
              params.args.level6,
              params.args.level7,
              params.args.level8,
            ],
          };
          break;
        case "openSingle":
          code = "handSingle";
          args = {
            bright1: 100,
            color1: 100,
            bright2: 0,
            color2: 0,
            expire: params.args.delay,
          };
          break;
        case "closeSingle":
          code = "handSingle";
          args = {
            bright1: 0,
            color1: 0,
            bright2: 0,
            color2: 0,
            expire: params.args.delay,
          };
          break;
        case 'setLuxCtrlMode':
          code = 'setLuxCtrlMode';
          args = {
            flag: params.args.luxmode
          }
          break;
        case 'setLuxAutoMode':
          code = 'setLuxAutoMode';
          args = {
            no: params.args.luxGroupNo,
            enable: params.args.luxEnable,
            lux: params.args.luxTarget,
            error: params.args.luxError,
          }
          break;
        case 'setLuxLevelMode':
          code = 'setLuxLevelMode';
          args = {
            groups: params.args.luxGroups,
          }
          break;
        default: {
          args = {};
        }
      }
      for (let d of this.devs) {
        d.code = code;
        d.name = params.name;
        d.args = args;
      }
      this.$store.dispatch("cmd/sendCommands", this.devs);
    },
    ok: function (is) {
      if (this.item.cmd.code == "update") {
        if (this.$refs.fileInput.files.length == 0) {
          this.$Message.warning("请选择升级文件");
          return;
        }
        var reader = new FileReader(); //实例化文件读取对象
        reader.readAsDataURL(this.$refs.fileInput.files[0]); //将文件读取为 DataURL,也就是base64编码
        let that = this;
        reader.onload = function (ev) {
          //文件读取成功完成时触发
          var dataURL = ev.target.result; //获得文件读取成功后的DataURL,也就是base64编码
          // document.querySelector("img").src = dataURL; //将DataURL码赋值给img标签
          dataURL = dataURL.replace(
            "data:application/octet-stream;base64,",
            ""
          );
          // that.$emit('saved', {code: that.code, name: that.name, packLen: that.form.packLen, args: dataURL})
          that.argsSaved({
            code: that.code,
            name: that.name,
            args: { len: that.form.packLen, content: dataURL },
          });
          // that.loading = false;
          // that.showModal = false;
          that.$refs.fileInput.value = "";
        };
      } else if (this.item.cmd.code == "handControlByChannel") {
        if (this.loopList.filter(p => p == 1).length == 0) {
          this.$Message.warning("请点选中操作的回路");
          return false;
        }
        this.loopList.forEach((el, index) => {
          if (el == 1 && is) {
            this.form.open[index] = true;
          }
          if (el == 1 && !is) {
            this.form.open[index] = false;
          }
        });
        this.argsSaved({
          code: this.cmd.code,
          name: this.cmd.name,
          args: this.form,
        });
      } else {
        // this.$emit('saved', {code: this.code, name: this.name, args: this.form})
        this.argsSaved({
          code: this.cmd.code,
          name: this.cmd.name,
          args: this.form,
        });
        // this.showModal = false;
      }
    },
    switchsBut(index, is) {
      is = is == 1 ? 0 : 1;
      this.loopList.splice(index - 1, 1, is)
    },
    cancel() {
      if (this.$refs.fileInput) {
        this.$refs.fileInput.value = "";
      }
      this.showModal = false;
    },
  },
};
</script>
<style scoped>
.muti-table {
  width: 100%;
}
.muti-table tr td {
  border: solid 1px #e8e8e8;
}
.demo-drawer-footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  background: #fff;
}
.switchsBut {
  margin-left: 20px;
  flex: auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;
}
.form {
  height: calc(100% - 54px);
  overflow: auto;
}
.readonly {
  background: #eee;
}
.form-item {
  margin-bottom: 10px;
}
.loopList {
  border: 1px solid #05bbd3;
  box-sizing: border-box;
  position: relative;
}
.loopText {
  position: absolute;
  right: 3px;
  top: 3px;
  color: #f00;
}
.trans-btn {
  width: 60px;
  display: inline-block;
  height: 30px;
  text-align: center;
  line-height: 30px;
  border: 1px solid #05bbd3;
  margin-left: 5px;
  margin-top: 20px;
}
</style>