<template>
  <Modal v-model="showModal" :mask-closable="false" width="1200px">
    <div slot="header">组织 【{{item.name}}】 应用 【{{item.appName}}】 的角色信息</div>
    <div class="edit-area">
      <u-table ref="chkTable" style="width: 100%;" use-virtual row-id="id" border stripe :height="600" show-body-overflow>
        <u-table-column type="index" header-align="center"></u-table-column>
        <!-- <u-table-column type="selection" header-align="center" fixed="left"></u-table-column> -->
        <u-table-column prop="name" label="名称" width="200" header-align="center">
          <template slot-scope="params">
            {{params.row.name}}
          </template>
        </u-table-column>
        <u-table-column prop="remark" label="说明" header-align="center">
          <template slot-scope="params">
            {{params.row.remark}}
          </template>
        </u-table-column>
        <u-table-column width="120">
          <template slot="header">
            <AuthButton opCode='sra' style="margin-right: 5px" @click="handleAdd">新增</AuthButton>
            <AuthButton opCode='srv' style="margin-right: 5px" @click="getList">刷新</AuthButton>
          </template>
          <template slot-scope="scope">
            <!-- <AuthButton type="info" opCode='are' style="margin-right: 5px" @click="handleUser(scope)">查看用户</AuthButton> -->
            <AuthButton opCode='sre' style="margin-right: 5px" @click="handleEdit(scope)">编辑</AuthButton>
            <AuthButton opCode='srd' type="error" @click="handleDelete(scope)">删除</AuthButton>
          </template>
        </u-table-column>
      </u-table>
    </div>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">关闭</Button>
    </div>
    <ModalRoleEdit v-model="showEditModal" :item="editItem" @saved="itemSaved" />
  </Modal>
</template>
<script>
// import { mapState } from 'vuex'
import ModalRoleEdit from './ModalRoleEdit'
export default {
  name: 'ModalRoleList',
  components:{
    ModalRoleEdit,
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default(){ return {};}
    },
  },
  data () {
    return {
      showModal: this.value,
      editItem: {},
      showEditModal: false,
      loading: false,
      isAdd: false,
      list: [],
    }
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if(newVal){
        this.getList();
      }
    },
    showModal(val){
      this.$emit('showChanged', val);
    },
  },
  computed: {
  },
  mounted: function(){
  },
  methods: {
    handleAdd: function(params){
      this.editItem = {isAdd: true, data: params.row, cust: this.item};
      this.showEditModal = true;
    },
    handleEdit: function(params){
      this.editItem = {isAdd: false, data: params.row, cust: this.item};
      this.showEditModal = true;
    },
    handleDelete: function(params){
      this.$Modal.confirm({
        title: "提示",
        content: `确定要删除角色[${params.row.name}]吗?`,
        onOk: async () => {
          this.$axios.post(`//${this.domains.trans}/station/auth/DeleteRole`, {id: params.row.id}).then(res => {
            if(res.code != 0){
              // this.$Message.error(res.data);
            }else{
              this.getList();
            }
          });
        }
      });
    },
    getList: function(){
      this.$axios.post(`//${this.domains.trans}/station/auth/QueryRole`, {id: this.item.id}).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          this.$set(this, 'list', res.data);
          this.$refs.chkTable.reloadData(res.data)
        }
      });
    },
    itemSaved: function(){
      this.getList();
    },
    cancel () {
      this.showModal = false;
    },
  }
}
</script>
<style scoped>
.form-item-group{
  border: solid 1px rgba(129, 124, 124, 0.377);
  border-radius: 12px;
  margin-bottom: 15px;
}
.group-header{
  /* border: solid 1px blue; */
  background-color: rgba(221, 216, 216, 0.808);
  margin-bottom: 15px;
  height: 35px;
  border-radius: 12px 12px 0 0;
  line-height: 35px;
  font-weight: bold;
  padding: 0 10px;
}
.edit-area{
  /* border: solid 1px blue; */
  width: 100%;
  height: 600px;
  display: flex;
}
.form-area{
  width: 410px;
  flex: none;
}
.fun-area{
  width: 400px;
  flex: auto;
  margin-left: 20px;
  border: solid 1px rgba(216, 199, 199, 0.438);
  border-radius: 6px;
  overflow: auto;
  position: relative;
}
.chk-all{
  position: absolute;
  top: 10px;
  right: 10px;
}
.logo-upload {
  width: 410px;
  height: 114px;
  border: solid 1px gray;
  cursor: pointer;
}
.logo-upload input {
  visibility: hidden;
}
.logo-upload img {
  width: 410px;
  height: 114px;
}
.fun-list {
  display: flex;
  /* list-style: none; */
  flex-direction: column;
  margin: 8px 15px;
}
.chk-list {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
}
li {
  margin: 0 5px;
}
</style>