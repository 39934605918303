<template>
  <div class="station-alarm-container">
    <div class="alarm-data-query">
      <template v-for="(cmd, idx) in canDoCmds">
        <AuthButton v-if="idx < cmdcnt" :key="idx" :opCode="cmd.opcode" size="default" style="margin-right: 5px" @click="clickCmd(cmd)">{{cmd.name}}</AuthButton>
      </template>
      <Dropdown style="margin-right: 10px" v-if="canDoCmds.length > cmdcnt">
        <Button type="primary">
          其他<Icon type="ios-arrow-down"></Icon>
        </Button>
        <div slot="list" class="btns-flex" style="width: 500px">
          <template v-for="(cmd, idx) in canDoCmds">
            <AuthButton v-if="idx >= cmdcnt" :key="idx" :opCode="cmd.opcode" size="default" style="margin: 5px" @click="clickCmd(cmd)">{{cmd.name}}</AuthButton>
          </template>
        </div>
      </Dropdown>
      <AuthButton opCode='sc' type="success" size="default" style="margin-right: 5px" @click="sendFailed">重发失败</AuthButton>
      <AuthButton opCode='sc' type="success" size="default" style="margin-right: 5px" @click="exportResult">导出结果</AuthButton>
      <Checkbox v-model="clearOldLog">发送指令时，是否清除旧指令记录？</Checkbox>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
// import exportXlsx from '@/components/xlsx/exportXlsx'
export default {
  name: 'LightControlMenuCommon',
  components: {
  },
  props: {
  },
  data() {
    return {
      clearOldLog: this.clearOldLogs,
      showArgModal: false,
      cmdItem: { item: {}, other: {}, args: {} },
      cmdcnt: 8,
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes']),
    ...mapState('cmd', ['cmdResultRefresh', 'commands', 'windowOnResize', 'clearOldLogs', 'monitorCmds']),
    ...mapState('common', ['deviceTypes', 'productCodes', 'connectTypes']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
    canDoCmds: function () {
      let cmds = [];
      for (let cmd of this.monitorCmds) {
        if (this.funCodes(cmd.opcode)) cmds.push(cmd);
      }
      return cmds;
    }
  },
  watch: {
    clearOldLog() {
      this.$store.commit('cmd/setOldLogStatus', this.clearOldLog)
    },
  },
  mounted: function () {
    setTimeout(this.setTableHeight, 100);
    // console.log('monitor cmds mounted', this.monitorCmds)
  },
  destroyed: function () {
  },
  methods: {
    exportResult: function(){
      this.$emit('cmd', {code: 'export', args: {}})
    },
    sendFailed: function () {
      this.$emit('cmd', {code: 'sendFailed', args: {clearOldLog: this.clearOldLog}})
      // let chks = this.$refs.chkTable.getCheckboxRecords();
      // let sids = [];
      // for (let d of this.list) {
      //   if ((d.status == 2 || d.status == 3) && chks.filter(p => p.id == d.id).length > 0) {
      //     this.$set(d, 'status', 0)
      //     this.$set(d, 'result', '准备重发')
      //     sids.push(d.id);
      //     // this.$set(d, 'cmd', c.code)
      //     // this.$set(d, 'content', c.content)
      //   } else if (this.clearOldLog) {
      //     this.$set(d, 'status', 0)
      //     this.$set(d, 'result', '')
      //   }
      // }
      // setTimeout(() => {
      //   this.$store.dispatch('cmd/sendFailed', sids).then(res => {
      //     if (res.code == 0) {
      //       for (let c of this.commands) {
      //         let its = this.list.filter(p => p.id == c.id);
      //         if (its.length == 0) continue;
      //         this.$set(its[0], 'status', c.status)
      //         this.$set(its[0], 'result', c.result)
      //         this.$set(its[0], 'cmd', c.code)
      //         this.$set(its[0], 'content', c.content)
      //       }
      //     }
      //   });
      // }, 500)
    },
    getCmdCount: async function () {
      this.$emit('cmd', {code: 'getCmdCount', args: {}});
      // if (!this.currentAdjustParam) return;
      // console.log('get cmd cnt', item)
      // item.status = 0;
      // item.result = `开始查询队列指令数量`;
      // this.$axios.post(`//${this.domains.trans}/station/command/device`, { type: 'count', deviceId: item.devId }).then(res => {
      //   if (res.code !== 0) {
      //     // this.$Message.error(res.data);
      //     // this.hideLoading();
      //     // return;
      //     item.status = 3;
      //     item.result = `${res.data}`;
      //   } else {
      //     item.status = 9;
      //     item.result = `当前队列指令数量： ${res.data}`;
      //   }
      //   // this.$Message.info(`当前队列指令数量： ${res.data}`);
      // })
    },
    clearCmds: async function () {
      this.$emit('cmd', {code: 'clearCmds', args: {}});
      // this.$Modal.confirm({
      //   title: "提示",
      //   content: `确定要清除[${item.name}]的指令队列吗?`,
      //   onOk: async () => {
      //     item.status = 0;
      //     item.result = `开始清除队列指令数量`;
      //     this.$axios.post(`//${this.domains.trans}/station/command/device`, { type: 'clear', deviceId: item.devId }).then(res => {
      //       if (res.code !== 0) {
      //         item.status = 3;
      //         item.result = `执行失败： ${res.data}`;
      //       } else {
      //         item.status = 9;
      //         item.result = `指令队列清除成功`;
      //       }
      //     })
      //   },
      // });
    },
    clickCmd: function (item) {
      // console.log('click cmd', item)
      this.$emit('cmd', {code: 'clickCmd', args: {item}});
      // let chks = this.$refs.chkTable.getCheckboxRecords();
      // if (chks.length == 0) {
      //   this.$Message.warning('请选择要控制的通道');
      //   return;
      // }
      // // console.log('click cmd', item, chks);
      // if (item.needPswd || item.needArgs) {
      //   this.cmdItem = { code: item.code, name: item.name, pswd: item.needPswd, args: {} };
      //   this.showArgModal = true;
      // } else {
      //   if (this.clearOldLog) {
      //     for (let item of this.list) {
      //       item.result = '';
      //       item.status = 0;
      //     }
      //   }
      //   let cmds = []
      //   let ds = {};
      //   for (let chk of chks) {
      //     if (chk.topId == 0) {
      //       ds[chk.sname] = chk.devId;
      //     } else {
      //       ds[chk.sname] = chk.topId;
      //     }
      //     chk.result = '';
      //     chk.status = 0;
      //     // console.log('cmd saved', chk);
      //     cmds.push({ id: chk.id, code: item.code, name: item.name, paramId: chk.lid, ctype: chk.connectType, deviceId: chk.devId, args: {} });
      //   }
      //   this.$store.dispatch('cmd/sendCommands', cmds);
      // }
    },
    // argsSaved: function (params) {
    //   if (this.clearOldLog) {
    //     for (let item of this.list) {
    //       item.result = '';
    //       item.status = 0;
    //     }
    //   }
    //   let chks = this.$refs.chkTable.getCheckboxRecords();
    //   let cmds = []
    //   let ds = {};
    //   let ns = [];
    //   for (let chk of chks) {
    //     ns.push(chk.name);
    //     ds[chk.name] = chk.topId;
    //     chk.result = '';
    //     chk.status = 0;
    //     let args = this.clone(params.args);// {channel: chk.channel}
    //     // args.channel = chk.channel;
    //     // params.args.channel = chk.channel;
    //     let code = params.code;
    //     if (params.code == 'openSingle' || params.code == 'closeSingle') {
    //       code = 'handSingle';
    //     } else if (params.code == 'handControl') {
    //       args.point = false;
    //       // args.expire = params.args.expire;
    //       // params.args.point = false;
    //     } else if (params.code == 'setYearPlan') {
    //       // params.args.timeId = chk.timeId;
    //       args.timeId = chk.timeId;
    //     } else if (params.code == 'update') {
    //       args.flag = 1;
    //     }
    //     cmds.push({ id: chk.id, code: code, name: params.name, paramId: chk.lid, ctype: chk.connectType, deviceId: chk.devId, args: args });
    //   }
    //   if (params.code == 'update') {
    //     this.$Modal.confirm({
    //       title: "提示",
    //       content: `确定要升级设备[${ns}]吗?`,
    //       onOk: async () => {
    //         this.$store.dispatch('cmd/sendCommands', cmds);
    //       },
    //     });
    //   } else {
    //     this.$store.dispatch('cmd/sendCommands', cmds);
    //   }
    // },
  }
}
</script>
<style scoped>
.transfer-flex {
  width: 50%;
}
.btns-flex {
  width: 50%;
  display: flex;
  flex-wrap: wrap;
}
.station-alarm-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: stretch;
  /* padding-right: 40px; */
  /* border: solid 1px red; */
}
.alarm-data-query {
  height: 40px;
  flex: none;
  background-color: white;
  margin: 3px 1px;
  display: flex;
  align-items: center;
  padding: 10px;
  white-space: nowrap;
}
.alarm-data-area {
  /* border: solid 1px red; */
  height: 100px;
  flex: auto;
  border-radius: 6px;
}
.tab-active {
  border: solid 1px rgb(135, 159, 224);
  background-color: rgb(135, 159, 224);
  color: white;
}
.station-alarm-tabcontent {
  height: 500px;
  flex: auto;
  /* border: solid 1px red; */
}
.alarm-data-area >>> .plTableBox .el-table tr .umy-table-beyond {
  white-space: break-spaces !important;
  color: #324252;
}
/* .box-body{
  height: 0;
  flex: auto;
  margin-top: 5px;
  border: solid 1px red;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  position: relative;
}
.box-body::-webkit-scrollbar {
  display: none;
} */
</style>