<template>
<div class="config-station-container">
  <div class="station-data-area" ref="table">
    <u-table :data="list" style="width: 100%;" row-key="id" border stripe :height="tabHeight" default-expand-all>
      <u-table-column prop="name" label="名称" sortable width="200">
        <template slot-scope="params">
          {{params.row.name}}
        </template>
      </u-table-column>
      <u-table-column prop="interval" label="默认采集间隔" sortable width="130">
        <template slot-scope="params">
          {{params.row.interval}} 秒
        </template>
      </u-table-column>
      <u-table-column prop="confirmTime" label="触发确认时长" sortable width="130">
        <template slot-scope="params">
          {{params.row.confirmTime}} 秒
        </template>
      </u-table-column>
      <u-table-column prop="enableAuto" label="是否启用配电控制" sortable width="160">
        <template slot-scope="params">
          {{params.row.enableAuto ? '启用' : '禁用'}}
        </template>
      </u-table-column>
      <u-table-column prop="controlTime" label="配电控制延时时长" sortable width="160">
        <template slot-scope="params">
          {{params.row.controlTime}} 分钟
        </template>
      </u-table-column>
      <u-table-column prop="openRate" label="开灯光照限值" sortable width="160">
        <template slot-scope="params">
          {{params.row.openRate}} lux
        </template>
      </u-table-column>
      <u-table-column prop="openMaxTime" label="提前开灯时间最大限值" sortable width="200">
        <template slot-scope="params">
          {{params.row.openMaxTime}} 分钟
        </template>
      </u-table-column>
      <u-table-column prop="openMinTime" label="提前开灯时间最小限值" sortable width="200">
        <template slot-scope="params">
          {{params.row.openMinTime}} 分钟
        </template>
      </u-table-column>
      <u-table-column prop="closeMinTime" label="延时关灯时间限值" sortable width="160">
        <template slot-scope="params">
          {{params.row.closeMinTime}} 分钟
        </template>
      </u-table-column>
      <u-table-column prop="powerOtherAction" label="超时触发动作" sortable width="160">
        <template slot-scope="params">
          {{actions[params.row.powerOtherAction]}}
        </template>
      </u-table-column>
      <u-table-column prop="enableLight" label="是否启用单灯控制" sortable width="160">
        <template slot-scope="params">
          {{params.row.enableLight ? '启用' : '禁用'}}
        </template>
      </u-table-column>
      <u-table-column prop="controlLightTime" label="单灯控制延时时长" sortable width="160">
        <template slot-scope="params">
          {{params.row.controlLightTime}} 分钟
        </template>
      </u-table-column>
      <u-table-column width="135" fixed="right">
        <template slot="header" slot-scope="scope">
          <AuthButton opCode='lra' style="margin-right: 5px" @click="handleAdd(scope)">新增</AuthButton>
          <AuthButton opCode='lrv' style="margin-right: 5px" @click="getList">刷新</AuthButton>
        </template>
        <template slot-scope="scope">
          <AuthButton opCode='lre' style="margin-right: 5px" @click="handleEdit(scope)">编辑</AuthButton>
          <AuthButton opCode='lrd' type="error" @click="handleDelete(scope)">删除</AuthButton>
        </template>
      </u-table-column>
    </u-table>
  </div>
  <ModalRuleEdit v-model="showEditModal" :item="editItem" @saved="getList" />
</div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import ModalRuleEdit from './ModalRuleEdit'
export default {
  name: 'LuxConfigGroup',
  components:{
    ModalRuleEdit,
  },
  data () {
    return {
      showEditModal: false,
      tabHeight: 300,
      editItem: {isAdd: true, index: -1, data: {}},
      list: [],
      actions: {
        0: '不动作',
        1: '界面提示开灯',
        2: '自动控制开灯',
      }
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapGetters('group', ['groupPathName']),
    ...mapState('cmd', ['windowOnResize', 'cmds']),
    ...mapState('common', ['powerBoxAlarmTypes', 'productCodes', 'powerBoxPartTypes', 'powerBoxTypes']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
  },
  watch: {
    windowOnResize(){
      this.tabHeight = this.$refs.table.clientHeight
      // console.log('windowOnResize', this.windowOnResize, this.$refs.table.clientHeight)
    },
  },
  mounted: function(){
    setTimeout(() => {
      this.tabHeight = this.$refs.table.clientHeight;
    }, 200);
    this.getList();
  },
  methods: {
    getList: function(){
      this.$axios.post(`//${this.domains.trans}/station/lux/QueryRule`, {}).then(res => {
        if(res.code === 0){
          this.$set(this, 'list', res.data)
        }else{
          // this.$Message.error(res.data);
        }
      });
    },
    getDate:function(date){
      let d = new Date(date);
      if(d.getFullYear() == 1970)return '';
      return d.format('yyyy-MM-dd');
    },
    handleAdd: function(){
      this.editItem = {isAdd: true, data: {}};
      this.showEditModal = true;
    },
    handleEdit: function(params){
      this.editItem = {isAdd: false, data: params.row};
      this.showEditModal = true;
    },
    handleDelete: function(params){
      this.$Modal.confirm({
        title: "提示",
        content: `确定要删除控制规则[${params.row.name}]吗?`,
        onOk: async () => {
          this.$axios.post(`//${this.domains.trans}/station/lux/DeleteRule`, {id: params.row.id}).then(res => {
            if(res.code != 0){
              // this.$Message.error(res.data);
            }else{
              this.getList();
            }
          });
        }
      });
    },
  }
}
</script>
<style scoped>
.config-station-container{
  /* padding: 5px;
  padding-right: 40px; */
  height: 100%;
  display: flex;
  flex-direction: column;
  /* border: solid 1px red; */
}
.station-search-area{
  height: 42px;
  flex: none;
  border: solid 1px #DCDEE2;
  border-radius: 6px;
  margin-bottom: 5px;
  padding-top: 3px;
}
.station-data-area{
  /* border: solid 1px red; */
  height: 100%;
  flex: auto;
  /* border: solid 1px #DCDEE2; */
  border-radius: 6px;
}
</style>