<template>
  <div class="config-lux-container">
    <div class="lux-search-area">
      所属站点：
      <Select v-model="currentStationId" number style="width: 400px; margin-right: 10px">
        <template v-for="(item, idx) in stations">
          <Option :value="item.id" :key="idx">{{item.name}} - {{item.groupName}}</Option>
        </template>
      </Select>
      <!-- <Button type="success" :loading="loading" @click="addLux">新增</Button> -->
      <AuthButton opCode='lpa1' size="default" type="success" @click="addLux">新增</AuthButton>
    </div>
    <div class="lux-data-area" ref="table">
      <table class="lux-item-table">
        <tr v-if="isAdd">
          <td style="width: 50px">新增、</td>
          <td style="width: 300px">
            <Input type="text" v-model="editItem.name" clearable>
            <span slot="prepend">属性名称</span>
            <!-- <span slot="append">KW</span> -->
            </Input>
          </td>
          <td style="width: 80px;text-align:right;">关联设备：</td>
          <td style="width: 600px">
            <Select v-model="editItem.deviceId">
              <Option v-for="(dev, didx) in devs" :key="didx" :value="dev.id">{{dev.name}}[{{deviceTypes[dev.type]}}][ID:{{dev.code}}]</Option>
            </Select>
          </td>
          <td style="width: 70px;text-align:right;"><Button type="primary" :loading="loading" @click="saveLux(null)">保存</Button></td>
          <td style="width: 70px;text-align:right;"><Button type="default" :loading="loading" @click="cancelLux">取消</Button></td>
          <td></td>
        </tr>
        <tr v-for="(lux, idx) in luxes" :key="idx">
          <td style="width: 50px">NO.{{idx+1}}、</td>
          <td style="width: 300px">
            <Input type="text" v-model="lux.name" clearable>
            <span slot="prepend">属性名称</span>
            <!-- <span slot="append">KW</span> -->
            </Input>
          </td>
          <td style="width: 80px;text-align:right;">关联设备：</td>
          <td style="width: 600px">
            <Select v-model="lux.deviceId">
              <Option v-for="(dev, didx) in devs" :key="didx" :value="dev.id">{{dev.name}}[{{deviceTypes[dev.type]}}][ID:{{dev.code}}]</Option>
            </Select>
          </td>
          <td style="width: 70px;text-align:right;">
            <!-- <Button type="primary" :loading="loading" @click="saveLux(lux)">保存</Button> -->
            <AuthButton opCode='lpe1' size="default" type="primary" @click="saveLux(lux)">保存</AuthButton>
          </td>
          <td style="width: 70px;text-align:right;">
            <!-- <Button type="error" :loading="loading" @click="deleteLux(lux)">删除</Button> -->
            <AuthButton opCode='lpd1' size="default" type="error" @click="deleteLux(lux)">删除</AuthButton>
          </td>
          <td></td>
        </tr>
      </table>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'ConfigLuxIndex',
  components: {
  },
  props: {
  },
  data() {
    return {
      stations: [],
      tabHeight: 300,
      currentStationId: 0,
      showEditModal: false,
      loading: false,
      isAdd: false,
      luxes: [],
      devs: [],
      editItem: {
        name: '',
        deviceId: 0,
        stationId: 0,
        type: 201,
        content: {},
      },
    }
  },
  watch: {
    windowOnResize() {
      this.setTableHeight();
    },
    currentStationId() {
      this.getDeviceList();
    },
  },
  computed: {
    ...mapState('group', ['groups', 'selectedNode', 'selectedDevices']),
    ...mapState('cmd', ['windowOnResize']),
    ...mapState('common', ['productCodes', 'deviceTypes']),
    selectedStation: function () {
      if (this.currentStationId <= 0) return null;
      let ss = this.stations.filter(p => p.id == this.currentStationId);
      if (ss.length == 0) return null;
      return ss[0];
    }
  },
  mounted: function () {
    setTimeout(this.setTableHeight, 200);
    this.getList();
  },
  methods: {
    getDeviceList: function () {
      this.$axios.post(`//${this.domains.trans}/station/lux/QueryDevice`, { id: this.currentStationId }).then(res => {
        if (res.code != 0) {
          // this.$Message.error(res.data);
        } else {
          this.$set(this, 'devs', res.data);
          this.getLuxes();
        }
      });
    },
    getLuxes: function () {
      if (this.currentStationId <= 0) {
        this.$set(this, 'luxes', []);
        return;
      }
      this.$axios.post(`//${this.domains.trans}/station/lux/QueryParam`, { id: this.currentStationId }).then(res => {
        if (res.code != 0) {
          // this.$Message.error(res.data);
        } else {
          this.$set(this, 'luxes', res.data);
        }
      });
    },
    getList: function () {
      this.$axios.post(`//${this.domains.trans}/station/lux/QueryStation`, {}).then(res => {
        if (res.code != 0) {
          // this.$Message.error(res.data);
        } else {
          this.$set(this, 'stations', res.data);
          if (res.data.length == 0) {
            this.$Message.warning('请先创建光控站点');
          } else {
            this.currentStationId = res.data[0].id;
          }
        }
      });
    },
    addLux: function () {
      if (!this.selectedStation) {
        this.$Message.warning('请选择一个站点');
        return;
      }
      this.editItem.name = '';
      this.editItem.stationId = this.selectedStation.id;
      this.editItem.deviceId = 0;
      this.isAdd = true;
    },
    cancelLux: function () {
      this.isAdd = false;
    },
    saveLux: function (lux) {
      if (!lux) {
        if (this.editItem.name == '') {
          this.$Message.warning('请输入属性名称');
          return;
        }
        if (this.editItem.deviceId == 0) {
          this.$Message.warning('请选择关联设备');
          return;
        }
        if (this.editItem.stationId == 0) {
          this.$Message.warning('请选择所属站点');
          return;
        }
      } else {
        if (lux.name == '') {
          this.$Message.warning('请输入属性名称');
          return;
        }
        if (lux.deviceId == 0) {
          this.$Message.warning('请选择关联设备');
          return;
        }
      }
      this.loading = true;
      this.$axios.post(`//${this.domains.trans}/station/lux/SaveParam`, !lux ? this.editItem : lux).then(res => {
        this.loading = false;
        if (res.code != 0) {
          // this.$Message.error(res.data);
          return;
        }
        this.$Message.info('保存成功');
        this.isAdd = false;
        this.getLuxes();
      })
    },
    deleteLux: function (params) {
      this.$Modal.confirm({
        title: "提示",
        content: `确定要删除属性 ${params.name} 吗?`,
        onOk: async () => {
          this.$axios.post(`//${this.domains.trans}/station/lux/DeleteParam`, { id: params.id }).then(res => {
            if (res.code == 0) {
              this.$Message.info(`删除成功`, { timeout: 4000 });
              this.getLuxes();
            } else {
              // this.$Message.error({
              //   content: `删除失败：${res.data}`,
              //   duration: 4
              // });
            }
          });
        }
      });
    },
    setTableHeight: function () {
      this.tabHeight = this.$refs.table.clientHeight;
    },
    setDefaultStaion: function () {
      if (this.stations.length > 0) {
        this.currentStationId = this.stations[0].id;
      }
    },
  }
}
</script>
<style scoped>
.config-lux-container {
  /* padding: 5px;
  padding-right: 50px; */
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
  /* border: solid 1px red; */
}
.lux-search-area {
  height: 42px;
  flex: none;
  border-radius: 6px;
  margin-bottom: 5px;
  padding: 3px 10px;
  display: flex;
  align-items: center;
}
.lux-data-area {
  /* border: solid 1px red; */
  height: 100%;
  flex: auto;
  border: solid 1px #dcdee2;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  overflow: auto;
}
.lux-data-item {
  width: 100%;
  height: 200px;
  flex: none;
  /* border: solid 1px red; */
  border: solid 1px #cad1d9;
  margin-bottom: 30px;
  border-radius: 6px;
}
.lux-item-header {
  display: flex;
  height: 48px;
  align-items: center;
  background: linear-gradient(270deg, #3682ff, #4c9eff);
  border-radius: 6px 6px 0px 0px;
}

.header-title {
  line-height: 48px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #ffffff;
  flex: none;
  /* width: 74px; */
  display: flex;
  align-items: center;
}
.header-title div {
  width: 6px;
  height: 6px;
  background: #ffffff;
  margin: 0 10px 0 20px;
}
.header-extra {
  cursor: pointer;
  width: 74px;
  height: 22px;
  border: 1px solid #1b5fa8;
  border-radius: 4px;
  margin-right: 20px;
  flex: none;
  text-align: center;
  background-color: #ffffff;
  color: #3880fc;
}
.header-content {
  flex: auto;
}
.edit-content {
  padding: 20px;
}
</style>