<template>
  <Modal v-model="showModal" :mask-closable="false" width="1200px">
    <div slot="header">组织 【{{item.name}}】 应用 【{{item.appName}}】 的用户信息</div>
     <div>
        <Input v-model="filterName" placeholder="请输入..." style="width:200px"></Input>
        <Button type="success" @click="searchEvent()" class="ml10">搜索</Button>
      </div>
    <div class="edit-area mt10">
      <vxe-table ref="chkTable" border show-overflow show-header-overflow :data="listData" :height="600" :row-config="{isHover: true}">
        <vxe-column type="seq" width="60" ></vxe-column>
        <vxe-column field="stats" title="状态" width="70" header-align="center">
          <template #default="{ row }">
            {{getUserState(row)}}
          </template>
        </vxe-column>
        <vxe-column field="code" title="登录名" width="120" header-align="center">
          <template #default="{ row }">
            {{row.code}}
          </template>
        </vxe-column>
        <vxe-column field="name" title="姓名" width="100" header-align="center">
          <template #default="{ row }">
            {{row.name}}
          </template>
        </vxe-column>
        <vxe-column field="mobile" title="手机号" width="130" header-align="center">
          <template #default="{ row }">
            {{row.mobile}}
          </template>
        </vxe-column>
        <vxe-column field="needExpire" title="过期时间" width="100" header-align="center">
          <template #default="{ row }">
            {{row.needExpire ? new Date(row.expire).format('yyyy-MM-dd') : '永久'}}
          </template>
        </vxe-column>
        <vxe-column field="roleName" title="角色" header-align="center" width="180">
          <template #default="{ row }">
            {{row.roleName}}
          </template>
        </vxe-column>
        <vxe-column field="groupName" title="站点分组" header-align="center" width="180">
          <template #default="{ row }">
            {{row.groupName}}
          </template>
        </vxe-column>
        <vxe-column width="220" title="操作" fixed="right">
          <template #header>
            <AuthButton opCode='sua' style="margin-right: 5px" @click="handleAdd">新增</AuthButton>
            <AuthButton opCode='suv' style="margin-right: 5px" @click="getList">刷新</AuthButton>
          </template>
          <template #default="params">
            <AuthButton v-if="params.row.locked" type="success" opCode='sue1' style="margin-right: 5px" @click="handleLocked(params)">解锁</AuthButton>
            <AuthButton :type="params.row.active ? 'warning' : 'success'" opCode='sue1' style="margin-right: 5px" @click="handleEnable(params)">
              {{params.row.active ? '禁用' : '启用'}}</AuthButton>
            <AuthButton opCode='sue' style="margin-right: 5px" @click="handleEdit(params)">编辑</AuthButton>
            <AuthButton opCode='sud' type="error" @click="handleDelete(params)">删除</AuthButton>
          </template>
        </vxe-column>
      </vxe-table>
    </div>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">关闭</Button>
    </div>
    <ModalUserEdit v-model="showEditModal" :item="editItem" @saved="itemSaved" />
  </Modal>
</template>
<script>
// import { mapState } from 'vuex'
import ModalUserEdit from './ModalUserEdit'
export default {
  name: 'ModalUserList',
  components: {
    ModalUserEdit,
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default() { return {}; }
    },
  },
  data() {
    return {
      showModal: this.value,
      editItem: {},
      showEditModal: false,
      loading: false,
      isAdd: false,
      listData: [],
      list: [],
      filterName: ''
    }
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if (newVal) {
        this.getList();
      }
    },
    showModal(val) {
      this.$emit('showChanged', val);
    },
  },
  computed: {
  },
  mounted: function () {
  },
  methods: {
    searchEvent() {
      let arr=[];
      let term=['name','roleName','mobile','code'];
      if(this.filterName==''){
        this.listData=this.list;
      }
      this.list.forEach(el => {
          for(let val in el){
            if(typeof(el[val])=='string' && el[val].indexOf(this.filterName)>-1 && term.includes(val)){
             arr.push(el);
             break
            }
          }
      });
      this.listData=arr;
    },
    getUserState: function (params) {
      if (!params.active) {
        return '已停用';
      }
      if(params.locked){
        return '已锁定';
      }
      if (params.needExpire) {
        let expire = new Date(params.expire);
        expire.setDate(expire.getDate() + 1);
        if (new Date().getTime() > expire.getTime()) {
          return '已过期';
        }
      }
      return '正常';
    },
    handleAdd: function (params) {
      this.editItem = { isAdd: true, data: params.row, cust: this.item };
      this.showEditModal = true;
    },
    handleEdit: function (params) {
      this.editItem = { isAdd: false, data: params.row, cust: this.item };
      this.showEditModal = true;
    },
    handleEnable: function (params) {
      this.$Modal.confirm({
        title: "提示",
        content: `确定要${params.row.active ? '禁用' : '启用'}用户[${params.row.name}]吗?`,
        onOk: async () => {
          this.$axios.post(`//${this.domains.trans}/station/auth/EnableUser`, { id: params.row.id, active: !params.row.active }).then(res => {
            if (res.code == 0) {
              this.getList();
            }
          });
        }
      });
    },
    handleLocked: function (params) {
      this.$Modal.confirm({
        title: "提示",
        content: `确定要解锁用户[${params.row.name}]吗?`,
        onOk: async () => {
          this.$axios.post(`//${this.domains.trans}/station/auth/UnlockUser`, { id: params.row.id }).then(res => {
            if (res.code == 0) {
              this.getList();
            }
          });
        }
      });
    },
    handleDelete: function (params) {
      this.$Modal.confirm({
        title: "提示",
        content: `确定要删除用户[${params.row.code}]吗?`,
        onOk: async () => {
          this.$axios.post(`//${this.domains.trans}/station/auth/DeleteUser`, { id: params.row.id }).then(res => {
            if (res.code == 0) {
              this.getList();
            }
          });
        }
      });
    },
    getList: function () {
      this.$axios.post(`//${this.domains.trans}/station/auth/QueryUser`, { id: this.item.id }).then(res => {
        if (res.code == 0) {
          this.$set(this, 'list', res.data);
          this.$set(this, 'listData', res.data);
        }
      });
    },
    itemSaved: function () {
      this.getList();
    },
    cancel() {
      this.showModal = false;
    },
  }
}
</script>
<style scoped>
.form-item-group {
  border: solid 1px rgba(129, 124, 124, 0.377);
  border-radius: 12px;
  margin-bottom: 15px;
}
.group-header {
  /* border: solid 1px blue; */
  background-color: rgba(221, 216, 216, 0.808);
  margin-bottom: 15px;
  height: 35px;
  border-radius: 12px 12px 0 0;
  line-height: 35px;
  font-weight: bold;
  padding: 0 10px;
}
.edit-area {
  /* border: solid 1px blue; */
  width: 100%;
  height: 600px;
  display: flex;
}
.form-area {
  width: 410px;
  flex: none;
}
.fun-area {
  width: 400px;
  flex: auto;
  margin-left: 20px;
  border: solid 1px rgba(216, 199, 199, 0.438);
  border-radius: 6px;
  overflow: auto;
  position: relative;
}
.chk-all {
  position: absolute;
  top: 10px;
  right: 10px;
}
.logo-upload {
  width: 410px;
  height: 114px;
  border: solid 1px gray;
  cursor: pointer;
}
.logo-upload input {
  visibility: hidden;
}
.logo-upload img {
  width: 410px;
  height: 114px;
}
.fun-list {
  display: flex;
  /* list-style: none; */
  flex-direction: column;
  margin: 8px 15px;
}
.chk-list {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
}
li {
  margin: 0 5px;
}
</style>