<template>
  <div class="list-common-container">
    <div class="alarm-data-area" ref="table">
      <vxe-table border resizable show-overflow show-header-overflow keep-source ref="chkTable" :key="dataRefresh" @checkbox-change="selectChangeEvent" :data="devices" :height="tabHeight" :row-config="{isHover: true}" :checkbox-config="{checkField: 'checked',}" :merge-cells="mergeData">
        <vxe-column width="50" type="checkbox" field="checkbox" fixed="left"></vxe-column>
        <vxe-column field="name" title="名称" fixed="left" width="250">
          <template #default="{ row }">
            {{getSmallTypeName(row.content.stid)}}
          </template>
        </vxe-column>
        <vxe-column field="deviceTypeName" title="协议类型" width="250">
          <template #default="{ row }">
          {{row.deviceTypeName}}
          </template>
        </vxe-column>
        <vxe-column field="ch" title="通信ID" width="180">
          <template #default="{ row }">
          {{deviceCodeName(row.code)}}
          </template>
        </vxe-column>
        <vxe-column field="online" title="光控监测" width="150">
          <template #default="{ row }">
            {{!row.content.enlux ? '未启用':`采集间隔时间${row.content.tlux}秒`}}
          </template>
        </vxe-column>
        <!-- <vxe-column field="fireTime" title="最后通信时间" width="180">
          <template #default="{ row }">
            {{row.fireTime}}
          </template>
        </vxe-column> -->
        <vxe-column field="result" title="执行结果" :show-overflow-tooltip="true">
          <template #default="{ row }">
            {{getCmdStatus(row)}}
          </template>
        </vxe-column>
      </vxe-table>
    </div>
    <ModalArgEdit v-model="showArgModal" :command="cmdItem" :areas="areas" @saved="argsSaved" />
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import ModalArgEdit from '../args/ModalArgEdit'
import exportXlsx from '@/components/xlsx/exportXlsx'
export default {
  name: 'LightControlListCommon',
  components: {
    ModalArgEdit,
  },
  props: {
    type: {
      type: String,
      default: 'common'
    },
  },
  data() {
    return {
      timeTables: [],
      filter: {
        stationId: 0, //当前站点ID
        group: 0, //所选分组ID
        type: 0, //单灯类型
        timeType: -1, //时间表类型
        timeId: 0, //时间表ID
        code: '', //单灯通信ID
        name: '', //单灯名称
      },
      tabHeight: 600,
      clearOldLog: this.clearOldLogs,
      showArgModal: false,
      showPlanModal: false,
      showCreateModal: false,
      cmdItem: { item: {}, other: {}, args: {} },
      groups: [],
      list: [],
      showTransModal: false,
      lights: [],
      dataRefresh: 0,
      mergeData: [],
      items: [],
      areas: [],
      accounts: [],
      devices: [],
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes']),
    ...mapState('auth', ['config']),
    ...mapState('cmd', ['cmdResultRefresh', 'commands', 'windowOnResize', 'clearOldLogs', 'lightRealDataRefresh']),
    ...mapState('common', ['deviceTypes', 'productCodes', 'connectTypes', 'newLightTypes', 'leakagAlarmeStatus']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
    ...mapGetters('common', ['getBigTypeName', 'getSmallTypeName', 'getSmallTypes']),
    colCanShow: function(){
      return function(code){
        return this.config.lightShowColumns.indexOf(code) != -1;
      };
    },
  },
  watch: {
    clearOldLog() {
      this.$store.commit('cmd/setOldLogStatus', this.clearOldLog)
    },
    lightRealDataRefresh() {
      this.refreshLightData();
    },
    windowOnResize() {
      this.setTableHeight();
    },
    cmdResultRefresh() {
      for (let c of this.commands) {
        let id = parseInt(c.itemId);
        let its = this.devices.filter(p => p.id == id);
        if (its.length == 0) continue;
        for (let it of its) {
          this.$set(it, 'status', c.status)
          this.$set(it, 'result', c.result)
          this.$set(it, 'cmd', c.code)
          this.$set(it, 'cmdContent', c.content)
          this.$set(it, 'args', c.args)
          // if (c.status == 9) {
          //   this.$set(it, 'online', true);
          // }
        }
      }
    },
  },
  mounted: function () {
    this.clearOldLog = this.clearOldLogs;
    setTimeout(this.setTableHeight, 100);
    this.getAccounts();
    this.getList();
  },
  destroyed: function () {
  },
  methods: {
    getAccounts: async function(){
      let res = await this.$axios.post(`//${this.domains.trans}/station/account/GetAccounts`, {type: 4});
      if(res.code !== 0){
        return;
      }
      this.$set(this, "accounts", res.data);
    },
    deviceCodeName: function(code){
      if(code.indexOf('ZXKL:') == -1)return code;
      let id = code.substr(5);
      let accts = this.accounts.filter(p => p.id == id);
      if(accts.length == 0)return '-';
      return `克拉账号：${accts[0].name}`;
    },
    getList: function(){
      this.$axios.post(`//${this.domains.trans}/station/light/QueryGateWay`, {}).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          res.data.map(p => {
            p.cmdContent = {};
            p.cmdId = 0;
            p.status = 0;
            p.result = '';
          });
          this.$set(this, 'devices', res.data);
        }
      });
    },
    dealCmd: function (params) {
      let chks = this.$refs.chkTable.getCheckboxRecords();
      if (chks.length == 0) {
        this.$Message.warning("请选择要处理的单灯网关");
        return;
      }
      if(params.args.item.code == 'getCmdCount'){
        this.getCmdCount();
        return;
      }
      if(params.args.item.code == 'clearCmds'){
        this.clearCmds();
        return;
      }
      if (params.args.item.needPswd || params.args.item.needArgs) {
        this.cmdItem = { code: params.args.item.code, name: params.args.item.name, pswd: params.args.item.needPswd, args: {} };
        this.showArgModal = true;
      } else {
        if (this.clearOldLog) {
          for (let item of this.list) {
            item.result = '';
            item.status = 0;
          }
        }
        let ids = chks.map(p => p.id);
        this.$store.dispatch('cmd/sendGatewayCommand', {ids, code: params.args.item.code, name: params.args.item.name, args: {}}).then(res => {
          if(res.code == 0){
            this.$Message.info({
              title: '指令',
              content: '指令已发送',
            });
          }
        });
      }
    },
    selectChangeEvent({ rowIndex }) {
      this.$refs.chkTable.reloadRow(this.list, null, rowIndex)
    },
    refreshLightData: function () {
      // console.log('light data refresh 1')
      for (let l of this.list) {
        let lls = this.lights.filter(p => p.id == l.lid);
        if (lls.length == 0) continue;
        // console.log('light data refresh', lls)
        if (l.fireTime >= lls[0].fireTime || lls[0].fireTime <= 0) continue;
        l.c = lls[0].lastData[`c${l.ch}`];
        l.cl = lls[0].lastData[`cl${l.ch}`];
        l.ct = lls[0].lastData[`ct${l.ch}`];
        l.oc = lls[0].lastData[`oc${l.ch}`];
        l.op = lls[0].lastData[`op${l.ch}`];
        l.p = lls[0].lastData[`p${l.ch}`];
        l.po = lls[0].lastData.po;
        l.mode = lls[0].lastData.mode;
        l.q = lls[0].lastData[`q${l.ch}`];
        l.start = `${lls[0].lastData.sh}:${lls[0].lastData.sm}`;
        l.tc = lls[0].lastData.tc;
        l.tm = lls[0].lastData.tm;
        l.u = lls[0].lastData.u.toFixed(2);
        l.w = lls[0].lastData[`w${l.ch}`];
        l.gx = lls[0].lastData[`gx`];
        l.gy = lls[0].lastData[`gy`];
        l.gz = lls[0].lastData[`gz`];
        l.fireTime = new Date(lls[0].fireTime).format('yyyy-MM-dd HH:mm:ss');
        l.lampType = this.newLightTypes[lls[0].lastData[`type`]];
        l.timeName1 = lls[0].timeName1;
        l.timeName2 = lls[0].timeName2;
        l.areaName = lls[0].areaName;
      }
    },
    getCmdStatus: function (cmd) {
      switch (cmd.status) {
        case 0: return `${cmd.result}`;
        case 1: return '等待回复';
        case 2: return '指令超时';
        case 3: return `执行失败：${cmd.result}`;
        case 4: return `执行中：${cmd.cmdContent.pkg} / ${cmd.cmdContent.total}`;
        case 5: return `失败重试：${cmd.result}`;
        case 9:
              console.log('get cmd result', cmd)
          switch (cmd.cmd) {
            case 'getclock': return `执行成功：${cmd.cmdContent.nowTime}`;
            case 'getWhite': {
              if(cmd.deviceType != 'st6038'){
                return `执行成功：${cmd.cmdContent.list.join(', ')}`;
              }
              let cc = [];
              for(let item of cmd.cmdContent.list){
                cc.push(`${item.id}:${item.no}`);
              }
              return `执行成功：${cc.join(', ')}`;
            }
            case 'getLoraWork': return `当前信道：${cmd.cmdContent.channel}，当前速率：${cmd.cmdContent.speed}`;
            case 'getGroup': return `执行成功：当前分组号 ${cmd.cmdContent.no}`;
            case 'getInfo': return `硬件版本：${cmd.cmdContent.hardware}，软件版本：${cmd.cmdContent.software}，生产厂商：${cmd.cmdContent.manufacture}`;
            case 'getSimInfo': return `ICCID：${cmd.cmdContent.iccid}，IMEI：${cmd.cmdContent.imei}，RSSI：${cmd.cmdContent.rssi}`;
            case 'getDayPlan1': {
              let str = '';
              for (let n = 0; n < 6; n++) {
                let item = cmd.cmdContent[n];
                if (!item) continue;
                str += `时段${n + 1}，时间：${item.min}分，`;
                for (let c = 0; c < 4; c++) {
                  let bright = item[`ch${c + 1}`];
                  let color = item[`c${c + 1}`];
                  if (bright != undefined) {
                    str += `${c + 1}通道亮度：${bright}，`;
                  }
                  if (color != undefined) {
                    str += `${c + 1}通道色温：${color}，`;
                  }
                }
              }
              return str;
            }
            case 'getDayPlan2': {
              let str = '';
              for (let n = 0; n < 6; n++) {
                let item = cmd.cmdContent[n];
                if (!item) continue;
                str += `时段${n + 1}，时间：${item.min}，`;
                for (let c = 0; c < 4; c++) {
                  let bright = item[`ch${c + 1}`];
                  let color = item[`c${c + 1}`];
                  if (bright != undefined) {
                    str += `${c + 1}通道亮度：${bright}，`;
                  }
                  if (color != undefined) {
                    str += `${c + 1}通道色温：${color}，`;
                  }
                }
              }
              return str;
            }
            case 'getPlanType': {
              switch (cmd.cmdContent.type) {
                case 0: return '计时日表';
                case 1: return '准时日表';
                case 2: return '光控模式';
                case 255: return '日表无效';
                default: return '计时日表';
              }
            }
            case 'getNetwork': return `IP:${cmd.cmdContent.host}, 端口:${cmd.cmdContent.port}, 心跳间隔:${cmd.cmdContent.heart}秒`
            case 'getLuxOpenValue': return `光感值:${cmd.cmdContent.lux}`
            case 'getAlarmStatus': {
              return `电压报警:${cmd.cmdContent.voltage ? '启用' : '禁用'}, 电流报警：${cmd.cmdContent.current ? '启用' : '禁用'}, 温度报警：${cmd.cmdContent.temp ? '启用' : '禁用'}, 漏电报警：${cmd.cmdContent.leakage ? '启用' : '禁用'}`
            }
            case 'getLeakageAlarmLevel': {
              return `一级：${cmd.cmdContent.l1}; 二级：${cmd.cmdContent.l2}; 三级：${cmd.cmdContent.l3}`;
            }
            case 'getLeakageAlarmEnable': {
              return `状态：${cmd.cmdContent.enable ? '启用' : '禁用'}; 检查时间：${cmd.cmdContent.check}; 上报次数：${cmd.cmdContent.count}`;
            }
            case 'getLeakageAlarmStatus': {
              return `状态：${this.leakagAlarmeStatus[cmd.cmdContent.level]}; 漏电值：${cmd.cmdContent.val};`;
            }
            case 'getUnionParam': {
              return `
              <p>灯1延时时间：${cmd.cmdContent.keep1}秒</p>
              <p>灯2延时时间：${cmd.cmdContent.keep2}秒</p>
              <p>是否启用感应启用：${cmd.cmdContent.sensor == 1 ? '有效' : '无效'}；</p>
              <p>是否发送433信号：${cmd.cmdContent.txen > 10 ? '有效' : '无效'}；</p>
              <p>重发周期：${cmd.cmdContent.period}秒</p>
              <p>联动编号：${cmd.cmdContent.rfsn}；</p>
              <p>433发送功率：${cmd.cmdContent.txpower}；</p>
              <p>433应用频道：${cmd.cmdContent.channel}；</p>
              <p>响应范围：${cmd.cmdContent.range}；</p>
              <p>是否接收433信号：${cmd.cmdContent.rxen == 1 ? '接收' : '不接收'}；</p>
              <p>联动方式：${cmd.cmdContent.mode == 1 ? '联动控制' : '灯杆互联'}；</p>
              <p>重发次数：${cmd.cmdContent.repeat}；</p>
              `
            }
            default: return cmd.result ? cmd.result : `执行成功`;

          }
      }
    },
    exportResult: function () {
      let cmdData = [];
      this.list.map(item => {
        cmdData.push({
          name: item.name,
          sname: item.sname,
          code: item.code,
          result: this.getCmdStatus(item)
        })
      });
      let tabHead = {
        name: '通道名称',
        sname: '站点名称',
        code: '通信ID',
        result: '执行结果'
      }
      exportXlsx(cmdData, tabHead, "指令执行结果");
    },
    setTableHeight: function () {
      this.tabHeight = this.$refs.table.clientHeight - 5;
    },
    sendFailed: function () {
      let chks = this.$refs.chkTable.getCheckboxRecords();
      let sids = [];
      for (let d of this.list) {
        if ((d.status == 2 || d.status == 3) && chks.filter(p => p.id == d.id).length > 0) {
          this.$set(d, 'status', 0)
          this.$set(d, 'result', '准备重发')
          sids.push(d.id);
          // this.$set(d, 'cmd', c.code)
          // this.$set(d, 'content', c.content)
        } else if (this.clearOldLog) {
          this.$set(d, 'status', 0)
          this.$set(d, 'result', '')
        }
      }
      setTimeout(() => {
        this.$store.dispatch('cmd/sendFailed', sids).then(res => {
          if (res.code == 0) {
            for (let c of this.commands) {
              let its = this.list.filter(p => p.id == c.id);
              if (its.length == 0) continue;
              this.$set(its[0], 'status', c.status)
              this.$set(its[0], 'result', c.result)
              this.$set(its[0], 'cmd', c.code)
              this.$set(its[0], 'content', c.content)
            }
          }
        });
      }, 500)
    },
    getCmdCount: async function () {
      let chks = this.$refs.chkTable.getCheckboxRecords();
      if (chks.length == 0) {
        this.$Message.warning("请选择要处理的单灯网关");
        return;
      }
      let ids = chks.map(p => p.deviceId);
      for(let item of chks){
        item.status = 0;
        item.cmd = 'QueenCount';
        item.result = `开始查询队列指令数量`;
      }
      this.$axios.post(`//${this.domains.trans}/station/command/QueenCount`, { ids }).then(res => {
        if (res.code !== 0) {
          //
        } else {
          for(let item of chks){
            if(res.data[item.deviceId]){
              item.status = 9;
              item.result = `当前队列指令数量： ${res.data[item.deviceId]}`;
            }
          }
        }
      })
    },
    clearCmds: async function () {
      let chks = this.$refs.chkTable.getCheckboxRecords();
      if (chks.length == 0) {
        this.$Message.warning("请选择要处理的单灯网关");
        return;
      }
      this.$Modal.confirm({
        title: "提示",
        content: `确定要清除所选网关的指令队列吗?`,
        onOk: async () => {
          let ids = chks.map(p => p.deviceId);
          for(let item of chks){
            item.status = 0;
            item.cmd = 'QueenClear';
            item.result = `开始清除队列指令`;
          }
          this.$axios.post(`//${this.domains.trans}/station/command/QueenClear`, { ids }).then(res => {
            if (res.code !== 0) {
              // item.status = 3;
              // item.result = `执行失败： ${res.data}`;
            } else {
              for(let item of chks){
                if(res.data[item.deviceId]){
                  item.status = 9;
                  item.result = `${res.data[item.deviceId]}`;
                }
              }
            }
          })
        },
      });
    },
    argsSaved: function (params) {
      if (this.clearOldLog) {
        for (let item of this.list) {
          item.result = '';
          item.status = 0;
        }
      }
      let chks = this.$refs.chkTable.getCheckboxRecords();
      let ids = chks.map(p => p.id);
      let ns = chks.map(p => p.name);
      let args = this.clone(params.args);
      let code = params.code;
      if (params.code == 'openSingle' || params.code == 'closeSingle') {
        code = 'handSingle';
      } else if (params.code == 'update') {
        args.flag = 1;
      }
      if (params.code == 'update') {
        this.$Modal.confirm({
          title: "提示",
          content: `确定要升级设备[${ns}]吗?`,
          onOk: async () => {
            // this.$store.dispatch('cmd/sendCommands', cmds);
            this.$store.dispatch('cmd/sendGatewayCommand', {ids, code, name: params.name, args})
          },
        });
      } else {
        // this.$store.dispatch('cmd/sendCommands', cmds);
        this.$store.dispatch('cmd/sendGatewayCommand', {ids, code, name: params.name, args})
      }
    },
  }
}
</script>
<style scoped>
.list-common-container {
  /* border: solid 1px red; */
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: stretch;
}
.list-common-query {
  /* border: solid 1px red; */
  height: 40px;
  flex: none;
  background-color: white;
  margin: 3px 1px;
  display: flex;
  align-items: center;
  padding: 10px;
  white-space: nowrap;
}
.transfer-flex {
  width: 50%;
}
.btns-flex {
  width: 50%;
  display: flex;
  flex-wrap: wrap;
}
.alarm-data-area {
  /* border: solid 1px red; */
  height: 100px;
  flex: auto;
  border-radius: 6px;
}
.tab-active {
  border: solid 1px rgb(135, 159, 224);
  background-color: rgb(135, 159, 224);
  color: white;
}
.station-alarm-tabcontent {
  height: 500px;
  flex: auto;
  /* border: solid 1px red; */
}
.alarm-data-area >>> .plTableBox .el-table tr .umy-table-beyond {
  white-space: break-spaces !important;
  color: #324252;
}
/* .box-body{
  height: 0;
  flex: auto;
  margin-top: 5px;
  border: solid 1px red;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  position: relative;
}
.box-body::-webkit-scrollbar {
  display: none;
} */
</style>