<template>
<Modal v-model="showModal" :mask-closable="false" width="1500px">
  <div slot="header">批量导入单灯信息</div>
  <div class="import-container">
    <Form ref="form" :label-width="100" class="import-form">
      <FormItem prop="areaId" label="所在分区">
        <Select v-model="form.groupId">
          <Option v-for="(s, idx) in groups" :key="idx" :value="s.id">{{s.name}}</Option>
        </Select>
      </FormItem>
      <FormItem prop="monitorId" label="关联网关">
        <Select v-model="form.monitorId">
          <Option :value="0">不需要网关</Option>
          <Option v-for="(dev, didx) in gates" :key="didx" :value="dev.id">[{{dev.code}}]{{dev.name}}</Option>
        </Select>
      </FormItem>
      <FormItem prop="type" label="单灯类型">
        <Select v-model="form.type">
          <template v-for="(v, k) in newLightTypes">
            <Option :value="parseInt(k)" :key="k">{{v}}</Option>
          </template>
        </Select>
      </FormItem>
      <FormItem prop="areaId" label="所在分组">
        <Select v-model="form.areaId">
          <template v-for="(s, idx) in areas">
            <Option :value="s.id" :key="idx">{{s.name}}[{{s.code}}]</Option>
          </template>
        </Select>
      </FormItem>
      <FormItem prop="timeId1" label="计时日表">
        <Select v-model="form.timeId1">
          <template v-for="(item, idx) in timeTables">
            <Option v-if="item.type == '计时日表'" :value="item.id" :key="idx">[{{item.type}}]{{item.name}}</Option>
          </template>
        </Select>
      </FormItem>
      <FormItem prop="timeId2" label="准时日表">
        <Select v-model="form.timeId2">
          <template v-for="(item, idx) in timeTables">
            <Option v-if="item.type == '准时日表'" :value="item.id" :key="idx">[{{item.type}}]{{item.name}}</Option>
          </template>
        </Select>
      </FormItem>
      <FormItem prop="mode" label="控制方式">
        <Select v-model="form.mode">
          <Option :value="0">计时日表</Option>
          <Option :value="1">准时日表</Option>
          <!-- <Option :value="2">光控模式</Option> -->
        </Select>
      </FormItem>
      <FormItem prop="en1" label="启用主灯">
        <div style="display: flex;align-items:center">
          <i-switch v-model="form.en1" style="width: 44px; flex:none; margin-right:10px" />
          <Input type="number" v-model="form.pr1"><span slot="prepend">额定功率</span><span slot="append">W</span></Input>
          <Input type="number" v-model="form.lc1"><span slot="prepend">灯具数量</span><span slot="append">个</span></Input>
        </div>
      </FormItem>
      <FormItem prop="en2" label="启用辅灯1">
        <div style="display: flex;align-items:center">
          <i-switch v-model="form.en2" style="width: 44px; flex:none; margin-right:10px" />
          <Input type="number" v-model="form.pr2"><span slot="prepend">额定功率</span><span slot="append">W</span></Input>
          <Input type="number" v-model="form.lc2"><span slot="prepend">灯具数量</span><span slot="append">个</span></Input>
        </div>
      </FormItem>
      <FormItem prop="en3" label="启用辅灯2">
        <div style="display: flex;align-items:center">
          <i-switch v-model="form.en3" style="width: 44px; flex:none; margin-right:10px" />
          <Input type="number" v-model="form.pr3"><span slot="prepend">额定功率</span><span slot="append">W</span></Input>
          <Input type="number" v-model="form.lc3"><span slot="prepend">灯具数量</span><span slot="append">个</span></Input>
        </div>
      </FormItem>
      <FormItem prop="en4" label="启用辅灯3">
        <div style="display: flex;align-items:center">
          <i-switch v-model="form.en4" style="width: 44px; flex:none; margin-right:10px" />
          <Input type="number" v-model="form.pr4"><span slot="prepend">额定功率</span><span slot="append">W</span></Input>
          <Input type="number" v-model="form.lc4"><span slot="prepend">灯具数量</span><span slot="append">个</span></Input>
        </div>
      </FormItem>
      <FormItem prop="checkType" label="识别单灯类型">
        <div style="display: flex;align-items:center; height: 34px; border: solid 0 red;">
          <i-switch v-model="form.checkType" style="width: 44px; flex:none; margin-right:10px" />
          <Select v-if="!form.checkType" ref="lampType" v-model="form.lampType" number>
            <template v-for="(item, idx) in smallTypes">
              <Option v-if="item.type == 3" :value="item.code" :key="idx">{{item.name}}</Option>
            </template>
          </Select>
        </div>
      </FormItem>
      <FormItem prop="lng" label="选择文件">
        <div style="display: flex">
          <div class="logo-upload">
            <input ref="fileInput" type="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" :disabled="loading" />
          </div>
        </div>
      </FormItem>
    <FormItem prop="lng" :label-width="0">
      <div style="display: flex;justify-content: center;">
        <a :href="`//${this.domains.trans}/station/light/LightImportTemplate`" style="margin-right: 0;background-color: #2d8cf0;color:#fff;height:40px;width:80px;display:inline-block;border-radius:6px;text-align:center;line-height:40px;">数据模板</a>
        <Button type="info" style="margin-left: 8px;height: 40px;" :loading="loading" @click="loadData">加载数据</Button>
        <Button type="success" style="margin-left: 8px;height: 40px;" :loading="loading" @click="ok">导入数据</Button>
      </div>
    </FormItem>
  </Form>
  <div class="import-data" ref="table">
    <u-table :data="excelData" use-virtual style="width: 100%;" row-key="code" border stripe :height="800" default-expand-all>
      <u-table-column type="index" label="序号" width="50"></u-table-column>
      <u-table-column prop="code" label="序列号" width="100">
        <template slot-scope="params">
          {{params.row.code}}
        </template>
      </u-table-column>
      <u-table-column prop="code" label="灯控名称" width="100">
        <template slot-scope="params">
          {{params.row.name}}
        </template>
      </u-table-column>
      <u-table-column prop="code" label="灯杆名称" width="100">
        <template slot-scope="params">
          {{params.row.pole}}
        </template>
      </u-table-column>
      <u-table-column prop="code" label="上行通道" width="80">
        <template slot-scope="params">
          {{params.row.channel}}
        </template>
      </u-table-column>
      <u-table-column label="导入结果">
        <template slot-scope="params">
          {{params.row.result}}
        </template>
      </u-table-column>
    </u-table>
  </div>
</div>
  <div slot="footer">
    <Button style="margin-right: 8px" @click="cancel">关闭</Button>
  </div>
  <!-- <ModalSelectGroup v-model="showSelectModal" @newGroupSelected="newGroupSelected" /> -->
</Modal>
</template>
<script>
import XLSX from 'xlsx'
import { mapState } from 'vuex'
export default {
  name: 'ModalLampImportNew',
  components:{
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default(){ return {};}
    },
    groups: {
      type: Array,
      default(){ return [];}
    },
    areas: {
      type: Array,
      default(){ return [];}
    },
    gates: {
      type: Array,
      default(){ return [];}
    },
  },
  data () {
    return {
      showModal: this.value,
      // showSelectModal: false,
      loading: false,
      isAdd: false,
      form: {
        checkType: true,
        lampType: 0,
        groupId: 0,
        // connectType: 0,
        type: 101,
        monitorId: 0,
        en1: true,
        pr1: 100,
        lc1: 1,
        en2: false,
        pr2: 100,
        lc2: 1,
        en3: false,
        pr3: 100,
        lc3: 1,
        en4: false,
        pr4: 100,
        lc4: 1,
        mode: 0,
        timeId1: 0,
        timeId2: 0,
        areaId: 0,
        items: [],
      },
      excelData: [],
      bigs: [],
      types: [],
      monitors: [],
      devices: [],
      timeTables: [],
    }
  },
  computed: {
    ...mapState('auth', ['appType']),
    ...mapState('cmd', ['windowOnResize']),
    ...mapState('common', ['powerBoxAlarmTypes', 'productCodes', 'connectTypes', 'deviceTypes', 'newLightTypes', 'smallTypes']),
    ...mapState('group', ['selectedDevices', 'selectedNode', 'selectedGroups']),
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if(newVal){
        // console.log('modal level edit mounted', this.item)
        this.isAdd = this.item.isAdd;
        this.form.items.splice(0, this.form.items.length);
        this.excelData.splice(0, this.excelData.length);
        // console.log('modal level edit mounted', this.form.content, this.item.data.content)
      }
    },
    showModal(val){
      this.$emit('showChanged', val);
    },
    // 'form.stationId'(){
    //   this.form.paramId = 0;
    // },
  },
  mounted: function(){
    this.getUseablePlan();
    // console.log('modal level edit mounted', this.alarmTypes, this.alarmGroupList)
  },
  methods: {
    getUseablePlan: function(){
      this.$axios.post(`//${this.domains.trans}/station/plan/QueryLightPlan`, {}).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          this.$set(this, 'timeTables', res.data);
        }
      });
    },
    setTableHeight: function(){
      this.tabHeight = this.$refs.table.clientHeight;
    },
    loadData: function(){
      if(this.$refs.fileInput.files.length == 0){
        this.$Message.warning('请选择要导入的文件');
        return;
      }
      var reader = new FileReader(); //实例化文件读取对象
      let that = this;
      reader.onload = function (e) {
        var wb = XLSX.read(e.target.result, { type: "binary" });
        var wb_sheetname=wb.SheetNames[0];
        const wb_sheet = wb.Sheets[wb_sheetname]
        let fileData = XLSX.utils.sheet_to_json(wb_sheet);
        let result = [];
        for(let d of fileData){
          // console.log('get excel data', d)
          let code = `${d['产品序列号']}`;
          let name = `${d['灯控名称']}`;
          let pole = `${d['灯杆名称']}`;
          let ch = `${d['上行通道']}`;
          if(code.indexOf("ID")!=-1){
            code = code.substring(2);
          }
          if(result.filter(p => p.code == code || p.name == name).length > 0){
            that.$Modal.warning({
              title: '数据有误',
              content: `ID${code}或灯控名称${name}重复`
            });
            return;
          }
          result.push({code:code, name: name, pole: pole, channel: ch})
        }
        that.excelData = result;
      }
      reader.readAsBinaryString(this.$refs.fileInput.files[0]);
    },
    checkData: function(){
      if(this.form.groupId <= 0){
        this.$Message.warning(`不能选择根分区`);
        return false;
      }
      if(this.form.areaId <= 0){
        this.$Message.warning(`请选择单灯分组`);
        return false;
      }
      if(this.form.timeId1 <= 0){
        this.$Message.warning(`请选择计时日表`);
        return false;
      }
      if(this.form.timeId2 <= 0){
        this.$Message.warning(`请选择准时日表`);
        return false;
      }
      if(!this.form.en1 && !this.form.en2 && !this.form.en3 && !this.form.en4){
        this.$Message.warning(`必须至少启用一个灯`);
        return false;
      }
      if(!this.form.checkType && this.form.lampType <= 0){
        this.$Message.warning(`请指定单灯类型`);
        return false;
      }
      if(this.excelData.length == 0){
        this.$Message.warning(`请先加载单灯导入文件`);
        return false;
      }
      let names = [], codes = [];
      for(let d of this.excelData){
        if(d.name == ''){
          this.$Message.warning(`灯控名称[${d.name}]重复`);
          return false;
        }
        names.push(d.name);
        if(codes.filter(p => p == d.code).length > 0){
          this.$Message.warning(`控制器序列号[${d.code}]重复`);
          return false;
        }
        codes.push(d.code);
      }
      return true;
    },
    ok: async function () {
      let result = this.checkData();
      if(!result){
        return;
      }
      this.form.items = [];
      for(let item of this.excelData){
        this.form.items.push(item);
      }
      this.$axios.post(`//${this.domains.trans}/station/light/ImportLight`, this.form).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
          return;
        }else{
          let len = this.excelData.length;
          this.$emit('saved', {});
          for(let i = 0; i < this.excelData.length; i++){
            let d = this.excelData[i];
            let result = res.data.filter(p => p.code == d.code);
            if(result.length == 0){
              this.excelData.splice(i, 1);
              i--;
            }else{
              // d.result = result[0].name;
              this.$set(d, 'result', result[0].name)
            }
          }
          let failCount = res.data.filter(p => !p.success).length
          if(failCount == 0){
            this.$Modal.info({
              title: '导入完成',
              content: `全部导入成功`
            })
          }else if(failCount == len){
            this.$Modal.info({
              title: '导入完成',
              content: `全部导入失败`
            })
          }else{
            this.$Modal.info({
              title: '导入完成',
              content: `失败数量：${failCount}`
            })
          }
        }
      });
    },
    cancel () {
      this.showModal = false;
      this.$emit('saved', {});
    },
  }
}
</script>
<style scoped>
.form-item-group{
  border: solid 1px rgba(129, 124, 124, 0.377);
  border-radius: 12px;
  margin-bottom: 15px;
}
.group-header{
  /* border: solid 1px blue; */
  background-color: rgba(221, 216, 216, 0.808);
  margin-bottom: 15px;
  height: 35px;
  border-radius: 12px 12px 0 0;
  line-height: 35px;
  font-weight: bold;
  padding: 0 10px;
}

.import-container{
  /* border: solid 1px red; */
  /* height: 100%; */
  display: flex;
}
.import-form{
  width: 500px;
  flex: none;
}
.import-data{
  width: 500px;
  flex: auto;
  margin-left: 35px;
}
.logo-upload{
  width: 100%;
  height:40px;
  border:solid 1px #dcdee2;
  cursor: pointer;
}
.logo-upload input{
  /* visibility: hidden; */
  width: 100%;
}
.logo-upload button{
  /* visibility: hidden; */
  width: 100%;
  height: 40px;
}
</style>