<template>
  <div class="auth-container">
    <div class="auth-search">
      关键字：
      <Input v-model="formItem.content" placeholder="请输入操作内容" style="width: 100px;margin-right:10px"></Input>
      操作人员：
      <Input v-model="formItem.userName" placeholder="请输入操作人员" style="width: 100px;margin-right:10px"></Input>
      IP：
      <Input v-model="formItem.ip" placeholder="请输入IP" style="width: 100px;margin-right:10px"></Input>
      开始时间：
      <DatePicker v-model="formItem.start" format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="开始时间" style="width: 175px;margin-right:10px"></DatePicker>
      结束时间：
      <DatePicker v-model="formItem.end" format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="结束时间" style="width: 175px;margin-right:10px"></DatePicker>
      <Button type="primary" @click="ajaxList(true)">搜索</Button>
    </div>
    <div class="auth-tab" ref="xTable1">
      <vxe-table border show-overflow show-header-overflow :data="list" :height="tabHeight" :row-config="{isHover: true}" :key="tabHeight">
        <vxe-column type="seq" width="60" title="序号"></vxe-column>
        <vxe-column field="fireTime" title="时间" width="150"></vxe-column>
        <vxe-column field="userCode" title="操作人员" width="150">
          <template #default="{ row }">
            {{row.userCode}} --{{row.userName}}
          </template>
        </vxe-column>
        <vxe-column field="content" title="内容"></vxe-column>
        <vxe-column field="ip" title="登录IP" width="150"></vxe-column>
      </vxe-table>
      <p>
        <vxe-pager background :current-page.sync="formItem.index" :page-size.sync="formItem.size" :total="totalResult" :layouts="['PrevJump', 'PrevPage', 'JumpNumber', 'NextPage', 'NextJump', 'Sizes', 'FullJump', 'Total']" @page-change="formPage">
        </vxe-pager>
      </p>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'safeSystemIndex',
  components: {
  },
  data() {
    return {
      list: [],
      tabHeight: 300,
      totalResult: 0,
      formItem: {
        "index": 1,
        "size": 15,
        "ip": "",
        "content": "",
        "userName": "",
        "start": "2022-11-07 00:00:00",
        "end": "2022-11-09 00:00:00"
      }
    }
  },
  computed: {
    ...mapState('cmd', ['windowOnResize', 'cmds']),
  },
  watch: {
    windowOnResize() {
      this.setTableHeight();
    },
  },
  mounted: function () {
    let now1 = new Date();
    this.formItem.end = now1.format('yyyy-MM-dd HH:mm:ss');
    now1.setDate(now1.getDate() - 300);
    this.formItem.start = now1.format('yyyy-MM-dd HH:mm:ss');
    setTimeout(this.setTableHeight, 200);
    this.ajaxList(true);
  },
  methods: {
    setTableHeight: function () {
      this.tabHeight = this.$refs.xTable1.clientHeight - 50;
    },
    formPage({ currentPage, pageSize }) {
      this.formItem.index = currentPage;
      this.formItem.size = pageSize;
      this.ajaxList(false)
    },
    ajaxList(is) {
      if (is) { this.formItem.index = 1 }
      this.formItem.end=  new Date(this.formItem.end).format('yyyy-MM-dd HH:mm:ss')
      this.formItem.start=  new Date(this.formItem.start).format('yyyy-MM-dd HH:mm:ss')
      this.$axios.post(`//${this.domains.trans}/sys/log/QueryOperation`, this.formItem).then(res => {
        if (res.code == 0) {
          this.$set(this, 'list', res.data.list);
          this.$set(this, 'totalResult', res.data.count);
        }
      });
    },
  }
}
</script>
<style scoped>
.auth-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: stretch;
}
.auth-search{
  /* border: solid 1px red; */
  display: flex;
  align-items: center;
  height: 50px;
  flex: none;
}
.auth-tab {
  height: 100px;
  flex: auto;
  border-radius: 6px;
}
</style>
