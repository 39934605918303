<template>
  <div style="display: flex;">
    <div class="info-left-box">
      <div class="info-drop">
        <div class="info-drop2"></div>
      </div>
      <div class="info-thread1"></div>
      <div class="info-thread2"></div>
      <div class="info-thread3"></div>
    </div>
    <div class="info-rig-box">
      <div class="info-horn info-horn1"></div>
      <div class="info-horn info-horn2"></div>
      <div class="info-horn info-horn3"></div>
      <div class="info-horn info-horn4"></div>
      <div class="info-title">
        <div class="info-title-box">
          <img :src="img.lightMainLocation" alt="" class="info-title-fixed">{{showInfo.name}}
          --分组:{{showInfo.groupName}}
        </div>
        <img :src="img.light.carat.gis.closeIcon" alt="" class="info-title-close" @click="infoClose()">
      </div>
      <div class="info-con">
        <div class="info-icon">
          <img :src="imgStatus(showInfo)" alt="">
          <p class="info-icon-name" :title="showInfo.poleName">{{showInfo.poleName}}</p>
        </div>
        <div class="info-con-val">
          <table>
            <tr>
              <td><span class="info-val-name">状态</span></td>
              <td><span class="info-val">{{wordStatus(showInfo)}}</span></td>
              <td><span class="info-val-name">设备通信ID</span></td>
              <td><span class="info-val">{{showInfo.code}}</span></td>
            </tr>
            <tr>
              <td><span class="info-val-name">电压</span></td>
              <td><span class="info-val">{{showInfo.lastData && typeof (showInfo.lastData.u) == 'number' && showInfo.lastData.u >= 0 ? showInfo.lastData.u.toFixed(2) : '-',}}V</span></td>
              <td><span class="info-val-name">最后通信时间</span></td>
              <td><span class="info-val">{{`${new Date(showInfo.fireTime).format('yyyy-MM-dd HH:mm:ss')}`}}</span></td>
            </tr>
            <tr>
              <td><span class="info-val-name">额定电压</span></td>
              <td><span class="info-val">{{showInfo.topUr?showInfo.topUr:'-'}} V</span></td>
              <td><span class="info-val-name">温度</span></td>
              <td><span class="info-val">{{showInfo.tm?showInfo.tm:'-'}}℃</span></td>
            </tr>
            <tr>

              <td><span class="info-val-name">单灯类型</span></td>
              <td><span class="info-val">{{newLightTypes()}}</span></td>
              <td><span class="info-val-name">总灯具数</span></td>
              <td><span class="info-val">{{showInfo.lampsNum}}</span></td>
            </tr>
            <tr>
              <td><span class="info-val-name">控制模式</span></td>
              <td><span class="info-val">{{showInfo.content && showInfo.content.mode==0?"计时日表":"准时日表"}}</span></td>
              <td><span class="info-val-name">{{showInfo.content && showInfo.content.mode==0?"计时日表":"准时日表"}}</span></td>
              <td><span class="info-val">{{queryLightPlan(showInfo.content && showInfo.content.mode==0?1:2)}}</span></td>
            </tr>
          </table>
        </div>
      </div>
      <table class="info-con-tab" border="1" cellspacing="0">
        <tr>
          <th>通道</th>
          <th>电流/A</th>
          <th>功率/W</th>
          <th>亮度/%</th>
          <th>漏电电流</th>
          <th>灯具数</th>
        </tr>
        <tr v-for="(el,index) in showInfo.lampList" :key="index">
          <td>K{{index+1}}:{{el.n}}</td>
          <td>{{el.c}}</td>
          <td>{{el.p}}</td>
          <td>{{el.op}}</td>
          <td>{{el.cl}}</td>
          <td>{{el.lc}}</td>
        </tr>
      </table>
      <div class="info-list-but">
        <ButListPage :dataShow="showInfo" @savedShow="savedShow"></ButListPage>
      </div>
    </div>
    <!-- <ModalControlOutput v-model="showArgModal" :command="cmdItem" @saved="argsSaved" /> -->
    <LampHistory v-model="lampHistoryShow" :showInfo="showInfo" :value="true" @popupClose="popupClose" />
    <AlarmRecord v-model="alarmRecordShow" :showInfo="showInfo" :value="true" @popupClose="popupClose" />
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
// import ModalControlOutput from '@/views/light/road/monitor/station/base/control/ModalControlOutput'
import ButListPage from './butList'
import LampHistory from './lampHistory'
import AlarmRecord from './AlarmRecord'
export default {
  name: 'ModalMakerInfo',
  components: {
    ButListPage,
    // ModalControlOutput,
    LampHistory,
    AlarmRecord,
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    showInfo: {
      type: Object,
      default() { return {}; }
    },
  },
  data() {
    return {
      loading: false,
      productId: '',
      selected: {},
      refresh: 0,
      showArgModal: false,
      lampHistoryShow: false,
      alarmRecordShow: false,
      cmdItem: {},
      cmdIds: [],
      timeTables: [],
    }
  },
  watch: {
    // cmdResultRefresh() {
    //   // this.cmdResultChanged();
    // },
    // value(val){
    //   if(val){
    //     console.log('modal maker info', this)
    //   }
    // }
  },
  computed: {
    ...mapGetters('group', ['groupPathName']),
    ...mapState("cmd", ["commands", "cmdResultRefresh"]),
    ...mapState('common', ['weeks', 'productCodes', 'topDevices', 'deviceTypes', 'powerTableActions', 'monitorActionTypes']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
    ...mapState('auth', ['showSpin', 'stationTabName']),
    currentStation: function () {
      if (this.selectedNode.type == this.productCodes.station) {
        return this.selectedNode;
      }
      return null;
    },
  },
  mounted: function () {
    this.getUseablePlan();
  },
  methods: {
    newLightTypes() {
      let name = this.showInfo.content && this.showInfo.content.type
      let newLightTypes = { 101: '市电常规型', 102: '市电太阳能混合', 103: '市电带倾斜', 104: '双色温', 105: '485单灯' }
      return newLightTypes[name]
    },
    queryLightPlan(index) {
      let name = "";
      let id = index == 1 ? this.showInfo.content && this.showInfo.content.timeId1 : this.showInfo.content && this.showInfo.content.timeId2;
      this.timeTables.forEach(el => {
        if (id == el.id) {
          name = el.name
        }
      });
      return name;
    },
    getUseablePlan: function () {
      this.$axios.post(`//${this.domains.trans}/station/plan/QueryLightPlan`, {}).then(res => {
        if (res.code != 0) {
          // this.$Message.error(res.data);
        } else {
          this.$set(this, 'timeTables', res.data);
        }
      });
    },
    infoClose() {
      this.$emit('cmd', { infoClose: true });
    },
    imgStatus(el) {
      if (!el.online) {
        return this.img.light.carat.gis.poleLine; //离线1
      } else {
        if (el.running) {//亮灯
          return el.alarm ? this.img.light.carat.gis.poleBrightpolice : this.img.light.carat.gis.poleBright;//是否报警
        } else {//灭灯
          return el.alarm ? this.img.light.carat.gis.poleOutPolice : this.img.light.carat.gis.poleOut;//是否报警
        }
      }
    },
    wordStatus(el) {
      if (!el.online) {
        return "离线"; //
      } else {
        if (el.running) {//亮灯
          return el.alarm ? "亮灯报警" : "亮灯";//是否报警
        } else {//灭灯
          return el.alarm ? "灭灯报警" : "灭灯";//是否报警
        }
      }
    },
    popupClose() {
      this.lampHistoryShow = false;
      this.alarmRecordShow = false;
    },
    savedShow(cmd) {
      if (cmd.cmd.code == "HistoryData") {
        this.lampHistoryShow = true;
        return;
      }
      if (cmd.cmd.code == "AlarmRecord") {
        this.alarmRecordShow = true;
        return;
      }
      this.$emit('cmd', cmd);
    },
    getCmdStatus: function (cmd) {
      let p = '<p>', p1 = '</p>';
      if (this.stationTabName == 'light') {
        p = '';
        p1 = ' ';
      }
      let content = `${p}指令已创建${p1}`;
      ////status: 0、等待发送，1、等待回复，2、指令超时，3、执行失败，4、执行中，5、失败重发，9、执行成功
      if (cmd.status == 1) {
        if (cmd.args.channel) {
          content = `${p}等待设备通道K${cmd.args.channel}回复${p1}`;
        } else {
          content = `${p}等待设备回复${p1}`
        }
      }
      else if (cmd.status == 3) {
        if (cmd.args.channel) {
          content = `${p}通道K${cmd.args.channel}执行失败：${cmd.result}${p1}`;
        } else {
          content = `${p}执行失败：${cmd.result}${p1}`
        }
      } else if (cmd.status == 4) {
        if (cmd.args.channel) {
          content = `${p}通道K${cmd.args.channel}执行中：${cmd.content.pkg} / ${cmd.content.total}${p1}`;
        } else {
          content = `${p}执行中：${cmd.content.pkg} / ${cmd.content.total}${p1}`
        }
      } else if (cmd.status == 5) {
        if (cmd.args.channel) {
          content = `${p}通道K${cmd.args.channel}失败重试：${cmd.result}${p1}`;
        } else {
          content = `${p}失败重试：${cmd.result}${p1}`;
        }
      } else if (cmd.status == 9) {
        if (cmd.code == "getTimeTable") {
          let day = "day" + cmd.args.day;
          let con = cmd.content[day];
          //{0: '关闭', 1: '开启', 2: '无效'},
          content = `${p} 时间表：${cmd.args.month}月${cmd.args.day}日<br> ${con.t1} - ${this.powerTableActions[con.a1]}<br>${con.t2} - ${this.powerTableActions[con.a2]}<br> ${con.t3} - ${this.powerTableActions[con.a3]}<br> ${con.t4} - ${this.powerTableActions[con.a4]}${p1}`;
        } else if (cmd.code == "getclock") {
          content = `${p}设备当前时间: ${cmd.content.nowTime}${p1}`;
        } else if (cmd.code == "setclock") {
          let myDate = new Date();
          content = `${p}已校准时钟: ${myDate.format('yyyy-MM-dd HH:mm:ss')}${p1}`;
        } else if (cmd.code == 'getYearPlan') {
          // console.log('get year plan', cmd)
          if (cmd.args.month != cmd.content.month) {
            content = `${p}执行成功：但返回月份不对，需要:${cmd.args.month}，实际:${cmd.content.month}${p1}`;
          } else {
            let strs = [];
            let obj = cmd.content[`day${cmd.args.day}`];
            if (cmd.content.mode == 4) { //北京模式
              for (let i = 1; i <= 4; i++) {
                let ao = this.powerTableActions[obj[`a${i}`]];
                let to = obj[`o${i}`];
                // let ac = this.powerTableActions[obj[`ac${i}`]];
                let tc = obj[`c${i}`];
                strs.push(`${p}${i}、${to}-${tc}:${ao}${p1}`);
              }
            } else if (cmd.content.mode == 5) { //集中器模式
              for (let i = 1; i <= 6; i++) {
                let a = obj[`a${i}`]; //this.monitorActionTypes[];
                let t = obj[`t${i}`];
                let v = this.monitorActionTypes[a]; //a == 1 ? '' : obj[`v${i}`];
                if (a != 1) {
                  v += ` - ${obj[`v${i}`]}`
                }
                strs.push(`${p}${i}、${t} - ${v}${p1}`);
              }
            } else {
              for (let i = 1; i <= 4; i++) {
                let a = this.powerTableActions[obj[`a${i}`]];
                let t = obj[`t${i}`];
                strs.push(`${p}${i}、${t} - ${a}${p1}`);
              }
            }
            content = `${p}通道K${cmd.args.channel}执行成功：${cmd.args.month}月${cmd.args.day}日计划：${strs.join(' ')}${p1}`
          }
        } else if (cmd.code == 'getInfo') {
          content = `${p}硬件版本：${cmd.content.hardware}${p1}${p}软件版本：${cmd.content.software}${p1}${p}生产厂商：${cmd.content.manufacture}${p1}`
        } else if (cmd.code == 'getSimInfo') {
          content = `${p}ICCID: ${cmd.content.iccid}${p1}${p}IMEI: ${cmd.content.imei}${p1}${p}RSSI: ${cmd.content.rssi}${p1}`
        } else if (cmd.code == 'getNetwork') {
          content = `${p}IP:${cmd.content.host}${p1}${p}端口:${cmd.content.port}${p1}${p}心跳间隔:${cmd.content.heart}秒${p1}`
        } else if (cmd.code == 'getDayPlan1') {
          content = '';
          for (let n = 1; n <= 6; n++) {
            content += `${p}时段${n}：${cmd.content[`enable1${n}`] ? '启用' : '禁用'}；${p1}${p}时间：${cmd.content[`expire1${n}`]}分,1通道亮度：${cmd.content[`bright1${n}`]}，1通道色温：${cmd.content[`color1${n}`]},2通道亮度：${cmd.content[`bright2${n}`]}，2通道色温：${cmd.content[`color2${n}`]};${p1}`
          }
        } else if (cmd.code == 'getDayPlan2') {
          content = '';
          for (let n = 1; n <= 6; n++) {
            content += `${p}时段${n}：${cmd.content[`enable1${n}`] ? '启用' : '禁用'}；${p1}${p}时间：${cmd.content[`start1${n}`]},1通道亮度：${cmd.content[`bright1${n}`]}，1通道色温：${cmd.content[`color1${n}`]},2通道亮度：${cmd.content[`bright2${n}`]}，2通道色温：${cmd.content[`color2${n}`]};${p1}`
          }
        } else if (cmd.code == 'getGroup') {
          content = `${p}分组号: ${cmd.content.no}${p1}`
        } else if (cmd.code == 'getPlanType') {
          content = `${p}日表类型: ${cmd.content.type == 0 ? '计时日表' : '准时日表'}`
        } else if (cmd.code == 'getAlarmRange') {
          content = `电压下限：${cmd.content.vLow}；
                电压上限：${cmd.content.vHigh}；
                电流下限：${cmd.content.cLow}；
                电流上限：${cmd.content.cHigh}；
                温度下限：${cmd.content.tLow}；
                温度上限：${cmd.content.tHigh}；
                上报间隔：${cmd.content.interval}分；`
        } else if (cmd.code == 'getUnionParam') {
          content = `联动编号：${cmd.content.rfsn}；
                433应用频道：${cmd.content.channel}；
                433发送功率：${cmd.content.txpower}；
                是否发送433信号：${cmd.content.txen}；
                重发次数：${cmd.content.repeat}；
                重发周期：${cmd.content.period}；
                响应范围：${cmd.content.range}；
                是否接收433信号：${cmd.content.rxen}；
                联动方式：${cmd.content.mode}；
                灯1延时时间：${cmd.content.keep1}；
                灯2延时时间：${cmd.content.keep2}；
                是否启用感应启用：${cmd.content.sensor ? '启用' : '禁用'}；`
        } else if (cmd.code == 'getAlarmStatus') {
          content = `电压报警：${cmd.content.voltage ? '启用' : '禁用'}；
                电流报警：${cmd.content.current ? '启用' : '禁用'}；
                温度报警：${cmd.content.temp ? '启用' : '禁用'}；`
        } else if (cmd.code == 'getGyroLevel') {
          content = `倾斜阈值：${cmd.content.level}；`
        } else if (cmd.args.channel) {
          content = `${p}通道K${cmd.args.channel}执行成功${p1}`;
        } else {
          content = `${p}执行成功${p1}`;
        }
      }
      return content;
    },
    cmdResultChanged: function () {
      let cmds = this.commands.filter(p => this.cmdIds.indexOf(p.id) != -1);
      if (cmds.length == 0) return;
      let contents = [];
      for (let cmd of cmds) {
        contents.push(this.getCmdStatus(cmd));

      }
      let content = contents.join(' ');
      this.$store.commit('auth/hideSpin');
      setTimeout(() => {
        this.$Modal.success({
          title: '执行结果',
          content: content
        });
      }, 500);
    },
    getBoxDeviceIds: function () {
      if (!this.currentStation) return [];
      return this.currentStation.deviceIds;
    },
    argsSaved: function (params) {
      let cmdids = [];
      if (params.cmd.type == 'light') {
        let chks = this.$refs.lightList1.getSelectedItems();
        let cmds = []
        let ns = [];
        // let ds = {};
        for (let chk of chks) {
          ns.push(chk.name);
          chk.result = '';
          chk.status = 0;
          let args = this.clone(params.args);
          let code = params.cmd.code;
          if (params.cmd.code == 'openSingle' || params.cmd.code == 'closeSingle') {
            code = 'handSingle';
          } else if (params.cmd.code == 'handControl') {
            args.point = false;
          } else if (params.cmd.code == 'setYearPlan') {
            args.timeId = chk.timeId;
          } else if (params.cmd.code == 'update') {
            args.flag = 1;
          }
          cmdids.push(chk.id);
          cmds.push({ id: chk.id, code: code, name: params.cmd.name, deviceId: chk.devId, args: args });
        }
        // this.$set(this, 'monitorDevices', ds);
        if (params.cmd.code == 'update') {
          this.$Modal.confirm({
            title: "提示",
            content: `确定要升级设备[${ns}]吗?`,
            onOk: async () => {
              this.$set(this, 'cmdIds', cmdids);
              this.$store.dispatch('cmd/sendCommands', cmds);
            },
          });
        } else {
          this.$set(this, 'cmdIds', cmdids);
          this.$store.dispatch('cmd/sendCommands', cmds);
        }
      }
    },
  }
}
</script>
<style scoped>
.info-left-box {
  position: relative;
  width: 132px;
  margin-left: -10px;
}
.info-drop {
  width: 20px;
  height: 20px;
  background: rgba(73, 225, 252, 0.4);
  border-radius: 50%;
  position: absolute;
  bottom: 0;
}
.info-drop2 {
  width: 12px;
  height: 12px;
  background: rgba(73, 225, 252);
  border-radius: 50%;
  margin: 4px auto 0;
}
.info-thread1 {
  width: 40px;
  height: 1px;
  border: 1px solid #49e1fc;
  transform: rotate(-56deg);
  position: absolute;
  bottom: 22px;
}
.info-thread2 {
  width: 80px;
  height: 1px;
  border: 1px solid #49e1fc;
  position: absolute;
  left: 30px;
  bottom: 38px;
}
.info-thread3 {
  width: 40px;
  height: 1px;
  border: 1px solid #49e1fc;
  transform: rotate(-56deg);
  position: absolute;
  left: 101px;
  bottom: 54px;
}
.info-rig-box {
  width: 640px;
  background: rgba(2, 29, 50, 0.9);
  border: 1px solid #224e7c;
  position: relative;
  padding: 10px;
  top: 110px;
}
.info-horn {
  width: 7px;
  height: 7px;
  opacity: 0.8;
  position: absolute;
}
.info-horn1 {
  border-top: 2px solid #49e1fc;
  border-left: 2px solid #49e1fc;
  top: -1px;
  left: -1px;
}
.info-horn2 {
  border-top: 2px solid #49e1fc;
  border-right: 2px solid #49e1fc;
  top: -1px;
  right: -1px;
}
.info-horn3 {
  border-bottom: 2px solid #49e1fc;
  border-left: 2px solid #49e1fc;
  left: -1px;
  bottom: -1px;
}
.info-horn4 {
  border-bottom: 2px solid #49e1fc;
  border-right: 2px solid #49e1fc;
  bottom: -1px;
  right: -1px;
}
.info-title {
  display: flex;
  background: rgba(30, 85, 143, 0.6);
  flex-direction: initial;
  justify-content: space-between;
  height: 32px;
  line-height: 32px;
  margin-bottom: 10px;
}
.info-title-box {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #42cee8;
}
.info-title-box img {
  vertical-align: middle;
  margin: 0 6px 5px;
}
.info-title-close {
  width: 14px;
  height: 14px;
  margin: 9px 12px 0;
  cursor: pointer;
}
.info-con {
  display: flex;
  flex-direction: initial;
  justify-content: space-between;
}
.info-con-left {
  width: 33.3%;
  height: 26px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #99aac0;
  line-height: 26px;
}
.info-con-rig {
  width: 33.3%;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #99aac0;
  line-height: 26px;
}
.info-con-left i,
.info-con-rig i {
  color: #49e1fc;
  font-style: inherit;
}
.info-con-tab {
  width: 100%;
  border-color: #07172a;
  margin-top: 10px;
  color: #99aac0;
}
.info-con-tab th {
  background: #07172a;
  text-align: center;
  height: 32px;
  line-height: 32px;
}
.info-con-tab tr {
  text-align: center;
  height: 32px;
  line-height: 32px;
}
.info-icon {
  width: 120px;
  height: 150px;
  text-align: center;
}
.info-icon img {
  margin-top: 15px;
}
.info-con-val {
  width: 490px;
}
.info-con-val table {
  width: 100%;
}
.info-con-val table tr {
  height: 34px;
}
.info-val-name {
  width: 90px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #a2b2c7;
  line-height: 26px;
}
.info-val {
  display: inline-block;
  width: 155px;
  height: 24px;
  background: #07172a;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #42cee8;
  line-height: 26px;
  text-indent: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.info-icon-name {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #42cee8;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: 5px;
}
</style>